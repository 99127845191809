export enum EIcon {
	active = "active",
	addFile= "addFile",
	alert = "alert",
	ambulance = "ambulance",
	animal = "animal",
	arrow = "arrow",
	bike = "bike",
	businessLiability = "businessLiability",
	calculator = "calculator",
	calculatorAlert = "calculatorAlert",
	calculatorBriefcase = "calculatorBriefcase",
	calculatorOfficeClip = "calculatorOfficeClip",
	calendar = "calendar",
	calendarSimple = "calendarSimple",
	car = "car",
	cat = "cat",
	chat = "chat",
	check = "check",
	cocktail = "cocktail",
	code = "code",
	codeCube = "codeCube",
	compass = "compass",
	contact = "contact",
	couch = "couch",
	courtage = "courtage",
	cyber = "cyber",
	doctoralHat = "doctoralHat",
	documentDownload = "documentDownload",
	documentExport = "documentExport",
	documentLock = "documentLock",
	documentSearch = "documentSearch",
	documentStats = "documentStats",
	doubleArrow = "doubleArrow",
	downUp = "downUp",
	download = "download",
	drone = "drone",
	ecCard = "ecCard",
	excavator = "excavator",
	export = "export",
	filesArchive = "filesArchive",
	flag = "flag",
	folder = "folder",
	fourHands = "fourHands",
	gear = "gear",
	goBackArrow = "goBackArrow",
	hands = "hands",
	house = "house",
	houseWithGarden = "houseWithGarden",
	industry = "industry",
	info = "info",
	infoWithoutHalo = "infoWithoutHalo",
	job = "job",
	laptop = "laptop",
	legal = "legal",
	legalProtection = "legalProtection",
	mail = "mail",
	manWithAccident = "manWithAccident",
	mountain = "mountain",
	movingTruck = "movingTruck",
	network = "network",
	newsletter = "newsletter",
	office = "office",
	officeClip = "officeClip",
	people = "people",
	phone = "phone",
	piggyBank = "piggyBank",
	plus = "plus",
	profile = "profile",
	rocket = "rocket",
	search = "search",
	sepa = "sepa",
	service = "service",
	shield = "shield",
	sickAnimal = "sickAnimal",
	sign = "sign",
	solarPanel = "solarPanel",
	speedometer = "speedometer",
	star = "star",
	starFilled = "starFilled",
	statistic = "statistic",
	stethoscope = "stethoscope",
	testOnline = "testOnline",
	trashCan = "trashCan",
	umbrella = "umbrella",
	waterdrop = "waterdrop",
	wineglass = "wineglass",
	worker = "worker"
}
export enum EPaneColor {
	blue = "blue",
	darkgrey = "darkgrey",
	greyblue = "greyblue",
	lightblue = "lightblue",
	lightgrey = "lightgrey",
	lightteal = "lightteal",
	saturatedteal = "saturatedteal",
	transparent = "transparent",
	white = "white"
}
export enum EIconColor {
	blue = "blue",
	darkgrey = "darkgrey",
	green = "green",
	lightgrey = "lightgrey",
	white = "white"
}
export enum EButtonSize {
	big,
	medium,
	small,
	extraSmall
}