import { Container } from "../container"
import { INavigationProps } from "./interface"
import { IconBox } from "./icon-box"
import { Link } from "@teamparallax/react-components"
import { NumberInfo } from "./number-info"
import { useBreakPoints } from "@teamparallax/client-libraries/lib/hooks/use-break-points"
import DegeniaLogoColorSvg from "../../../static/degenia/degenia-logo-color.svg"
import DegeniaLogoSvg from "../../../static/degenia/degenia-logo.svg"
import DmuLogoColorSvg from "../../../static/degenia/dmu-logo-color.svg"
import DmuLogoSvg from "../../../static/degenia/dmu-logo.svg"
import React, {
	FC, ReactElement, useCallback, useEffect, useState
} from "react"
import StarSvg from "../../../static/degenia/star-filled.svg"
import cn from "classnames"
import s from "./navigation.module.scss"
export const Navigation: FC<INavigationProps> = ({
	hasDmu,
	shouldStickyBeHighlighted = true,
	navigationClickHandler,
	isActive = false
}) => {
	const isMobile = !useBreakPoints ().lg
	const [
		isStickyNavigation,
		setIsStickyNavigation
	] = useState<boolean> (document.documentElement.scrollTop > 0)
	const handleScroll = useCallback (() => {
		if (document.documentElement.scrollTop > 0) {
			setIsStickyNavigation(true)
		}
		else {
			setIsStickyNavigation(false)
		}
	}, [])
	useEffect (() => {
		window.addEventListener ("scroll", handleScroll, {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			passive: true
		})
		return (): void => {
			window.removeEventListener ("scroll", handleScroll)
		}
	}, [handleScroll])
	const getDegeniaLogoToRender = (isStickyNavigation: boolean): ReactElement => {
		if (shouldStickyBeHighlighted && isStickyNavigation) {
			return (
				<Link href="/">
					<DegeniaLogoColorSvg
						className={ s.logo }
					>
						Degenia
					</DegeniaLogoColorSvg>
				</Link>
			)
		}
		else {
			return (
				<Link href="/">
					<DegeniaLogoSvg
						className={ s.logo }
					>
						Degenia
					</DegeniaLogoSvg>
				</Link>
			)
		}
	}
	const degeniaLogo = getDegeniaLogoToRender (isStickyNavigation)
	const getDmuLogoToRender = (isStickyNavigation: boolean): ReactElement => {
		if (shouldStickyBeHighlighted && isStickyNavigation) {
			return (
				<Link href="/">
					<DmuLogoColorSvg
						className={ cn (s.logo) }
					>
						DMU
					</DmuLogoColorSvg>
				</Link>
			)
		}
		else {
			return (
				<Link href="/">
					<DmuLogoSvg
						className={ cn (s.logo) }
					>
						DMU
					</DmuLogoSvg>
				</Link>
			)
		}
	}
	const dmuLogo = getDmuLogoToRender (isStickyNavigation)
	const getLogosToRender = (isMobile: boolean): ReactElement => {
		if (isMobile) {
			return (
				<div className={ cn (s.logoWrapper, {
					[ s.colored ]: shouldStickyBeHighlighted && isStickyNavigation
				}) }
				>
					<Link href="/">
						<StarSvg
							className={ s.star }
						/>
					</Link>
				</div>
			)
		}
		else {
			return (
				<div className={ cn (s.logoWrapper) }>
					{degeniaLogo}
					{hasDmu && dmuLogo}
				</div>
			)
		}
	}
	const logos = getLogosToRender (isMobile)
	return (
		<Container>
			<div
				className={ cn (s.wrapper) }
			>
				<div
					className={ cn (s.background, {
						[ s.visible ]: shouldStickyBeHighlighted && isStickyNavigation
					}) }
				/>
				<div
					className={ cn (s.navigation, {
						[ s.scrolling ]: isStickyNavigation
					}) }
				>
					{logos}
					<div className={ cn (s.telephoneWrapper, {
						[ s.colored ]: shouldStickyBeHighlighted && isStickyNavigation
					}) }
					>
						<NumberInfo/>
					</div>
					<div className={ cn (s.iconWrapper, {
						[ s.colored ]: shouldStickyBeHighlighted && isStickyNavigation
					}) }
					>
						<IconBox
							isActive={ isActive }
							isMobile={ isMobile }
							{ ...navigationClickHandler }
						/>
					</div>
				</div>
			</div>
		</Container>
	)
}