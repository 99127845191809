"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.133.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = exports.MailApi = exports.MailApiFactory = exports.MailApiFp = exports.MailApiAxiosParamCreator = exports.IdisApi = exports.IdisApiFactory = exports.IdisApiFp = exports.IdisApiAxiosParamCreator = exports.DegeniaLegacyApi = exports.DegeniaLegacyApiFactory = exports.DegeniaLegacyApiFp = exports.DegeniaLegacyApiAxiosParamCreator = exports.DegeniaConfigApi = exports.DegeniaConfigApiFactory = exports.DegeniaConfigApiFp = exports.DegeniaConfigApiAxiosParamCreator = exports.DebugApi = exports.DebugApiFactory = exports.DebugApiFp = exports.DebugApiAxiosParamCreator = exports.CMSApi = exports.CMSApiFactory = exports.CMSApiFp = exports.CMSApiAxiosParamCreator = exports.EUserRole = exports.ELocalisation = exports.EIdisProductType = exports.EIdisPaymentsPerYear = exports.EIdisCustomerSelectedField = exports.EIdisContractStatus = exports.EIdisContractSelectedField = exports.EIdisConstructionDesignClass = exports.EIdisBrokerPool = exports.EDegeniaLegacySalutation = exports.EDegeniaLegacyPaymentMethod = exports.EDegeniaLegacyDocumentCategory = exports.ECmsDegeniaVacancyType = exports.ECmsDegeniaTextType = exports.ECmsDegeniaGenericPageType = exports.ECmsDegeniaEntryTypeWebinar = exports.ECmsDegeniaEntryTypeVideo = exports.ECmsDegeniaEntryTypeVacancy = exports.ECmsDegeniaEntryTypeNews = exports.ECmsDegeniaEntryTypeMicrosite = exports.ECmsDegeniaEntryTypeInsurance = exports.ECmsDegeniaEntryTypeGenericPage = exports.ECmsDegeniaEntryTypeEvent = void 0;
exports.UserApi = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeEvent;
(function (ECmsDegeniaEntryTypeEvent) {
    ECmsDegeniaEntryTypeEvent["Event"] = "event";
})(ECmsDegeniaEntryTypeEvent = exports.ECmsDegeniaEntryTypeEvent || (exports.ECmsDegeniaEntryTypeEvent = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeGenericPage;
(function (ECmsDegeniaEntryTypeGenericPage) {
    ECmsDegeniaEntryTypeGenericPage["GenericPage"] = "generic-page";
})(ECmsDegeniaEntryTypeGenericPage = exports.ECmsDegeniaEntryTypeGenericPage || (exports.ECmsDegeniaEntryTypeGenericPage = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeInsurance;
(function (ECmsDegeniaEntryTypeInsurance) {
    ECmsDegeniaEntryTypeInsurance["Insurance"] = "insurance";
})(ECmsDegeniaEntryTypeInsurance = exports.ECmsDegeniaEntryTypeInsurance || (exports.ECmsDegeniaEntryTypeInsurance = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeMicrosite;
(function (ECmsDegeniaEntryTypeMicrosite) {
    ECmsDegeniaEntryTypeMicrosite["Microsite"] = "microsite";
})(ECmsDegeniaEntryTypeMicrosite = exports.ECmsDegeniaEntryTypeMicrosite || (exports.ECmsDegeniaEntryTypeMicrosite = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeNews;
(function (ECmsDegeniaEntryTypeNews) {
    ECmsDegeniaEntryTypeNews["NewsItem"] = "news-item";
})(ECmsDegeniaEntryTypeNews = exports.ECmsDegeniaEntryTypeNews || (exports.ECmsDegeniaEntryTypeNews = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeVacancy;
(function (ECmsDegeniaEntryTypeVacancy) {
    ECmsDegeniaEntryTypeVacancy["Vacancy"] = "vacancy";
})(ECmsDegeniaEntryTypeVacancy = exports.ECmsDegeniaEntryTypeVacancy || (exports.ECmsDegeniaEntryTypeVacancy = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeVideo;
(function (ECmsDegeniaEntryTypeVideo) {
    ECmsDegeniaEntryTypeVideo["Video"] = "video";
})(ECmsDegeniaEntryTypeVideo = exports.ECmsDegeniaEntryTypeVideo || (exports.ECmsDegeniaEntryTypeVideo = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeWebinar;
(function (ECmsDegeniaEntryTypeWebinar) {
    ECmsDegeniaEntryTypeWebinar["Webinar"] = "webinar";
})(ECmsDegeniaEntryTypeWebinar = exports.ECmsDegeniaEntryTypeWebinar || (exports.ECmsDegeniaEntryTypeWebinar = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaGenericPageType;
(function (ECmsDegeniaGenericPageType) {
    ECmsDegeniaGenericPageType["DamageReport"] = "damageReport";
    ECmsDegeniaGenericPageType["Insurance"] = "insurance";
    ECmsDegeniaGenericPageType["Jobs"] = "jobs";
    ECmsDegeniaGenericPageType["News"] = "news";
    ECmsDegeniaGenericPageType["Press"] = "press";
    ECmsDegeniaGenericPageType["Team"] = "team";
})(ECmsDegeniaGenericPageType = exports.ECmsDegeniaGenericPageType || (exports.ECmsDegeniaGenericPageType = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaTextType;
(function (ECmsDegeniaTextType) {
    ECmsDegeniaTextType["LongColumn"] = "longColumn";
    ECmsDegeniaTextType["ShortColumn"] = "shortColumn";
    ECmsDegeniaTextType["TwoColumns"] = "twoColumns";
})(ECmsDegeniaTextType = exports.ECmsDegeniaTextType || (exports.ECmsDegeniaTextType = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaVacancyType;
(function (ECmsDegeniaVacancyType) {
    ECmsDegeniaVacancyType["Apprenticeship"] = "apprenticeship";
    ECmsDegeniaVacancyType["JobOffer"] = "jobOffer";
})(ECmsDegeniaVacancyType = exports.ECmsDegeniaVacancyType || (exports.ECmsDegeniaVacancyType = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EDegeniaLegacyDocumentCategory;
(function (EDegeniaLegacyDocumentCategory) {
    EDegeniaLegacyDocumentCategory["Maklervollmacht"] = "Maklervollmacht";
})(EDegeniaLegacyDocumentCategory = exports.EDegeniaLegacyDocumentCategory || (exports.EDegeniaLegacyDocumentCategory = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EDegeniaLegacyPaymentMethod;
(function (EDegeniaLegacyPaymentMethod) {
    EDegeniaLegacyPaymentMethod["H"] = "H";
    EDegeniaLegacyPaymentMethod["M"] = "M";
    EDegeniaLegacyPaymentMethod["V"] = "V";
    EDegeniaLegacyPaymentMethod["J"] = "J";
})(EDegeniaLegacyPaymentMethod = exports.EDegeniaLegacyPaymentMethod || (exports.EDegeniaLegacyPaymentMethod = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EDegeniaLegacySalutation;
(function (EDegeniaLegacySalutation) {
    EDegeniaLegacySalutation["_1"] = "1";
    EDegeniaLegacySalutation["_0"] = "0";
})(EDegeniaLegacySalutation = exports.EDegeniaLegacySalutation || (exports.EDegeniaLegacySalutation = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EIdisBrokerPool;
(function (EIdisBrokerPool) {
    EIdisBrokerPool["Degenia"] = "Degenia";
    EIdisBrokerPool["Dmu"] = "Dmu";
    EIdisBrokerPool["Other"] = "Other";
})(EIdisBrokerPool = exports.EIdisBrokerPool || (exports.EIdisBrokerPool = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EIdisConstructionDesignClass;
(function (EIdisConstructionDesignClass) {
    EIdisConstructionDesignClass[EIdisConstructionDesignClass["NUMBER_0"] = 0] = "NUMBER_0";
    EIdisConstructionDesignClass[EIdisConstructionDesignClass["NUMBER_1"] = 1] = "NUMBER_1";
    EIdisConstructionDesignClass[EIdisConstructionDesignClass["NUMBER_2"] = 2] = "NUMBER_2";
    EIdisConstructionDesignClass[EIdisConstructionDesignClass["NUMBER_3"] = 3] = "NUMBER_3";
})(EIdisConstructionDesignClass = exports.EIdisConstructionDesignClass || (exports.EIdisConstructionDesignClass = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EIdisContractSelectedField;
(function (EIdisContractSelectedField) {
    EIdisContractSelectedField["ContractId"] = "contractId";
    EIdisContractSelectedField["Contribution"] = "contribution";
    EIdisContractSelectedField["Duration"] = "duration";
    EIdisContractSelectedField["Expiration"] = "expiration";
    EIdisContractSelectedField["LicensePlate"] = "licensePlate";
    EIdisContractSelectedField["NetContribution"] = "netContribution";
    EIdisContractSelectedField["Organisation"] = "organisation";
    EIdisContractSelectedField["PaymentsPerYear"] = "paymentsPerYear";
    EIdisContractSelectedField["Product"] = "product";
    EIdisContractSelectedField["Rate"] = "rate";
    EIdisContractSelectedField["Start"] = "start";
    EIdisContractSelectedField["Status"] = "status";
})(EIdisContractSelectedField = exports.EIdisContractSelectedField || (exports.EIdisContractSelectedField = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EIdisContractStatus;
(function (EIdisContractStatus) {
    EIdisContractStatus["NotRecognized"] = "notRecognized";
    EIdisContractStatus["PortfolioTransfer"] = "portfolioTransfer";
    EIdisContractStatus["ConverGranted"] = "converGranted";
    EIdisContractStatus["Submitted"] = "submitted";
    EIdisContractStatus["Exempted"] = "exempted";
    EIdisContractStatus["CorrespondentBroker"] = "correspondentBroker";
    EIdisContractStatus["Unpoliced"] = "unpoliced";
    EIdisContractStatus["InvitationToTreat"] = "invitationToTreat";
    EIdisContractStatus["Dormant"] = "dormant";
    EIdisContractStatus["Active"] = "active";
    EIdisContractStatus["DunningCompany"] = "dunningCompany";
    EIdisContractStatus["NotWorkedOut"] = "notWorkedOut";
    EIdisContractStatus["Commissioned"] = "commissioned";
    EIdisContractStatus["InquiryToBroker"] = "inquiryToBroker";
    EIdisContractStatus["Reversal"] = "reversal";
    EIdisContractStatus["PortfolioTransferClosed"] = "portfolioTransferClosed";
    EIdisContractStatus["Offer"] = "offer";
    EIdisContractStatus["InsuranceCompanies"] = "insuranceCompanies";
    EIdisContractStatus["L"] = "L";
    EIdisContractStatus["P"] = "P";
    EIdisContractStatus["D"] = "D";
    EIdisContractStatus["G"] = "G";
    EIdisContractStatus["NichtErfasst"] = "nicht erfasst";
    EIdisContractStatus["J"] = "J";
    EIdisContractStatus["M"] = "M";
    EIdisContractStatus["F"] = "F";
    EIdisContractStatus["R"] = "R";
    EIdisContractStatus["Z"] = "Z";
    EIdisContractStatus["I"] = "I";
    EIdisContractStatus["A"] = "A";
    EIdisContractStatus["N"] = "N";
    EIdisContractStatus["V"] = "V";
    EIdisContractStatus["B"] = "B";
    EIdisContractStatus["UE"] = "UE";
    EIdisContractStatus["S"] = "S";
    EIdisContractStatus["E"] = "E";
    EIdisContractStatus["H"] = "H";
})(EIdisContractStatus = exports.EIdisContractStatus || (exports.EIdisContractStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EIdisCustomerSelectedField;
(function (EIdisCustomerSelectedField) {
    EIdisCustomerSelectedField["Birthdate"] = "birthdate";
    EIdisCustomerSelectedField["City"] = "city";
    EIdisCustomerSelectedField["FirstName"] = "firstName";
    EIdisCustomerSelectedField["PhonePersonal"] = "phonePersonal";
    EIdisCustomerSelectedField["Street"] = "street";
    EIdisCustomerSelectedField["Surname"] = "surname";
    EIdisCustomerSelectedField["Zipcode"] = "zipcode";
})(EIdisCustomerSelectedField = exports.EIdisCustomerSelectedField || (exports.EIdisCustomerSelectedField = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EIdisPaymentsPerYear;
(function (EIdisPaymentsPerYear) {
    EIdisPaymentsPerYear[EIdisPaymentsPerYear["NUMBER_2"] = 2] = "NUMBER_2";
    EIdisPaymentsPerYear[EIdisPaymentsPerYear["NUMBER_12"] = 12] = "NUMBER_12";
    EIdisPaymentsPerYear[EIdisPaymentsPerYear["NUMBER_4"] = 4] = "NUMBER_4";
    EIdisPaymentsPerYear[EIdisPaymentsPerYear["NUMBER_1"] = 1] = "NUMBER_1";
})(EIdisPaymentsPerYear = exports.EIdisPaymentsPerYear || (exports.EIdisPaymentsPerYear = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EIdisProductType;
(function (EIdisProductType) {
    EIdisProductType["AGRAR"] = "AGRAR";
    EIdisProductType["AIV"] = "AIV";
    EIdisProductType["AU"] = "AU";
    EIdisProductType["AZH"] = "AZH";
    EIdisProductType["BAU"] = "BAU";
    EIdisProductType["BAV"] = "BAV";
    EIdisProductType["BH"] = "BH";
    EIdisProductType["BHH"] = "BHH";
    EIdisProductType["BHI"] = "BHI";
    EIdisProductType["BI"] = "BI";
    EIdisProductType["BIGL"] = "BI-GL";
    EIdisProductType["BLV"] = "BLV";
    EIdisProductType["BSP"] = "BSP";
    EIdisProductType["BTA"] = "BTA";
    EIdisProductType["BUZ"] = "BUZ";
    EIdisProductType["B"] = "B\u00DC";
    EIdisProductType["BN"] = "B\u00DCN";
    EIdisProductType["CAMP"] = "CAMP";
    EIdisProductType["CYB"] = "CYB";
    EIdisProductType["CYBER"] = "CYBER";
    EIdisProductType["DO"] = "DO";
    EIdisProductType["DD"] = "DD";
    EIdisProductType["DIEL"] = "DIEL";
    EIdisProductType["DIOPT"] = "DIOPT";
    EIdisProductType["DV"] = "DV";
    EIdisProductType["EAV"] = "EAV";
    EIdisProductType["ELE"] = "ELE";
    EIdisProductType["EUV"] = "EUV";
    EIdisProductType["FBU"] = "FBU";
    EIdisProductType["FEUER"] = "FEUER";
    EIdisProductType["FLV"] = "FLV";
    EIdisProductType["FONDS"] = "FONDS";
    EIdisProductType["FRV"] = "FRV";
    EIdisProductType["GGLA"] = "G-GLA";
    EIdisProductType["GBV"] = "GBV";
    EIdisProductType["GEWER"] = "GEWER";
    EIdisProductType["GIV"] = "GIV";
    EIdisProductType["GKV"] = "GKV";
    EIdisProductType["GLA"] = "GLA";
    EIdisProductType["GRU"] = "GRU";
    EIdisProductType["GUV"] = "GUV";
    EIdisProductType["GWH"] = "GWH";
    EIdisProductType["GWS"] = "GWS";
    EIdisProductType["HGLA"] = "H-GLA";
    EIdisProductType["HEIM"] = "HEIM";
    EIdisProductType["HGV"] = "HGV";
    EIdisProductType["HHW"] = "HHW";
    EIdisProductType["HKV"] = "HKV";
    EIdisProductType["HOSTI"] = "HOSTI";
    EIdisProductType["HUG"] = "HUG";
    EIdisProductType["HUGMA"] = "HUGMA";
    EIdisProductType["HV"] = "HV";
    EIdisProductType["JHV"] = "JHV";
    EIdisProductType["JUV"] = "JUV";
    EIdisProductType["KA"] = "KA";
    EIdisProductType["KAUT"] = "KAUT";
    EIdisProductType["KFZ"] = "KFZ";
    EIdisProductType["KFZGE"] = "KFZGE";
    EIdisProductType["KLV"] = "KLV";
    EIdisProductType["KRAD"] = "KRAD";
    EIdisProductType["KRED"] = "KRED";
    EIdisProductType["KREDV"] = "KREDV";
    EIdisProductType["KRV"] = "KRV";
    EIdisProductType["LADY"] = "LADY";
    EIdisProductType["LANDW"] = "LANDW";
    EIdisProductType["LDY"] = "LDY";
    EIdisProductType["LFK"] = "LFK";
    EIdisProductType["LHV"] = "LHV";
    EIdisProductType["LKV"] = "LKV";
    EIdisProductType["LKW"] = "LKW";
    EIdisProductType["LUFT"] = "LUFT";
    EIdisProductType["MAA"] = "MAA";
    EIdisProductType["MAS"] = "MAS";
    EIdisProductType["MAV"] = "MAV";
    EIdisProductType["MIET"] = "MIET";
    EIdisProductType["MV"] = "MV";
    EIdisProductType["PBH"] = "PBH";
    EIdisProductType["PHTH"] = "PH+TH";
    EIdisProductType["PHO"] = "PHO";
    EIdisProductType["PHV"] = "PHV";
    EIdisProductType["PHVA"] = "PHVA";
    EIdisProductType["PHVD"] = "PHV\u00D6D";
    EIdisProductType["PKV"] = "PKV";
    EIdisProductType["PKV30"] = "PKV30";
    EIdisProductType["PPV"] = "PPV";
    EIdisProductType["PRIVA"] = "PRIVA";
    EIdisProductType["PUV"] = "PUV";
    EIdisProductType["RA"] = "RA";
    EIdisProductType["RAD"] = "RAD";
    EIdisProductType["RKV"] = "RKV";
    EIdisProductType["RLV"] = "RLV";
    EIdisProductType["RP"] = "RP";
    EIdisProductType["RRV"] = "RRV";
    EIdisProductType["RS"] = "RS";
    EIdisProductType["RSGE"] = "RS-GE";
    EIdisProductType["RSVO"] = "RS-VO";
    EIdisProductType["RSWG"] = "RS-WG";
    EIdisProductType["SB"] = "SB";
    EIdisProductType["STG"] = "STG";
    EIdisProductType["SV"] = "SV";
    EIdisProductType["TAXIS"] = "TAXIS";
    EIdisProductType["THPF"] = "THPF";
    EIdisProductType["THV"] = "THV";
    EIdisProductType["TKV"] = "TKV";
    EIdisProductType["TLV"] = "TLV";
    EIdisProductType["TOP"] = "TOP";
    EIdisProductType["TOV"] = "TOV";
    EIdisProductType["TRV"] = "TRV";
    EIdisProductType["TS"] = "TS";
    EIdisProductType["TUV"] = "TUV";
    EIdisProductType["TV"] = "TV";
    EIdisProductType["UHV"] = "UHV";
    EIdisProductType["VALO"] = "VALO";
    EIdisProductType["VALOR"] = "VALOR";
    EIdisProductType["VHV"] = "VHV";
    EIdisProductType["VHVAL"] = "VHVAL";
    EIdisProductType["VRS"] = "VRS";
    EIdisProductType["VSH"] = "VSH";
    EIdisProductType["VWG"] = "VWG";
    EIdisProductType["VWGGE"] = "VWGGE";
    EIdisProductType["VWGD"] = "VWG\u00D6D";
    EIdisProductType["WGLA"] = "W-GLA";
    EIdisProductType["ZKV"] = "ZKV";
    EIdisProductType["KO"] = "\u00D6KO";
    EIdisProductType["KAS"] = "\u00DCKAS";
})(EIdisProductType = exports.EIdisProductType || (exports.EIdisProductType = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ELocalisation;
(function (ELocalisation) {
    ELocalisation["De"] = "de";
    ELocalisation["En"] = "en";
})(ELocalisation = exports.ELocalisation || (exports.ELocalisation = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EUserRole;
(function (EUserRole) {
    EUserRole["Admin"] = "admin";
    EUserRole["Alliance"] = "alliance";
    EUserRole["Allianceadmin"] = "alliance:admin";
    EUserRole["Allianceuser"] = "alliance:user";
    EUserRole["DegeniaBroker"] = "degeniaBroker";
    EUserRole["DegeniaCustomer"] = "degeniaCustomer";
    EUserRole["DegeniaCustomerCorporation"] = "degeniaCustomerCorporation";
    EUserRole["DegeniaEventPlanner"] = "degeniaEventPlanner";
    EUserRole["Degeniauserduplicate"] = "degenia:user:duplicate";
    EUserRole["Disabled"] = "disabled";
    EUserRole["FrontEndManager"] = "frontEndManager";
    EUserRole["Lord"] = "lord";
    EUserRole["Mod"] = "mod";
    EUserRole["PlanetManager"] = "planetManager";
    EUserRole["User"] = "user";
    EUserRole["Userbeta"] = "user:beta";
})(EUserRole = exports.EUserRole || (exports.EUserRole = {}));
/**
 * CMSApi - axios parameter creator
 * @export
 */
const CMSApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaArticle: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaArticle.');
            }
            const localVarPath = `/cms/degenia/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaAward: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaAward.');
            }
            const localVarPath = `/cms/degenia/award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaCompanyLogo: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaCompanyLogo.');
            }
            const localVarPath = `/cms/degenia/company-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaEmail: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaEmail.');
            }
            const localVarPath = `/cms/degenia/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaEvent: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaEvent.');
            }
            const localVarPath = `/cms/degenia/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericPage: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaGenericPage.');
            }
            const localVarPath = `/cms/degenia/generic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericText: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaGenericText.');
            }
            const localVarPath = `/cms/degenia/genericText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaInsurance: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaInsurance.');
            }
            const localVarPath = `/cms/degenia/insurance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMicrosite: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaMicrosite.');
            }
            const localVarPath = `/cms/degenia/microsite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMrMoneyiFrame: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaMrMoneyiFrame.');
            }
            const localVarPath = `/cms/degenia/mr-money-i-frame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNewsletter: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaNewsletter.');
            }
            const localVarPath = `/cms/degenia/newsletter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNote: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaNote.');
            }
            const localVarPath = `/cms/degenia/note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaTeamMember: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaTeamMember.');
            }
            const localVarPath = `/cms/degenia/team-member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaVacancy: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaVacancy.');
            }
            const localVarPath = `/cms/degenia/vacancy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaWebinar: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaWebinar.');
            }
            const localVarPath = `/cms/degenia/webinar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaArticles: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaAwards: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaCompanyLogos: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/company-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaEmails: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaEvents: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericPages: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/generic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericTexts: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/genericText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaInsurances: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/insurance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMicrosites: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/microsite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMrMoneyiFrames: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/mr-money-i-frame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNews: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNewsletters: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/newsletter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNotes: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaTeamMembers: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/team-member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaVacancies: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/vacancy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaWebinars: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/webinar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaArticle: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaArticle.');
            }
            const localVarPath = `/cms/degenia/article/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaAward: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaAward.');
            }
            const localVarPath = `/cms/degenia/award/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaCompanyLogo: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaCompanyLogo.');
            }
            const localVarPath = `/cms/degenia/company-logo/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaEmail: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaEmail.');
            }
            const localVarPath = `/cms/degenia/email/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaEvent: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaEvent.');
            }
            const localVarPath = `/cms/degenia/event/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericPage: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaGenericPage.');
            }
            const localVarPath = `/cms/degenia/generic/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericText: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaGenericText.');
            }
            const localVarPath = `/cms/degenia/genericText/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaInsurance: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaInsurance.');
            }
            const localVarPath = `/cms/degenia/insurance/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaLandingpage: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/single/landingpage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrosite: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaMicrosite.');
            }
            const localVarPath = `/cms/degenia/microsite/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrositeDescription: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/single/microsite-description`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMrMoneyiFrame: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaMrMoneyiFrame.');
            }
            const localVarPath = `/cms/degenia/mr-money-i-frame/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNews: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaNews.');
            }
            const localVarPath = `/cms/degenia/news/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNewsletter: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaNewsletter.');
            }
            const localVarPath = `/cms/degenia/newsletter/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNote: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaNote.');
            }
            const localVarPath = `/cms/degenia/note/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaTeamMember: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaTeamMember.');
            }
            const localVarPath = `/cms/degenia/team-member/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaVacancy: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaVacancy.');
            }
            const localVarPath = `/cms/degenia/vacancy/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaWebinar: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaWebinar.');
            }
            const localVarPath = `/cms/degenia/webinar/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInDegeniaHomepage: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling searchInDegeniaHomepage.');
            }
            const localVarPath = `/cms/degenia/search/homepage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInMyDegenia: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling searchInMyDegenia.');
            }
            const localVarPath = `/cms/degenia/search/mydegenia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaArticle: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaArticle.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaArticle.');
            }
            const localVarPath = `/cms/degenia/article/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaAward: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaAward.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaAward.');
            }
            const localVarPath = `/cms/degenia/award/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaCompanyLogo: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaCompanyLogo.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaCompanyLogo.');
            }
            const localVarPath = `/cms/degenia/company-logo/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaEmail: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaEmail.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaEmail.');
            }
            const localVarPath = `/cms/degenia/email/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaEvent: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaEvent.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaEvent.');
            }
            const localVarPath = `/cms/degenia/event/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericPage: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaGenericPage.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaGenericPage.');
            }
            const localVarPath = `/cms/degenia/generic/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericText: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaGenericText.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaGenericText.');
            }
            const localVarPath = `/cms/degenia/genericText/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaInsurance: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaInsurance.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaInsurance.');
            }
            const localVarPath = `/cms/degenia/insurance/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeLandingpageExcludeKeyofICmsDegeniaContentTypeLandingpageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaLandingpage: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaLandingpage.');
            }
            const localVarPath = `/cms/degenia/landingpage/{entryId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrosite: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaMicrosite.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaMicrosite.');
            }
            const localVarPath = `/cms/degenia/microsite/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeDescriptionExcludeKeyofICmsDegeniaContentTypeMicrositeDescriptionIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrositeDescription: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaMicrositeDescription.');
            }
            const localVarPath = `/cms/degenia/microsite-description/{entryId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMrMoneyiFrame: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaMrMoneyiFrame.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaMrMoneyiFrame.');
            }
            const localVarPath = `/cms/degenia/mr-money-i-frame/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNewsletter: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaNewsletter.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaNewsletter.');
            }
            const localVarPath = `/cms/degenia/newsletter/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNote: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaNote.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaNote.');
            }
            const localVarPath = `/cms/degenia/note/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaTeamMember: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaTeamMember.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaTeamMember.');
            }
            const localVarPath = `/cms/degenia/team-member/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaVacancy: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaVacancy.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaVacancy.');
            }
            const localVarPath = `/cms/degenia/vacancy/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaWebinar: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaWebinar.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaWebinar.');
            }
            const localVarPath = `/cms/degenia/webinar/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.CMSApiAxiosParamCreator = CMSApiAxiosParamCreator;
/**
 * CMSApi - functional programming interface
 * @export
 */
const CMSApiFp = function (configuration) {
    return {
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaArticle(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaArticle(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaAward(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaAward(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaCompanyLogo(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaCompanyLogo(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaEmail(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaEmail(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaEvent(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaEvent(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericPage(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaGenericPage(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericText(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaGenericText(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaInsurance(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaInsurance(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMicrosite(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaMicrosite(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMrMoneyiFrame(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaMrMoneyiFrame(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNewsletter(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaNewsletter(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNote(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaNote(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaTeamMember(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaTeamMember(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaVacancy(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaVacancy(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaWebinar(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaWebinar(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaArticles(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaArticles(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaAwards(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaAwards(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaCompanyLogos(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaCompanyLogos(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaEmails(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaEmails(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaEvents(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaEvents(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericPages(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaGenericPages(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericTexts(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaGenericTexts(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaInsurances(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaInsurances(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMicrosites(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaMicrosites(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMrMoneyiFrames(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaMrMoneyiFrames(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNews(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaNews(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNewsletters(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaNewsletters(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNotes(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaNotes(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaTeamMembers(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaTeamMembers(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaVacancies(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaVacancies(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaWebinars(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaWebinars(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaArticle(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaArticle(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaAward(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaAward(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaCompanyLogo(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaCompanyLogo(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaEmail(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaEmail(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaEvent(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaEvent(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericPage(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaGenericPage(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericText(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaGenericText(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaInsurance(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaInsurance(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaLandingpage(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaLandingpage(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrosite(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaMicrosite(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrositeDescription(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaMicrositeDescription(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMrMoneyiFrame(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaMrMoneyiFrame(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNews(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaNews(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNewsletter(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaNewsletter(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNote(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaNote(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaTeamMember(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaTeamMember(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaVacancy(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaVacancy(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaWebinar(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaWebinar(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInDegeniaHomepage(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).searchInDegeniaHomepage(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInMyDegenia(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).searchInMyDegenia(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaArticle(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaArticle(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaAward(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaAward(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaCompanyLogo(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaCompanyLogo(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaEmail(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaEmail(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaEvent(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaEvent(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericPage(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaGenericPage(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericText(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaGenericText(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaInsurance(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaInsurance(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeLandingpageExcludeKeyofICmsDegeniaContentTypeLandingpageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaLandingpage(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaLandingpage(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrosite(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaMicrosite(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeDescriptionExcludeKeyofICmsDegeniaContentTypeMicrositeDescriptionIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrositeDescription(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaMicrositeDescription(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMrMoneyiFrame(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaMrMoneyiFrame(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNewsletter(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaNewsletter(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNote(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaNote(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaTeamMember(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaTeamMember(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaVacancy(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaVacancy(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaWebinar(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaWebinar(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.CMSApiFp = CMSApiFp;
/**
 * CMSApi - factory interface
 * @export
 */
const CMSApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaArticle(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaArticle(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaAward(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaAward(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaCompanyLogo(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaCompanyLogo(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaEmail(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaEmail(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaEvent(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaEvent(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericPage(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaGenericPage(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericText(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaGenericText(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaInsurance(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaInsurance(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMicrosite(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaMicrosite(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMrMoneyiFrame(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaMrMoneyiFrame(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNewsletter(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaNewsletter(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNote(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaNote(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaTeamMember(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaTeamMember(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaVacancy(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaVacancy(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaWebinar(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaWebinar(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaArticles(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaArticles(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaAwards(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaAwards(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaCompanyLogos(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaCompanyLogos(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaEmails(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaEmails(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaEvents(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaEvents(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericPages(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaGenericPages(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericTexts(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaGenericTexts(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaInsurances(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaInsurances(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMicrosites(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaMicrosites(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMrMoneyiFrames(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaMrMoneyiFrames(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNews(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaNews(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNewsletters(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaNewsletters(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNotes(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaNotes(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaTeamMembers(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaTeamMembers(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaVacancies(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaVacancies(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaWebinars(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaWebinars(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaArticle(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaArticle(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaAward(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaAward(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaCompanyLogo(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaCompanyLogo(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaEmail(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaEmail(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaEvent(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaEvent(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericPage(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaGenericPage(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericText(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaGenericText(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaInsurance(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaInsurance(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaLandingpage(options) {
            return exports.CMSApiFp(configuration).getDegeniaLandingpage(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrosite(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaMicrosite(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrositeDescription(options) {
            return exports.CMSApiFp(configuration).getDegeniaMicrositeDescription(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMrMoneyiFrame(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaMrMoneyiFrame(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNews(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaNews(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNewsletter(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaNewsletter(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNote(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaNote(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaTeamMember(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaTeamMember(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaVacancy(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaVacancy(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaWebinar(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaWebinar(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInDegeniaHomepage(body, options) {
            return exports.CMSApiFp(configuration).searchInDegeniaHomepage(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInMyDegenia(body, options) {
            return exports.CMSApiFp(configuration).searchInMyDegenia(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaArticle(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaArticle(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaAward(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaAward(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaCompanyLogo(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaCompanyLogo(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaEmail(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaEmail(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaEvent(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaEvent(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericPage(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaGenericPage(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericText(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaGenericText(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaInsurance(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaInsurance(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeLandingpageExcludeKeyofICmsDegeniaContentTypeLandingpageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaLandingpage(body, options) {
            return exports.CMSApiFp(configuration).updateDegeniaLandingpage(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrosite(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaMicrosite(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeDescriptionExcludeKeyofICmsDegeniaContentTypeMicrositeDescriptionIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrositeDescription(body, options) {
            return exports.CMSApiFp(configuration).updateDegeniaMicrositeDescription(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMrMoneyiFrame(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaMrMoneyiFrame(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNewsletter(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaNewsletter(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNote(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaNote(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaTeamMember(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaTeamMember(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaVacancy(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaVacancy(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaWebinar(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaWebinar(body, entryId, options).then((request) => request(axios, basePath));
        },
    };
};
exports.CMSApiFactory = CMSApiFactory;
/**
 * CMSApi - object-oriented interface
 * @export
 * @class CMSApi
 * @extends {BaseAPI}
 */
class CMSApi extends base_1.BaseAPI {
    /**
     *
     * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaArticle(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaArticle(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaAward(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaAward(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaCompanyLogo(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaCompanyLogo(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaEmail(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaEmail(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaEvent(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaEvent(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaGenericPage(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaGenericPage(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaGenericText(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaGenericText(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaInsurance(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaInsurance(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaMicrosite(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaMicrosite(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaMrMoneyiFrame(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaMrMoneyiFrame(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaNewsletter(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaNewsletter(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaNote(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaNote(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaTeamMember(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaTeamMember(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaVacancy(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaVacancy(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaWebinar(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaWebinar(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaArticles(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaArticles(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaAwards(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaAwards(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaCompanyLogos(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaCompanyLogos(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaEmails(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaEmails(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaEvents(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaEvents(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaGenericPages(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaGenericPages(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaGenericTexts(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaGenericTexts(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaInsurances(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaInsurances(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaMicrosites(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaMicrosites(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaMrMoneyiFrames(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaMrMoneyiFrames(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaNews(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaNews(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaNewsletters(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaNewsletters(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaNotes(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaNotes(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaTeamMembers(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaTeamMembers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaVacancies(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaVacancies(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaWebinars(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaWebinars(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaArticle(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaArticle(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaAward(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaAward(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaCompanyLogo(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaCompanyLogo(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaEmail(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaEmail(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaEvent(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaEvent(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaGenericPage(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaGenericPage(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaGenericText(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaGenericText(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaInsurance(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaInsurance(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaLandingpage(options) {
        return exports.CMSApiFp(this.configuration).getDegeniaLandingpage(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaMicrosite(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaMicrosite(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaMicrositeDescription(options) {
        return exports.CMSApiFp(this.configuration).getDegeniaMicrositeDescription(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaMrMoneyiFrame(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaMrMoneyiFrame(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaNews(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaNews(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaNewsletter(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaNewsletter(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaNote(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaNote(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaTeamMember(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaTeamMember(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaVacancy(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaVacancy(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaWebinar(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaWebinar(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    searchInDegeniaHomepage(body, options) {
        return exports.CMSApiFp(this.configuration).searchInDegeniaHomepage(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    searchInMyDegenia(body, options) {
        return exports.CMSApiFp(this.configuration).searchInMyDegenia(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaArticle(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaArticle(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaAward(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaAward(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaCompanyLogo(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaCompanyLogo(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaEmail(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaEmail(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaEvent(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaEvent(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaGenericPage(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaGenericPage(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaGenericText(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaGenericText(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaInsurance(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaInsurance(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeLandingpageExcludeKeyofICmsDegeniaContentTypeLandingpageIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaLandingpage(body, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaLandingpage(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaMicrosite(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaMicrosite(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeMicrositeDescriptionExcludeKeyofICmsDegeniaContentTypeMicrositeDescriptionIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaMicrositeDescription(body, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaMicrositeDescription(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaMrMoneyiFrame(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaMrMoneyiFrame(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaNewsletter(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaNewsletter(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaNote(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaNote(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaTeamMember(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaTeamMember(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaVacancy(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaVacancy(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaWebinar(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaWebinar(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.CMSApi = CMSApi;
/**
 * DebugApi - axios parameter creator
 * @export
 */
const DebugApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {any} body
         * @param {string} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSubscription: (body, type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling triggerSubscription.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new base_1.RequiredError('type', 'Required parameter type was null or undefined when calling triggerSubscription.');
            }
            const localVarPath = `/debug/trigger-subscription/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DebugApiAxiosParamCreator = DebugApiAxiosParamCreator;
/**
 * DebugApi - functional programming interface
 * @export
 */
const DebugApiFp = function (configuration) {
    return {
        /**
         *
         * @param {any} body
         * @param {string} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSubscription(body, type, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DebugApiAxiosParamCreator(configuration).triggerSubscription(body, type, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.DebugApiFp = DebugApiFp;
/**
 * DebugApi - factory interface
 * @export
 */
const DebugApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {any} body
         * @param {string} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSubscription(body, type, options) {
            return exports.DebugApiFp(configuration).triggerSubscription(body, type, options).then((request) => request(axios, basePath));
        },
    };
};
exports.DebugApiFactory = DebugApiFactory;
/**
 * DebugApi - object-oriented interface
 * @export
 * @class DebugApi
 * @extends {BaseAPI}
 */
class DebugApi extends base_1.BaseAPI {
    /**
     *
     * @param {any} body
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    triggerSubscription(body, type, options) {
        return exports.DebugApiFp(this.configuration).triggerSubscription(body, type, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DebugApi = DebugApi;
/**
 * DegeniaConfigApi - axios parameter creator
 * @export
 */
const DegeniaConfigApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDamageReportLabels: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/degenia-config/damage-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} damageReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageReportLabelsById: (damageReportId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'damageReportId' is not null or undefined
            if (damageReportId === null || damageReportId === undefined) {
                throw new base_1.RequiredError('damageReportId', 'Required parameter damageReportId was null or undefined when calling getDamageReportLabelsById.');
            }
            const localVarPath = `/degenia-config/damage-report/{damageReportId}`
                .replace(`{${"damageReportId"}}`, encodeURIComponent(String(damageReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DegeniaConfigApiAxiosParamCreator = DegeniaConfigApiAxiosParamCreator;
/**
 * DegeniaConfigApi - functional programming interface
 * @export
 */
const DegeniaConfigApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDamageReportLabels(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DegeniaConfigApiAxiosParamCreator(configuration).getAllDamageReportLabels(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} damageReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageReportLabelsById(damageReportId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DegeniaConfigApiAxiosParamCreator(configuration).getDamageReportLabelsById(damageReportId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.DegeniaConfigApiFp = DegeniaConfigApiFp;
/**
 * DegeniaConfigApi - factory interface
 * @export
 */
const DegeniaConfigApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDamageReportLabels(options) {
            return exports.DegeniaConfigApiFp(configuration).getAllDamageReportLabels(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} damageReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageReportLabelsById(damageReportId, options) {
            return exports.DegeniaConfigApiFp(configuration).getDamageReportLabelsById(damageReportId, options).then((request) => request(axios, basePath));
        },
    };
};
exports.DegeniaConfigApiFactory = DegeniaConfigApiFactory;
/**
 * DegeniaConfigApi - object-oriented interface
 * @export
 * @class DegeniaConfigApi
 * @extends {BaseAPI}
 */
class DegeniaConfigApi extends base_1.BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DegeniaConfigApi
     */
    getAllDamageReportLabels(options) {
        return exports.DegeniaConfigApiFp(this.configuration).getAllDamageReportLabels(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} damageReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DegeniaConfigApi
     */
    getDamageReportLabelsById(damageReportId, options) {
        return exports.DegeniaConfigApiFp(this.configuration).getDamageReportLabelsById(damageReportId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DegeniaConfigApi = DegeniaConfigApi;
/**
 * DegeniaLegacyApi - axios parameter creator
 * @export
 */
const DegeniaLegacyApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {IDegeniaLegacySoapParameter} iDegeniaLegacySoapParameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortfolioCession: (iDegeniaLegacySoapParameter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iDegeniaLegacySoapParameter' is not null or undefined
            if (iDegeniaLegacySoapParameter === null || iDegeniaLegacySoapParameter === undefined) {
                throw new base_1.RequiredError('iDegeniaLegacySoapParameter', 'Required parameter iDegeniaLegacySoapParameter was null or undefined when calling postPortfolioCession.');
            }
            const localVarPath = `/degenia-legacy/portfolio-cession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iDegeniaLegacySoapParameter !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iDegeniaLegacySoapParameter !== undefined ? iDegeniaLegacySoapParameter : {}) : (iDegeniaLegacySoapParameter || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DegeniaLegacyApiAxiosParamCreator = DegeniaLegacyApiAxiosParamCreator;
/**
 * DegeniaLegacyApi - functional programming interface
 * @export
 */
const DegeniaLegacyApiFp = function (configuration) {
    return {
        /**
         *
         * @param {IDegeniaLegacySoapParameter} iDegeniaLegacySoapParameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortfolioCession(iDegeniaLegacySoapParameter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DegeniaLegacyApiAxiosParamCreator(configuration).postPortfolioCession(iDegeniaLegacySoapParameter, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.DegeniaLegacyApiFp = DegeniaLegacyApiFp;
/**
 * DegeniaLegacyApi - factory interface
 * @export
 */
const DegeniaLegacyApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {IDegeniaLegacySoapParameter} iDegeniaLegacySoapParameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPortfolioCession(iDegeniaLegacySoapParameter, options) {
            return exports.DegeniaLegacyApiFp(configuration).postPortfolioCession(iDegeniaLegacySoapParameter, options).then((request) => request(axios, basePath));
        },
    };
};
exports.DegeniaLegacyApiFactory = DegeniaLegacyApiFactory;
/**
 * DegeniaLegacyApi - object-oriented interface
 * @export
 * @class DegeniaLegacyApi
 * @extends {BaseAPI}
 */
class DegeniaLegacyApi extends base_1.BaseAPI {
    /**
     *
     * @param {IDegeniaLegacySoapParameter} iDegeniaLegacySoapParameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DegeniaLegacyApi
     */
    postPortfolioCession(iDegeniaLegacySoapParameter, options) {
        return exports.DegeniaLegacyApiFp(this.configuration).postPortfolioCession(iDegeniaLegacySoapParameter, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DegeniaLegacyApi = DegeniaLegacyApi;
/**
 * IdisApi - axios parameter creator
 * @export
 */
const IdisApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveContracts: (customerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new base_1.RequiredError('customerId', 'Required parameter customerId was null or undefined when calling getActiveContracts.');
            }
            const localVarPath = `/idis/customer/activeContract/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerAll: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/idis/broker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} brokerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerById: (brokerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'brokerId' is not null or undefined
            if (brokerId === null || brokerId === undefined) {
                throw new base_1.RequiredError('brokerId', 'Required parameter brokerId was null or undefined when calling getBrokerById.');
            }
            const localVarPath = `/idis/broker/{brokerId}`
                .replace(`{${"brokerId"}}`, encodeURIComponent(String(brokerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IIdisBrokerCustomersBirthdaysQuery} iIdisBrokerCustomersBirthdaysQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerCustomerBirthdays: (iIdisBrokerCustomersBirthdaysQuery, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iIdisBrokerCustomersBirthdaysQuery' is not null or undefined
            if (iIdisBrokerCustomersBirthdaysQuery === null || iIdisBrokerCustomersBirthdaysQuery === undefined) {
                throw new base_1.RequiredError('iIdisBrokerCustomersBirthdaysQuery', 'Required parameter iIdisBrokerCustomersBirthdaysQuery was null or undefined when calling getBrokerCustomerBirthdays.');
            }
            const localVarPath = `/idis/broker/customerBirthdays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iIdisBrokerCustomersBirthdaysQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iIdisBrokerCustomersBirthdaysQuery !== undefined ? iIdisBrokerCustomersBirthdaysQuery : {}) : (iIdisBrokerCustomersBirthdaysQuery || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} brokerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerPopulatedById: (brokerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'brokerId' is not null or undefined
            if (brokerId === null || brokerId === undefined) {
                throw new base_1.RequiredError('brokerId', 'Required parameter brokerId was null or undefined when calling getBrokerPopulatedById.');
            }
            const localVarPath = `/idis/broker/populated/{brokerId}`
                .replace(`{${"brokerId"}}`, encodeURIComponent(String(brokerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} brokerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerUnpolicedContracts: (brokerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'brokerId' is not null or undefined
            if (brokerId === null || brokerId === undefined) {
                throw new base_1.RequiredError('brokerId', 'Required parameter brokerId was null or undefined when calling getBrokerUnpolicedContracts.');
            }
            const localVarPath = `/idis/broker/unpolicedContractData/{brokerId}`
                .replace(`{${"brokerId"}}`, encodeURIComponent(String(brokerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAll: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/idis/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById: (companyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'companyId' is not null or undefined
            if (companyId === null || companyId === undefined) {
                throw new base_1.RequiredError('companyId', 'Required parameter companyId was null or undefined when calling getCompanyById.');
            }
            const localVarPath = `/idis/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IPaginate} [iPaginate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractAll: (iPaginate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/idis/contractAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iPaginate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iPaginate !== undefined ? iPaginate : {}) : (iPaginate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractAndDamages: (contractId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new base_1.RequiredError('contractId', 'Required parameter contractId was null or undefined when calling getContractAndDamages.');
            }
            const localVarPath = `/idis/contract/contractAndDamages/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById: (contractId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new base_1.RequiredError('contractId', 'Required parameter contractId was null or undefined when calling getContractById.');
            }
            const localVarPath = `/idis/contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAll: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/idis/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById: (customerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new base_1.RequiredError('customerId', 'Required parameter customerId was null or undefined when calling getCustomerById.');
            }
            const localVarPath = `/idis/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageAll: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/idis/damage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} damageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageById: (damageId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'damageId' is not null or undefined
            if (damageId === null || damageId === undefined) {
                throw new base_1.RequiredError('damageId', 'Required parameter damageId was null or undefined when calling getDamageById.');
            }
            const localVarPath = `/idis/damage/{damageId}`
                .replace(`{${"damageId"}}`, encodeURIComponent(String(damageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IIdisExportQuery} iIdisExportQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaCsvExport: (iIdisExportQuery, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iIdisExportQuery' is not null or undefined
            if (iIdisExportQuery === null || iIdisExportQuery === undefined) {
                throw new base_1.RequiredError('iIdisExportQuery', 'Required parameter iIdisExportQuery was null or undefined when calling getDegeniaCsvExport.');
            }
            const localVarPath = `/idis/export/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iIdisExportQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iIdisExportQuery !== undefined ? iIdisExportQuery : {}) : (iIdisExportQuery || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IIdisExportQuery} iIdisExportQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaExport: (iIdisExportQuery, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iIdisExportQuery' is not null or undefined
            if (iIdisExportQuery === null || iIdisExportQuery === undefined) {
                throw new base_1.RequiredError('iIdisExportQuery', 'Required parameter iIdisExportQuery was null or undefined when calling getDegeniaExport.');
            }
            const localVarPath = `/idis/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iIdisExportQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iIdisExportQuery !== undefined ? iIdisExportQuery : {}) : (iIdisExportQuery || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInactiveContracts: (customerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new base_1.RequiredError('customerId', 'Required parameter customerId was null or undefined when calling getInactiveContracts.');
            }
            const localVarPath = `/idis/customer/inactiveContract/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnCustomer: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/idis/customer-own`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopulatedCompanyById: (companyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'companyId' is not null or undefined
            if (companyId === null || companyId === undefined) {
                throw new base_1.RequiredError('companyId', 'Required parameter companyId was null or undefined when calling getPopulatedCompanyById.');
            }
            const localVarPath = `/idis/company/populated/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopulatedContractById: (contractId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new base_1.RequiredError('contractId', 'Required parameter contractId was null or undefined when calling getPopulatedContractById.');
            }
            const localVarPath = `/idis/contract/populated/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopulatedCustomerById: (customerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new base_1.RequiredError('customerId', 'Required parameter customerId was null or undefined when calling getPopulatedCustomerById.');
            }
            const localVarPath = `/idis/customer/populated/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAll: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/idis/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: (productId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new base_1.RequiredError('productId', 'Required parameter productId was null or undefined when calling getProductById.');
            }
            const localVarPath = `/idis/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCategoryAll: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/idis/productCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} productCategoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCategoryById: (productCategoryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'productCategoryId' is not null or undefined
            if (productCategoryId === null || productCategoryId === undefined) {
                throw new base_1.RequiredError('productCategoryId', 'Required parameter productCategoryId was null or undefined when calling getProductCategoryById.');
            }
            const localVarPath = `/idis/productCategory/{productCategoryId}`
                .replace(`{${"productCategoryId"}}`, encodeURIComponent(String(productCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRelatedCompanies: (productId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new base_1.RequiredError('productId', 'Required parameter productId was null or undefined when calling getProductRelatedCompanies.');
            }
            const localVarPath = `/idis/product/companies/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {TIdisUpdateBank} tIdisUpdateBank
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idisUpdateBank: (tIdisUpdateBank, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tIdisUpdateBank' is not null or undefined
            if (tIdisUpdateBank === null || tIdisUpdateBank === undefined) {
                throw new base_1.RequiredError('tIdisUpdateBank', 'Required parameter tIdisUpdateBank was null or undefined when calling idisUpdateBank.');
            }
            const localVarPath = `/idis/customer/updateBank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof tIdisUpdateBank !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(tIdisUpdateBank !== undefined ? tIdisUpdateBank : {}) : (tIdisUpdateBank || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {TIdisUpdatePersonal} tIdisUpdatePersonal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idisUpdatePersonal: (tIdisUpdatePersonal, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tIdisUpdatePersonal' is not null or undefined
            if (tIdisUpdatePersonal === null || tIdisUpdatePersonal === undefined) {
                throw new base_1.RequiredError('tIdisUpdatePersonal', 'Required parameter tIdisUpdatePersonal was null or undefined when calling idisUpdatePersonal.');
            }
            const localVarPath = `/idis/customer/updatePersonal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof tIdisUpdatePersonal !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(tIdisUpdatePersonal !== undefined ? tIdisUpdatePersonal : {}) : (tIdisUpdatePersonal || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IContractCustomerSearch} iContractCustomerSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContractCustomer: (iContractCustomerSearch, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iContractCustomerSearch' is not null or undefined
            if (iContractCustomerSearch === null || iContractCustomerSearch === undefined) {
                throw new base_1.RequiredError('iContractCustomerSearch', 'Required parameter iContractCustomerSearch was null or undefined when calling searchContractCustomer.');
            }
            const localVarPath = `/idis/contract/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iContractCustomerSearch !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iContractCustomerSearch !== undefined ? iContractCustomerSearch : {}) : (iContractCustomerSearch || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.IdisApiAxiosParamCreator = IdisApiAxiosParamCreator;
/**
 * IdisApi - functional programming interface
 * @export
 */
const IdisApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveContracts(customerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getActiveContracts(customerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerAll(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getBrokerAll(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} brokerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerById(brokerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getBrokerById(brokerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IIdisBrokerCustomersBirthdaysQuery} iIdisBrokerCustomersBirthdaysQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerCustomerBirthdays(iIdisBrokerCustomersBirthdaysQuery, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getBrokerCustomerBirthdays(iIdisBrokerCustomersBirthdaysQuery, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} brokerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerPopulatedById(brokerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getBrokerPopulatedById(brokerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} brokerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerUnpolicedContracts(brokerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getBrokerUnpolicedContracts(brokerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAll(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getCompanyAll(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(companyId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getCompanyById(companyId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IPaginate} [iPaginate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractAll(iPaginate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getContractAll(iPaginate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractAndDamages(contractId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getContractAndDamages(contractId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById(contractId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getContractById(contractId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAll(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getCustomerAll(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById(customerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getCustomerById(customerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageAll(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getDamageAll(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} damageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageById(damageId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getDamageById(damageId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IIdisExportQuery} iIdisExportQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaCsvExport(iIdisExportQuery, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getDegeniaCsvExport(iIdisExportQuery, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IIdisExportQuery} iIdisExportQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaExport(iIdisExportQuery, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getDegeniaExport(iIdisExportQuery, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInactiveContracts(customerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getInactiveContracts(customerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnCustomer(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getOwnCustomer(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopulatedCompanyById(companyId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getPopulatedCompanyById(companyId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopulatedContractById(contractId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getPopulatedContractById(contractId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopulatedCustomerById(customerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getPopulatedCustomerById(customerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAll(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getProductAll(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(productId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getProductById(productId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCategoryAll(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getProductCategoryAll(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} productCategoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCategoryById(productCategoryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getProductCategoryById(productCategoryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRelatedCompanies(productId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).getProductRelatedCompanies(productId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {TIdisUpdateBank} tIdisUpdateBank
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idisUpdateBank(tIdisUpdateBank, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).idisUpdateBank(tIdisUpdateBank, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {TIdisUpdatePersonal} tIdisUpdatePersonal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idisUpdatePersonal(tIdisUpdatePersonal, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).idisUpdatePersonal(tIdisUpdatePersonal, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IContractCustomerSearch} iContractCustomerSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContractCustomer(iContractCustomerSearch, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.IdisApiAxiosParamCreator(configuration).searchContractCustomer(iContractCustomerSearch, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.IdisApiFp = IdisApiFp;
/**
 * IdisApi - factory interface
 * @export
 */
const IdisApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveContracts(customerId, options) {
            return exports.IdisApiFp(configuration).getActiveContracts(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerAll(options) {
            return exports.IdisApiFp(configuration).getBrokerAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} brokerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerById(brokerId, options) {
            return exports.IdisApiFp(configuration).getBrokerById(brokerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IIdisBrokerCustomersBirthdaysQuery} iIdisBrokerCustomersBirthdaysQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerCustomerBirthdays(iIdisBrokerCustomersBirthdaysQuery, options) {
            return exports.IdisApiFp(configuration).getBrokerCustomerBirthdays(iIdisBrokerCustomersBirthdaysQuery, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} brokerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerPopulatedById(brokerId, options) {
            return exports.IdisApiFp(configuration).getBrokerPopulatedById(brokerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} brokerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerUnpolicedContracts(brokerId, options) {
            return exports.IdisApiFp(configuration).getBrokerUnpolicedContracts(brokerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAll(options) {
            return exports.IdisApiFp(configuration).getCompanyAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(companyId, options) {
            return exports.IdisApiFp(configuration).getCompanyById(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IPaginate} [iPaginate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractAll(iPaginate, options) {
            return exports.IdisApiFp(configuration).getContractAll(iPaginate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractAndDamages(contractId, options) {
            return exports.IdisApiFp(configuration).getContractAndDamages(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById(contractId, options) {
            return exports.IdisApiFp(configuration).getContractById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAll(options) {
            return exports.IdisApiFp(configuration).getCustomerAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById(customerId, options) {
            return exports.IdisApiFp(configuration).getCustomerById(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageAll(options) {
            return exports.IdisApiFp(configuration).getDamageAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} damageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageById(damageId, options) {
            return exports.IdisApiFp(configuration).getDamageById(damageId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IIdisExportQuery} iIdisExportQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaCsvExport(iIdisExportQuery, options) {
            return exports.IdisApiFp(configuration).getDegeniaCsvExport(iIdisExportQuery, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IIdisExportQuery} iIdisExportQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaExport(iIdisExportQuery, options) {
            return exports.IdisApiFp(configuration).getDegeniaExport(iIdisExportQuery, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInactiveContracts(customerId, options) {
            return exports.IdisApiFp(configuration).getInactiveContracts(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnCustomer(options) {
            return exports.IdisApiFp(configuration).getOwnCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopulatedCompanyById(companyId, options) {
            return exports.IdisApiFp(configuration).getPopulatedCompanyById(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopulatedContractById(contractId, options) {
            return exports.IdisApiFp(configuration).getPopulatedContractById(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopulatedCustomerById(customerId, options) {
            return exports.IdisApiFp(configuration).getPopulatedCustomerById(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAll(options) {
            return exports.IdisApiFp(configuration).getProductAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(productId, options) {
            return exports.IdisApiFp(configuration).getProductById(productId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCategoryAll(options) {
            return exports.IdisApiFp(configuration).getProductCategoryAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} productCategoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCategoryById(productCategoryId, options) {
            return exports.IdisApiFp(configuration).getProductCategoryById(productCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRelatedCompanies(productId, options) {
            return exports.IdisApiFp(configuration).getProductRelatedCompanies(productId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {TIdisUpdateBank} tIdisUpdateBank
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idisUpdateBank(tIdisUpdateBank, options) {
            return exports.IdisApiFp(configuration).idisUpdateBank(tIdisUpdateBank, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {TIdisUpdatePersonal} tIdisUpdatePersonal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        idisUpdatePersonal(tIdisUpdatePersonal, options) {
            return exports.IdisApiFp(configuration).idisUpdatePersonal(tIdisUpdatePersonal, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IContractCustomerSearch} iContractCustomerSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContractCustomer(iContractCustomerSearch, options) {
            return exports.IdisApiFp(configuration).searchContractCustomer(iContractCustomerSearch, options).then((request) => request(axios, basePath));
        },
    };
};
exports.IdisApiFactory = IdisApiFactory;
/**
 * IdisApi - object-oriented interface
 * @export
 * @class IdisApi
 * @extends {BaseAPI}
 */
class IdisApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getActiveContracts(customerId, options) {
        return exports.IdisApiFp(this.configuration).getActiveContracts(customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getBrokerAll(options) {
        return exports.IdisApiFp(this.configuration).getBrokerAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} brokerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getBrokerById(brokerId, options) {
        return exports.IdisApiFp(this.configuration).getBrokerById(brokerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IIdisBrokerCustomersBirthdaysQuery} iIdisBrokerCustomersBirthdaysQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getBrokerCustomerBirthdays(iIdisBrokerCustomersBirthdaysQuery, options) {
        return exports.IdisApiFp(this.configuration).getBrokerCustomerBirthdays(iIdisBrokerCustomersBirthdaysQuery, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} brokerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getBrokerPopulatedById(brokerId, options) {
        return exports.IdisApiFp(this.configuration).getBrokerPopulatedById(brokerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} brokerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getBrokerUnpolicedContracts(brokerId, options) {
        return exports.IdisApiFp(this.configuration).getBrokerUnpolicedContracts(brokerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getCompanyAll(options) {
        return exports.IdisApiFp(this.configuration).getCompanyAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getCompanyById(companyId, options) {
        return exports.IdisApiFp(this.configuration).getCompanyById(companyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IPaginate} [iPaginate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getContractAll(iPaginate, options) {
        return exports.IdisApiFp(this.configuration).getContractAll(iPaginate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} contractId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getContractAndDamages(contractId, options) {
        return exports.IdisApiFp(this.configuration).getContractAndDamages(contractId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} contractId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getContractById(contractId, options) {
        return exports.IdisApiFp(this.configuration).getContractById(contractId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getCustomerAll(options) {
        return exports.IdisApiFp(this.configuration).getCustomerAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getCustomerById(customerId, options) {
        return exports.IdisApiFp(this.configuration).getCustomerById(customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getDamageAll(options) {
        return exports.IdisApiFp(this.configuration).getDamageAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} damageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getDamageById(damageId, options) {
        return exports.IdisApiFp(this.configuration).getDamageById(damageId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IIdisExportQuery} iIdisExportQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getDegeniaCsvExport(iIdisExportQuery, options) {
        return exports.IdisApiFp(this.configuration).getDegeniaCsvExport(iIdisExportQuery, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IIdisExportQuery} iIdisExportQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getDegeniaExport(iIdisExportQuery, options) {
        return exports.IdisApiFp(this.configuration).getDegeniaExport(iIdisExportQuery, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getInactiveContracts(customerId, options) {
        return exports.IdisApiFp(this.configuration).getInactiveContracts(customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getOwnCustomer(options) {
        return exports.IdisApiFp(this.configuration).getOwnCustomer(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getPopulatedCompanyById(companyId, options) {
        return exports.IdisApiFp(this.configuration).getPopulatedCompanyById(companyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} contractId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getPopulatedContractById(contractId, options) {
        return exports.IdisApiFp(this.configuration).getPopulatedContractById(contractId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getPopulatedCustomerById(customerId, options) {
        return exports.IdisApiFp(this.configuration).getPopulatedCustomerById(customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getProductAll(options) {
        return exports.IdisApiFp(this.configuration).getProductAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getProductById(productId, options) {
        return exports.IdisApiFp(this.configuration).getProductById(productId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getProductCategoryAll(options) {
        return exports.IdisApiFp(this.configuration).getProductCategoryAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} productCategoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getProductCategoryById(productCategoryId, options) {
        return exports.IdisApiFp(this.configuration).getProductCategoryById(productCategoryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    getProductRelatedCompanies(productId, options) {
        return exports.IdisApiFp(this.configuration).getProductRelatedCompanies(productId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {TIdisUpdateBank} tIdisUpdateBank
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    idisUpdateBank(tIdisUpdateBank, options) {
        return exports.IdisApiFp(this.configuration).idisUpdateBank(tIdisUpdateBank, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {TIdisUpdatePersonal} tIdisUpdatePersonal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    idisUpdatePersonal(tIdisUpdatePersonal, options) {
        return exports.IdisApiFp(this.configuration).idisUpdatePersonal(tIdisUpdatePersonal, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IContractCustomerSearch} iContractCustomerSearch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdisApi
     */
    searchContractCustomer(iContractCustomerSearch, options) {
        return exports.IdisApiFp(this.configuration).searchContractCustomer(iContractCustomerSearch, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.IdisApi = IdisApi;
/**
 * MailApi - axios parameter creator
 * @export
 */
const MailApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {IMailSendAddressChange} iMailSendAddressChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaAddressChange: (iMailSendAddressChange, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailSendAddressChange' is not null or undefined
            if (iMailSendAddressChange === null || iMailSendAddressChange === undefined) {
                throw new base_1.RequiredError('iMailSendAddressChange', 'Required parameter iMailSendAddressChange was null or undefined when calling sendDegeniaAddressChange.');
            }
            const localVarPath = `/mail/degenia/contract/sendAddressChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailSendAddressChange !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailSendAddressChange !== undefined ? iMailSendAddressChange : {}) : (iMailSendAddressChange || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IMailSendBankChange} iMailSendBankChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBankChange: (iMailSendBankChange, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailSendBankChange' is not null or undefined
            if (iMailSendBankChange === null || iMailSendBankChange === undefined) {
                throw new base_1.RequiredError('iMailSendBankChange', 'Required parameter iMailSendBankChange was null or undefined when calling sendDegeniaBankChange.');
            }
            const localVarPath = `/mail/degenia/contract/sendBankChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailSendBankChange !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailSendBankChange !== undefined ? iMailSendBankChange : {}) : (iMailSendBankChange || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IMailSendBecomeOurPartner} iMailSendBecomeOurPartner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBecomeOurPartner: (iMailSendBecomeOurPartner, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailSendBecomeOurPartner' is not null or undefined
            if (iMailSendBecomeOurPartner === null || iMailSendBecomeOurPartner === undefined) {
                throw new base_1.RequiredError('iMailSendBecomeOurPartner', 'Required parameter iMailSendBecomeOurPartner was null or undefined when calling sendDegeniaBecomeOurPartner.');
            }
            const localVarPath = `/mail/degenia/sendBecomeOurPartner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailSendBecomeOurPartner !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailSendBecomeOurPartner !== undefined ? iMailSendBecomeOurPartner : {}) : (iMailSendBecomeOurPartner || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IMailSendDirectMessage} iMailSendDirectMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaDirectMessage: (iMailSendDirectMessage, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailSendDirectMessage' is not null or undefined
            if (iMailSendDirectMessage === null || iMailSendDirectMessage === undefined) {
                throw new base_1.RequiredError('iMailSendDirectMessage', 'Required parameter iMailSendDirectMessage was null or undefined when calling sendDegeniaDirectMessage.');
            }
            const localVarPath = `/mail/degenia/contract/sendDirectMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailSendDirectMessage !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailSendDirectMessage !== undefined ? iMailSendDirectMessage : {}) : (iMailSendDirectMessage || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IMailSendStrapiMail} iMailSendStrapiMail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStrapiMail: (iMailSendStrapiMail, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailSendStrapiMail' is not null or undefined
            if (iMailSendStrapiMail === null || iMailSendStrapiMail === undefined) {
                throw new base_1.RequiredError('iMailSendStrapiMail', 'Required parameter iMailSendStrapiMail was null or undefined when calling sendStrapiMail.');
            }
            const localVarPath = `/mail/sendStrapiMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailSendStrapiMail !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailSendStrapiMail !== undefined ? iMailSendStrapiMail : {}) : (iMailSendStrapiMail || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.MailApiAxiosParamCreator = MailApiAxiosParamCreator;
/**
 * MailApi - functional programming interface
 * @export
 */
const MailApiFp = function (configuration) {
    return {
        /**
         *
         * @param {IMailSendAddressChange} iMailSendAddressChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaAddressChange(iMailSendAddressChange, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendDegeniaAddressChange(iMailSendAddressChange, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IMailSendBankChange} iMailSendBankChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBankChange(iMailSendBankChange, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendDegeniaBankChange(iMailSendBankChange, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IMailSendBecomeOurPartner} iMailSendBecomeOurPartner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IMailSendDirectMessage} iMailSendDirectMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaDirectMessage(iMailSendDirectMessage, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendDegeniaDirectMessage(iMailSendDirectMessage, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IMailSendStrapiMail} iMailSendStrapiMail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStrapiMail(iMailSendStrapiMail, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendStrapiMail(iMailSendStrapiMail, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.MailApiFp = MailApiFp;
/**
 * MailApi - factory interface
 * @export
 */
const MailApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {IMailSendAddressChange} iMailSendAddressChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaAddressChange(iMailSendAddressChange, options) {
            return exports.MailApiFp(configuration).sendDegeniaAddressChange(iMailSendAddressChange, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IMailSendBankChange} iMailSendBankChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBankChange(iMailSendBankChange, options) {
            return exports.MailApiFp(configuration).sendDegeniaBankChange(iMailSendBankChange, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IMailSendBecomeOurPartner} iMailSendBecomeOurPartner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options) {
            return exports.MailApiFp(configuration).sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IMailSendDirectMessage} iMailSendDirectMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaDirectMessage(iMailSendDirectMessage, options) {
            return exports.MailApiFp(configuration).sendDegeniaDirectMessage(iMailSendDirectMessage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IMailSendStrapiMail} iMailSendStrapiMail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStrapiMail(iMailSendStrapiMail, options) {
            return exports.MailApiFp(configuration).sendStrapiMail(iMailSendStrapiMail, options).then((request) => request(axios, basePath));
        },
    };
};
exports.MailApiFactory = MailApiFactory;
/**
 * MailApi - object-oriented interface
 * @export
 * @class MailApi
 * @extends {BaseAPI}
 */
class MailApi extends base_1.BaseAPI {
    /**
     *
     * @param {IMailSendAddressChange} iMailSendAddressChange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendDegeniaAddressChange(iMailSendAddressChange, options) {
        return exports.MailApiFp(this.configuration).sendDegeniaAddressChange(iMailSendAddressChange, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IMailSendBankChange} iMailSendBankChange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendDegeniaBankChange(iMailSendBankChange, options) {
        return exports.MailApiFp(this.configuration).sendDegeniaBankChange(iMailSendBankChange, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IMailSendBecomeOurPartner} iMailSendBecomeOurPartner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options) {
        return exports.MailApiFp(this.configuration).sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IMailSendDirectMessage} iMailSendDirectMessage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendDegeniaDirectMessage(iMailSendDirectMessage, options) {
        return exports.MailApiFp(this.configuration).sendDegeniaDirectMessage(iMailSendDirectMessage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IMailSendStrapiMail} iMailSendStrapiMail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendStrapiMail(iMailSendStrapiMail, options) {
        return exports.MailApiFp(this.configuration).sendStrapiMail(iMailSendStrapiMail, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.MailApi = MailApi;
/**
 * UserApi - axios parameter creator
 * @export
 */
const UserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: (token, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new base_1.RequiredError('token', 'Required parameter token was null or undefined when calling activate.');
            }
            const localVarPath = `/user/activate/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {TCredentials} tCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: (tCredentials, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tCredentials' is not null or undefined
            if (tCredentials === null || tCredentials === undefined) {
                throw new base_1.RequiredError('tCredentials', 'Required parameter tCredentials was null or undefined when calling authenticate.');
            }
            const localVarPath = `/user/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof tCredentials !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(tCredentials !== undefined ? tCredentials : {}) : (tCredentials || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {INewUser} iNewUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewUser: (iNewUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iNewUser' is not null or undefined
            if (iNewUser === null || iNewUser === undefined) {
                throw new base_1.RequiredError('iNewUser', 'Required parameter iNewUser was null or undefined when calling createNewUser.');
            }
            const localVarPath = `/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iNewUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iNewUser !== undefined ? iNewUser : {}) : (iNewUser || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling duplicate.');
            }
            const localVarPath = `/user/duplicate/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRoles: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/get-available-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getSettings.');
            }
            const localVarPath = `/user/settings/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserById.');
            }
            const localVarPath = `/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveRoleToUser: (iUserRole, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserRole' is not null or undefined
            if (iUserRole === null || iUserRole === undefined) {
                throw new base_1.RequiredError('iUserRole', 'Required parameter iUserRole was null or undefined when calling giveRoleToUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling giveRoleToUser.');
            }
            const localVarPath = `/user/add-role/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserRole !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserRole !== undefined ? iUserRole : {}) : (iUserRole || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IActivatableUser} iActivatableUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUser: (iActivatableUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iActivatableUser' is not null or undefined
            if (iActivatableUser === null || iActivatableUser === undefined) {
                throw new base_1.RequiredError('iActivatableUser', 'Required parameter iActivatableUser was null or undefined when calling registerNewUser.');
            }
            const localVarPath = `/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iActivatableUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iActivatableUser !== undefined ? iActivatableUser : {}) : (iActivatableUser || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRoleFromUser: (iUserRole, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserRole' is not null or undefined
            if (iUserRole === null || iUserRole === undefined) {
                throw new base_1.RequiredError('iUserRole', 'Required parameter iUserRole was null or undefined when calling removeRoleFromUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling removeRoleFromUser.');
            }
            const localVarPath = `/user/remove-role/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserRole !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserRole !== undefined ? iUserRole : {}) : (iUserRole || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserUserPasswordResetRequest} iUserUserPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordRequest: (iUserUserPasswordResetRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserUserPasswordResetRequest' is not null or undefined
            if (iUserUserPasswordResetRequest === null || iUserUserPasswordResetRequest === undefined) {
                throw new base_1.RequiredError('iUserUserPasswordResetRequest', 'Required parameter iUserUserPasswordResetRequest was null or undefined when calling resetPasswordRequest.');
            }
            const localVarPath = `/user/resetPasswordRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserUserPasswordResetRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserUserPasswordResetRequest !== undefined ? iUserUserPasswordResetRequest : {}) : (iUserUserPasswordResetRequest || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByUsername: (username, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new base_1.RequiredError('username', 'Required parameter username was null or undefined when calling searchByUsername.');
            }
            const localVarPath = `/user/search/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserUserUpdate} iUserUserUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: (iUserUserUpdate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserUserUpdate' is not null or undefined
            if (iUserUserUpdate === null || iUserUserUpdate === undefined) {
                throw new base_1.RequiredError('iUserUserUpdate', 'Required parameter iUserUserUpdate was null or undefined when calling update.');
            }
            const localVarPath = `/user/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserUserUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserUserUpdate !== undefined ? iUserUserUpdate : {}) : (iUserUserUpdate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserUserPasswordResetUpdate} iUserUserPasswordResetUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResettedPassword: (iUserUserPasswordResetUpdate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserUserPasswordResetUpdate' is not null or undefined
            if (iUserUserPasswordResetUpdate === null || iUserUserPasswordResetUpdate === undefined) {
                throw new base_1.RequiredError('iUserUserPasswordResetUpdate', 'Required parameter iUserUserPasswordResetUpdate was null or undefined when calling updateResettedPassword.');
            }
            const localVarPath = `/user/updateResettedPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserUserPasswordResetUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserUserPasswordResetUpdate !== undefined ? iUserUserPasswordResetUpdate : {}) : (iUserUserPasswordResetUpdate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IApiUserUpdateRole} iApiUserUpdateRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleOfUser: (iApiUserUpdateRole, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iApiUserUpdateRole' is not null or undefined
            if (iApiUserUpdateRole === null || iApiUserUpdateRole === undefined) {
                throw new base_1.RequiredError('iApiUserUpdateRole', 'Required parameter iApiUserUpdateRole was null or undefined when calling updateRoleOfUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling updateRoleOfUser.');
            }
            const localVarPath = `/user/update-role/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iApiUserUpdateRole !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iApiUserUpdateRole !== undefined ? iApiUserUpdateRole : {}) : (iApiUserUpdateRole || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserUpdateSettings} iUserUpdateSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: (iUserUpdateSettings, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserUpdateSettings' is not null or undefined
            if (iUserUpdateSettings === null || iUserUpdateSettings === undefined) {
                throw new base_1.RequiredError('iUserUpdateSettings', 'Required parameter iUserUpdateSettings was null or undefined when calling updateSettings.');
            }
            const localVarPath = `/user/updateSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserUpdateSettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserUpdateSettings !== undefined ? iUserUpdateSettings : {}) : (iUserUpdateSettings || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserPasswordResetToken} iUserPasswordResetToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordRequestToken: (iUserPasswordResetToken, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserPasswordResetToken' is not null or undefined
            if (iUserPasswordResetToken === null || iUserPasswordResetToken === undefined) {
                throw new base_1.RequiredError('iUserPasswordResetToken', 'Required parameter iUserPasswordResetToken was null or undefined when calling validatePasswordRequestToken.');
            }
            const localVarPath = `/user/validatePasswordRequestToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserPasswordResetToken !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserPasswordResetToken !== undefined ? iUserPasswordResetToken : {}) : (iUserPasswordResetToken || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.UserApiAxiosParamCreator = UserApiAxiosParamCreator;
/**
 * UserApi - functional programming interface
 * @export
 */
const UserApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(token, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).activate(token, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {TCredentials} tCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(tCredentials, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).authenticate(tCredentials, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {INewUser} iNewUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewUser(iNewUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).createNewUser(iNewUser, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).duplicate(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).getAll(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRoles(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).getAvailableRoles(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).getSettings(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).getUserById(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveRoleToUser(iUserRole, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).giveRoleToUser(iUserRole, userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IActivatableUser} iActivatableUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUser(iActivatableUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).registerNewUser(iActivatableUser, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRoleFromUser(iUserRole, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).removeRoleFromUser(iUserRole, userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserUserPasswordResetRequest} iUserUserPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordRequest(iUserUserPasswordResetRequest, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).resetPasswordRequest(iUserUserPasswordResetRequest, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByUsername(username, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).searchByUsername(username, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserUserUpdate} iUserUserUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(iUserUserUpdate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).update(iUserUserUpdate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserUserPasswordResetUpdate} iUserUserPasswordResetUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResettedPassword(iUserUserPasswordResetUpdate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).updateResettedPassword(iUserUserPasswordResetUpdate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IApiUserUpdateRole} iApiUserUpdateRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleOfUser(iApiUserUpdateRole, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).updateRoleOfUser(iApiUserUpdateRole, userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserUpdateSettings} iUserUpdateSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(iUserUpdateSettings, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).updateSettings(iUserUpdateSettings, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserPasswordResetToken} iUserPasswordResetToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordRequestToken(iUserPasswordResetToken, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).validatePasswordRequestToken(iUserPasswordResetToken, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.UserApiFp = UserApiFp;
/**
 * UserApi - factory interface
 * @export
 */
const UserApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(token, options) {
            return exports.UserApiFp(configuration).activate(token, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {TCredentials} tCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(tCredentials, options) {
            return exports.UserApiFp(configuration).authenticate(tCredentials, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {INewUser} iNewUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewUser(iNewUser, options) {
            return exports.UserApiFp(configuration).createNewUser(iNewUser, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate(userId, options) {
            return exports.UserApiFp(configuration).duplicate(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options) {
            return exports.UserApiFp(configuration).getAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRoles(options) {
            return exports.UserApiFp(configuration).getAvailableRoles(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(userId, options) {
            return exports.UserApiFp(configuration).getSettings(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId, options) {
            return exports.UserApiFp(configuration).getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveRoleToUser(iUserRole, userId, options) {
            return exports.UserApiFp(configuration).giveRoleToUser(iUserRole, userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IActivatableUser} iActivatableUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUser(iActivatableUser, options) {
            return exports.UserApiFp(configuration).registerNewUser(iActivatableUser, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRoleFromUser(iUserRole, userId, options) {
            return exports.UserApiFp(configuration).removeRoleFromUser(iUserRole, userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserUserPasswordResetRequest} iUserUserPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordRequest(iUserUserPasswordResetRequest, options) {
            return exports.UserApiFp(configuration).resetPasswordRequest(iUserUserPasswordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByUsername(username, options) {
            return exports.UserApiFp(configuration).searchByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserUserUpdate} iUserUserUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(iUserUserUpdate, options) {
            return exports.UserApiFp(configuration).update(iUserUserUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserUserPasswordResetUpdate} iUserUserPasswordResetUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResettedPassword(iUserUserPasswordResetUpdate, options) {
            return exports.UserApiFp(configuration).updateResettedPassword(iUserUserPasswordResetUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IApiUserUpdateRole} iApiUserUpdateRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleOfUser(iApiUserUpdateRole, userId, options) {
            return exports.UserApiFp(configuration).updateRoleOfUser(iApiUserUpdateRole, userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserUpdateSettings} iUserUpdateSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(iUserUpdateSettings, options) {
            return exports.UserApiFp(configuration).updateSettings(iUserUpdateSettings, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserPasswordResetToken} iUserPasswordResetToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordRequestToken(iUserPasswordResetToken, options) {
            return exports.UserApiFp(configuration).validatePasswordRequestToken(iUserPasswordResetToken, options).then((request) => request(axios, basePath));
        },
    };
};
exports.UserApiFactory = UserApiFactory;
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
class UserApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    activate(token, options) {
        return exports.UserApiFp(this.configuration).activate(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {TCredentials} tCredentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    authenticate(tCredentials, options) {
        return exports.UserApiFp(this.configuration).authenticate(tCredentials, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {INewUser} iNewUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    createNewUser(iNewUser, options) {
        return exports.UserApiFp(this.configuration).createNewUser(iNewUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    duplicate(userId, options) {
        return exports.UserApiFp(this.configuration).duplicate(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getAll(options) {
        return exports.UserApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getAvailableRoles(options) {
        return exports.UserApiFp(this.configuration).getAvailableRoles(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getSettings(userId, options) {
        return exports.UserApiFp(this.configuration).getSettings(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getUserById(userId, options) {
        return exports.UserApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserRole} iUserRole
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    giveRoleToUser(iUserRole, userId, options) {
        return exports.UserApiFp(this.configuration).giveRoleToUser(iUserRole, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IActivatableUser} iActivatableUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    registerNewUser(iActivatableUser, options) {
        return exports.UserApiFp(this.configuration).registerNewUser(iActivatableUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserRole} iUserRole
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    removeRoleFromUser(iUserRole, userId, options) {
        return exports.UserApiFp(this.configuration).removeRoleFromUser(iUserRole, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserUserPasswordResetRequest} iUserUserPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    resetPasswordRequest(iUserUserPasswordResetRequest, options) {
        return exports.UserApiFp(this.configuration).resetPasswordRequest(iUserUserPasswordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    searchByUsername(username, options) {
        return exports.UserApiFp(this.configuration).searchByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserUserUpdate} iUserUserUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    update(iUserUserUpdate, options) {
        return exports.UserApiFp(this.configuration).update(iUserUserUpdate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserUserPasswordResetUpdate} iUserUserPasswordResetUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    updateResettedPassword(iUserUserPasswordResetUpdate, options) {
        return exports.UserApiFp(this.configuration).updateResettedPassword(iUserUserPasswordResetUpdate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IApiUserUpdateRole} iApiUserUpdateRole
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    updateRoleOfUser(iApiUserUpdateRole, userId, options) {
        return exports.UserApiFp(this.configuration).updateRoleOfUser(iApiUserUpdateRole, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserUpdateSettings} iUserUpdateSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    updateSettings(iUserUpdateSettings, options) {
        return exports.UserApiFp(this.configuration).updateSettings(iUserUpdateSettings, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserPasswordResetToken} iUserPasswordResetToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    validatePasswordRequestToken(iUserPasswordResetToken, options) {
        return exports.UserApiFp(this.configuration).validatePasswordRequestToken(iUserPasswordResetToken, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.UserApi = UserApi;
