import { DegeniaGenericForm } from "../degenia-generic-form"
import { EInputType } from "../degenia-input-field/enum"
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { ICmsDegeniaContentTypeNote } from "@teamparallax/api-gateway-rest-api"
import { IDegeniaGenericFormFields } from "../degenia-generic-form/interface"
import { INoteContentProps, INoteProps } from "./interface"
import { successToast } from "@teamparallax/react-components"
import { useApiGatewayRest, useAuth } from "@teamparallax/react-components"
import { useTranslation } from "react-i18next"
import React, {
	FC, useCallback, useEffect, useState
} from "react"
export const Note: FC<INoteProps> = ({
	componentId
}) => {
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	const {
		cmsApi
	} = useApiGatewayRest()
	const {
		profile
	} = useAuth()
	const userId = profile?.id
	const [note, setNote] = useState<ICmsDegeniaContentTypeNote | undefined>()
	const loadNote = useCallback(
		async (): Promise<void> => {
			const {
				data: notes
			} = await cmsApi.getAllDegeniaNotes()
			/**
		 * TODO: provide filter object to getAllDegeniaNotes
		 */
			const wantedUserNote = notes.find(
				(note) => note.createdBy === userId
			&& note.componentId === componentId
			)
			setNote(wantedUserNote)
		},
		[userId, componentId, cmsApi]
	)
	useEffect(() => {
		void loadNote()
	},
	[userId, componentId, cmsApi, loadNote])
	const fields: IDegeniaGenericFormFields[] = [
		{
			defaultValue: note?.content,
			name: "note",
			placeholder: t(`notePlaceholder`),
			type: EInputType.textarea
		}
	]
	const submitCallback = async (values: INoteContentProps): Promise<void> => {
		if (note) {
			await cmsApi.updateDegeniaNote(
				{
					componentId,
					content: values.note,
					createdBy: note.createdBy
				},
				note.id
			)
		}
		else {
			await cmsApi.createDegeniaNote({
				componentId,
				content: values.note,
				createdBy: userId
			})
		}
		await loadNote()
		successToast(t(`noteSaved`))
	}
	return (
		<DegeniaGenericForm
			fields={ fields }
			resetButtonTitle={ t(`cancel`) }
			shouldHaveMarginIfNoTitleIsPresent
			submitButtonTitle={ t(`save`) }
			submitCallback={ submitCallback }
		/>
	)
}