import React, { FC } from "react"
import s from "./dashboard.module.scss"
export const Dashboard: FC = ({
	children
}) => {
	return (
		<section
			className={ s.dashboard }
		>
			{children}
		</section>
	)
}