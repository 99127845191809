import { DegeniaTable } from "../index"
import { IDegeniaProductsTableProps } from "./interface"
import { IDegeniaTableData } from "../interface"
import { TDegeniaTableCell } from "../type"
import CheckSvg from "../../../../static/degenia/check.svg"
import React, { FC } from "react"
import s from "./index.module.scss"
export const ProductsTable: FC<IDegeniaProductsTableProps> = ({
	data
}) => {
	const sanitizedBody: TDegeniaTableCell[][] = []
	for (const row of data.body) {
		const sanitizedRow: TDegeniaTableCell[] = []
		for (const cell of row) {
			if (!cell) {
				sanitizedRow.push(null)
			}
			else if (typeof cell === "boolean") {
				sanitizedRow.push((
					<div
						className={ s.productCell }
					>
						<CheckSvg/>
					</div>
				))
			}
			else {
				sanitizedRow.push(cell)
			}
		}
		sanitizedBody.push(sanitizedRow)
	}
	const sanitizedTableData: IDegeniaTableData = {
		...data,
		body: sanitizedBody
	}
	return (
		<DegeniaTable
			data={ sanitizedTableData }
			isFirstColumnBold
		/>
	)
}