import { IExternalFileProps } from "./interface"
import { parseStrapiUrl } from "../../../utils/strapi"
import { useAppConfig } from "@teamparallax/react-components"
import IconSVG from "../../../../static/degenia/external-file-icon.svg"
import React, { FC } from "react"
import cn from "classnames"
import s from "./external-file.module.scss"
export const ExternalFile: FC<IExternalFileProps> = ({
	className,
	description,
	title,
	url,
	isUsedInPortal
}) => {
	const {
		strapiUrl
	} = useAppConfig()
	return (
		<a
			className={ cn(
				s.externalFile,
				className,
				{
					[s.externalFileAdminPortal]: isUsedInPortal
				}
			) }
			data-shortcode="extenal-file"
			href={ parseStrapiUrl(strapiUrl, url) }
			rel="noreferrer"
			target="_blank"
		>
			<IconSVG
				className={ cn(s.icon) }
			/>
			<span
				className={ s.externalFileText }
			>
				{ title }
				{ description && (
					<small>
						({ description })
					</small>
				) }
			</span>
		</a>
	)
}