import { DegeniaButton } from "../../degenia-button"
import { TTextShortCodePlugin } from "@teamparallax/react-components"
import React, { ReactElement } from "react"
const render: TTextShortCodePlugin = (
	attributes: Record<"url"|"title"|"newTab", string>
): ReactElement => {
	const {
		url,
		title
	} = attributes
	const shouldOpenInNewTab = attributes.hasOwnProperty("newTab")
	return (
		<DegeniaButton
			href={ url }
			shouldOpenInNewTab={ shouldOpenInNewTab }
			title={ title }
		/>
	)
}
export const degeniaButtonShortcode = {
	name: "degenia-button",
	render
}