import { EMrMoneyCalculator } from "./enum"
import { IMrMoneyIframe } from "./interface"
import React, { FC } from "react"
export const MrMoneyIframe: FC<IMrMoneyIframe> = ({
	calculatorType
}) => {
	const {
		height,
		url
	} = getIframeData(calculatorType)
	return (
		<iframe
			src={ url }
			style={ {
				border: 0,
				height
			} }
			width="100%"
		/>
	)
}
interface IIframeData {
	height: number
	url: string
}
/* @TODO host on own webspace */
export const iframeCssUrl = `https://work.myr.ae/degenia/rechner/css/style.css`
const getIframeData = (calculatorType: EMrMoneyCalculator): IIframeData => {
	const baseUrl = `https://www.mr-money.de/cookievgl.php?sp=`
	const id = `degenia`
	const {
		type,
		height
	} = iframeData[calculatorType]
	return {
		height,
		url: `${baseUrl}${type}&id=${id}&css_url=${iframeCssUrl}`
	}
}
const iframeData = {
	[EMrMoneyCalculator.personalLiabilityInsurance]: {
		height: 2450,
		type: `phv`
	}
}