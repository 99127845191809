import { IStatsPaneProps } from "./interface"
import { StatsBox } from "./stats-box"
import Carousel from "react-elastic-carousel"
import React, { FC } from "react"
import s from "./stats.module.scss"
export const StatsPane: FC<IStatsPaneProps> = ({
	boxProps
}) => {
	const breakPoints = [
		{
			itemsToShow: 1,
			width: 0
		},
		{
			itemsToShow: 2,
			width: 570
		},
		{
			itemsToShow: 3,
			width: 790
		},
		{
			itemsToShow: 4,
			width: 990
		}
	]
	return (
		<div
			className={ s.statsPane }
		>
			<Carousel
				breakPoints={ breakPoints }
				className={ s.carousel }
				isRTL={ false }
				pagination={ false }
			>
				{
					boxProps.map((boxProp) => (
						<StatsBox
							icon={ boxProp.icon }
							key={ `${boxProp.icon}-${boxProp.text}` }
							text={ boxProp.text }
							value={ boxProp.value }
						/>
					))
				}
			</Carousel>
		</div>
	)
}