import { TSelectInputField } from "./interface"
import React, { FC } from "react"
import Select from "react-select"
import s from "./select-input-field.module.scss"
export const SelectInputField: FC<TSelectInputField> = ({
	...props
}) => {
	return (
		<Select
			classNamePrefix={ s.reactSelect }
			{ ...props }
		/>
	)
}