import { IAccordionSectionProps } from "./interface"
import React, { FC, useState } from "react"
import TriangleIcon from "../../../../static/degenia/triangle-small.svg"
import cn from "classnames"
import s from "./accordion-section.module.scss"
export const AccordionSection: FC<IAccordionSectionProps> = ({
	children,
	isInitialOpen,
	id,
	title
}) => {
	const [isOpen, setOpen] = useState(isInitialOpen)
	return (
		<>
			<div
				className={ cn(s.accordionSectionHeader, {
					[s.accordionSectionHeaderActive]: isOpen
				}) }
				id={ id }
				onClick={ (): void => setOpen(!isOpen) }
			>
				{ title }
				<TriangleIcon
					className={ s.icon }
				/>
			</div>
			<div
				className={ cn(s.accordionSectionBody, {
					[s.accordionSectionBodyOpened]: isOpen
				}) }
			>
				{ children }
			</div>
		</>
	)
}