import React, { FC } from "react"
import s from "./area.module.scss"
export const AreaContainer:FC = ({
	children
}) => {
	return (
		<span
			className={ s.areaContainer }
		>
			{children}
		</span>
	)
}