import { Container } from "../../components/container"
import { EPaneColor } from "../../utils/enum"
import { IPaneProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./pane.module.scss"
export const Pane: FC<IPaneProps> = ({
	children,
	paneColor,
	id,
	style = {},
	shouldAllowOverflow,
	paddingOverwriteInRem,
	hasNoPadding,
	hasNoContainerPadding
}) => {
	const hasPadding = !hasNoPadding
	if (paddingOverwriteInRem) {
		style.paddingBottom = `${paddingOverwriteInRem}rem`
		style.paddingTop = `${paddingOverwriteInRem}rem`
	}
	if (shouldAllowOverflow) {
		style.overflow = "visible"
	}
	return (
		<div
			className={
				cn(s[paneColor ?? EPaneColor.white], s.pane, {
					[s.panePadding]: hasPadding
				})
			}
			id={ id }
			style={ style }
		>
			<Container
				hasNoPadding={ hasNoContainerPadding }
			>
				{children}
			</Container>
		</div>
	)
}