import { ISideMenuProps } from "./interface"
import { SideMenuBox } from "./side-menu-box"
import React, {
	FC, ReactElement, useState
} from "react"
import cn from "classnames"
import s from "./side-menu.module.scss"
export const SideMenu: FC<ISideMenuProps> = ({
	sideMenuBoxProps,
	scrollHeight
}) => {
	const [isVisible, setIsVisible] = useState<boolean>(false)
	const [isExtended, setIsExtended] = useState<boolean>(false)
	const [currentKey, setCurrentKey] = useState<ReactElement | undefined>(undefined)
	const [
		currentComponent,
		setCurrentComponent
	] = useState<ReactElement | undefined>(undefined)
	const setMenuInactive = ():void => {
		setCurrentKey(undefined)
		setCurrentComponent(undefined)
		setIsExtended(false)
	}
	document.addEventListener(`scroll`, () => {
		if (document.documentElement.scrollTop >= scrollHeight) {
			setIsVisible(true)
		}
		else {
			setIsVisible(false)
			setMenuInactive()
		}
	}, {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		passive: true
	})
	return (
		<div
			className={ cn(s.wrapper, {
				[s.visible]: isVisible
			}) }
		>
			<div
				className={ cn(s.blurWrapper, {
					[s.blurred]: isExtended
				}) }
				onClick={ ():void => {
					setMenuInactive()
				} }
			/>
			<div
				className={ s.sideMenuWrapper }
			>
				<div
					className={ s.menuBoxesWrapper }
				>
					{sideMenuBoxProps.map((sideMenuBox) => (
						<div
							key={ Math.random() }
							onClick={ ():void => {
								if (currentKey === sideMenuBox.icon) {
									setMenuInactive()
								}
								else {
									setCurrentKey(sideMenuBox.icon)
									setCurrentComponent(sideMenuBox.component)
									setIsExtended(true)
								}
							} }
							role="button"
						>
							<SideMenuBox
								icon={ sideMenuBox.icon }
								isActive={ currentKey === sideMenuBox.icon || !isExtended }
							/>
						</div>
					))}
				</div>
				{currentKey && (
					<div
						className={ s.menuExtensionWrapper }
					>
						{ currentComponent }
					</div>
				)}
			</div>
		</div>
	)
}