import { CommunicationButton } from "../communication-button"
import { ICommunicationMessageActionsProps } from "./interface"
import AttachmentIcon from "../../../../static/degenia/communication/attachment-icon.svg"
import CloseIcon from "../../../../static/degenia/communication/close-icon.svg"
import DeleteIcon from "../../../../static/degenia/communication/delete-icon.svg"
import React, { FC } from "react"
import cn from "classnames"
import s from "./communication-message-actions.module.scss"
export const CommunicationMessageActions: FC<ICommunicationMessageActionsProps> = ({
	hasAttachment,
	isOpen,
	messageDate,
	setOpenCallback
}) => {
	return (
		<div
			className={ s.communicationMessageActions }
		>
			{hasAttachment && (
				<CommunicationButton
					className={ s.communicationMessageActionsButton }
					type="button"
				>
					<AttachmentIcon/>
				</CommunicationButton>
			)}
			{messageDate && (
				<span
					className={ s.communicationMessageActionsDate }
				>
					{ messageDate }
				</span>
			)}
			<CommunicationButton
				className={ s.communicationMessageActionsButton }
				type="button"
			>
				<DeleteIcon/>
			</CommunicationButton>
			<CommunicationButton
				className={ cn(
					s.communicationMessageActionsButton,
					s.communicationMessageActionsButtonClose,
					{
						[s.communicationMessageActionsButtonCloseVisible]: isOpen
					}
				) }
				onClickCallback={ setOpenCallback }
				type="button"
			>
				<CloseIcon/>
			</CommunicationButton>
		</div>
	)
}