import { Details } from "../details"
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { IContractDetailsProps } from "./interface"
import { prettifyDate } from "../../utils/date"
import { useTranslation } from "react-i18next"
import React, { FC } from "react"
export const ContractDetails: FC<IContractDetailsProps> = ({
	contract
}) => {
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	return (
		<Details
			details={ [
				{
					label: t("contractDetails|contractId"),
					value: contract.number
				},
				{
					label: t("contractDetails|organisation"),
					value: contract.organisation
				},
				{
					label: t("contractDetails|product"),
					value: contract.product
				},
				{
					label: t("contractDetails|rate"),
					value: contract.rate
				},
				{
					label: t("contractDetails|rateText"),
					value: "KFZ SB"
				},
				{
					label: t("contractDetails|start"),
					value: prettifyDate(contract.start)
				},
				{
					label: t("contractDetails|expiration"),
					value: prettifyDate(contract.expiration)
				},
				{
					label: t("contractDetails|duration"),
					value: `${contract.duration} Jahr(e)`
				},
				{
					label: t("contractDetails|contribution"),
					value: `${contract.contribution} €`
				},
				{
					label: t("contractDetails|collection"),
					value: ""
				},
				{
					label: t("contractDetails|status"),
					value: contract.status
				},
				{
					label: t("contractDetails|companyNumber"),
					value: ""
				},
				{
					label: t("contractDetails|claimRatio"),
					value: "% Historie"
				}
			] }
		/>
	)
}