import { DegeniaInputField } from "../degenia-input-field"
import { INestedInputFieldProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./split-input-form-field.module.scss"
export const SplitInputFormField: FC<INestedInputFieldProps> = ({
	props,
	type
}) => {
	const isAddressSplitInput = type === "address"
	return (
		<div
			className={ cn(s.wrapper,
				{
					[s.address]: isAddressSplitInput
				}) }
		>
			{
				props.map(
					(prop) => (
						<div
							key={ prop.title }
						>
							<DegeniaInputField
								{ ...prop }
							/>
						</div>
					)
				)
			}
		</div>
	)
}