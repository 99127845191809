import { IInputTextProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./input-text.module.scss"
export const InputText: FC<IInputTextProps> = ({
	title,
	className
}) => {
	return (
		<input
			className={ cn(s.input, className) }
			placeholder={ title }
			type="text"
		/>
	)
}