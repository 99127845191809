import { AnchorButton } from "@teamparallax/react-components"
import { INavigationContainerProps } from "./interface"
import React, { FC, useState } from "react"
import cn from "classnames"
import s from "./navigation-container.module.scss"
export const NavigationContainer: FC<INavigationContainerProps> = ({
	entries,
	children,
	isSmall
}) => {
	const [activeItem, setActiveItem] = useState<undefined|string>(entries[0]?.id)
	const [isOpen, setOpened] = useState(false)
	return (
		<div className={ cn(s.navigation, {
			[s.small]: isSmall
		}) }
		>
			<button
				className={ cn(s.navigationTitleButton, {
					[s.active]: isOpen
				}) }
				onClick={ (): void => setOpened(!isOpen) }
				type="button"
			>
				Produkte
			</button>
			<div
				className={ cn(s.navigationMenuContainer, {
					[s.active]: isOpen
				}) }
			>
				{ entries.map((entry) => (
					<AnchorButton
						className={ cn(s.navigationItem, {
							[s.active]: activeItem === entry.id
						}) }
						href={ `#${entry.id}` }
						key={ entry.id }
						onClick={ (): void => {
							setActiveItem(entry.id)
						} }
					>
						{isSmall
							? (entry.title as string).split("")[0]
							: entry.title}
					</AnchorButton>
				)) }
			</div>
		</div>
	)
}