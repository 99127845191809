import { Header2 } from "../../components/text/h2"
import { Header3 } from "../../components/text/h3"
import { TTitleWrapperProps } from "./interface"
import { TransformToMarkdown } from "../../components/transform-to-markdown"
import React, { FC, ReactElement } from "react"
import cn from "classnames"
import s from "./title.module.scss"
export const TitleWrapper: FC<TTitleWrapperProps> = ({
	title,
	subTitle,
	isCentered,
	isInverted,
	isSubtitleUppercase,
	isTitleSmall
}) => {
	const transformToMarkdown = (text: string|ReactElement): ReactElement => {
		return <TransformToMarkdown component={ text }/>
	}
	return (
		<div
			className={ cn(s.wrapper) }
		>
			{ title
			&& (
				<div
					className={ cn(
						s.title,
						{
							[s.white]: isInverted,
							[s.center]: isCentered,
							[s.smallTitle]: isTitleSmall
						}
					) }
				>
					<Header2
						shouldInheritColor={ isInverted }
						shouldInheritFontSize={ isTitleSmall }
						text={ transformToMarkdown(title) }
					/>
				</div>
			)
			}
			{ subTitle
			&& (
				<div
					className={ cn(
						s.subtitle,
						{
							[s.darkgrey]: isInverted,
							[s.center]: isCentered
						}
					) }
				>
					<Header3
						shouldInheritColor={ isInverted }
						shouldTransformUppercase={ isSubtitleUppercase }
						text={ transformToMarkdown(subTitle) }
					/>
				</div>
			)
			}
		</div>
	)
}