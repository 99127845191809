import { AxiosError, AxiosResponse } from "axios"
import {
	DegeniaConfigApi,
	DegeniaLegacyApi,
	IdisApi,
	MailApi,
	UserApi
} from "@teamparallax/api-gateway-degenia-rest-api"
import { IApiGatewayDegeniaRestContext } from "./interface"
import { RestClient } from "@teamparallax/common-libraries"
import { useAppConfig } from "@teamparallax/react-components"
import { useTranslation } from "react-i18next"
import React, {
	FC, createContext, useCallback, useEffect, useState
} from "react"
const ApiGatewayRestContext = createContext<IApiGatewayDegeniaRestContext|undefined>(undefined)
interface IApiGatewayProvider {
	apiContext?: IApiGatewayDegeniaRestContext
}
export const ApiGatewayDegeniaRestProvider: FC<IApiGatewayProvider> = ({
	children,
	apiContext
}) => {
	const {
		apiGatewayUrl
	} = useAppConfig()
	const {
		t
	} = useTranslation()
	const {
		axiosInstance
	} = RestClient.instance
	RestClient.instance.resolveMiddleware = useCallback((response: AxiosResponse) => {
		/* Ignored for now */
		// const translation = translateRestResolveMessages(response)
		// if (translation !== false) {
		// 	successToast(translation)
		// }
	}, [])
	RestClient.instance.rejectMiddleware = useCallback((error: AxiosError) => {
		// failureToast(error.message)
	}, [])
	const [context, setContext] = useState<IApiGatewayDegeniaRestContext|undefined>(apiContext)
	useEffect(() => {
		/* eslint-disable-next-line  */
		void (async (): Promise<void> => {
			if (apiContext) {
				setContext(apiContext)
				return
			}
			const apiGatewayUrlWithVersion = `${apiGatewayUrl}/api/v1`
			setContext({
				basePath: apiGatewayUrlWithVersion,
				degeniaConfigApi: new DegeniaConfigApi({}, apiGatewayUrlWithVersion, axiosInstance),
				degeniaLegacyApi: new DegeniaLegacyApi({}, apiGatewayUrlWithVersion, axiosInstance),
				idisApi: new IdisApi({}, apiGatewayUrlWithVersion, axiosInstance),
				mailApi: new MailApi({}, apiGatewayUrlWithVersion, axiosInstance),
				userApi: new UserApi({}, apiGatewayUrlWithVersion, axiosInstance)
			})
		})()
	}, [apiContext, apiGatewayUrl, axiosInstance])
	/* @TODO add loading screen for waiting for context (websocket connection) */
	return (
		<>
			{
				context && (
					<ApiGatewayRestContext.Provider
						value={ context }
					>
						{ children }
					</ApiGatewayRestContext.Provider>
				)
			}
		</>
	)
}
export const useApiGatewayDegeniaRest = ():IApiGatewayDegeniaRestContext => {
	const context = React.useContext(ApiGatewayRestContext)
	if (context === undefined) {
		throw new Error("useApiGateway must be used within a ApiGatewayRestProvider")
	}
	return context
}