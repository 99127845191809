import { DetailsWrapperCard } from "../wrapper-card"
import { IDetailsWrapperCardProps } from "../wrapper-card/interface"
import React, { FC } from "react"
import s from "./more-details.module.scss"
export const MoreDetailsBox: FC<IDetailsWrapperCardProps> = ({
	cardTitle,
	children,
	btnTitle,
	href
}) => {
	return (
		<DetailsWrapperCard
			btnTitle={ btnTitle }
			cardTitle={ cardTitle }
			hasList={ false }
			href={ href }
		>
			<div
				className={ s.description }
			>
				{children}
			</div>
		</DetailsWrapperCard>
	)
}