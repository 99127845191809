import { ECmsAccessors } from "@teamparallax/common-libraries/lib/degenia/cms/enum"
import { useGenericTexts } from "@teamparallax/react-components"
import React, { FC } from "react"
import TelephoneSvg from "../../../../static/degenia/telephone.svg"
import cn from "classnames"
import s from "./number-info.module.scss"
export const NumberInfo:FC = () => {
	const {
		content
	} = useGenericTexts()
	return (
		<div
			className={ cn(s.numberWrap) }
		>
			<div
				className={ cn(s.iconWrap) }
			>
				<TelephoneSvg
					className={ cn(s.icon) }
				>
					0671 8400 30
				</TelephoneSvg>
			</div>
			<div
				className={ cn(s.textWrap) }
			>
				<span
					className={ cn(s.info) }
				>
					{content[ECmsAccessors.callTimes]?.content}
				</span>
				<span
					className={ cn(s.number) }
				>
					0671 8400 30
				</span>
			</div>
		</div>
	)
}