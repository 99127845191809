import { IAwardsProps } from "./interface"
import { ResponsiveCarousel } from "../../components/responsive-carousel"
import AnimalownerSvg from "../../../static/degenia/awards/award-animalowner.svg"
import BuildingsSvg from "../../../static/degenia/awards/award-buildings.svg"
import HouseholdSvg from "../../../static/degenia/awards/award-household.svg"
import PersonalliabilitySvg from "../../../static/degenia/awards/award-personalliability.svg"
import React, { FC } from "react"
import s from "./awards.module.scss"
export const HouseholdAward = HouseholdSvg
export const PersonalLiabilityAward = PersonalliabilitySvg
export const AnimalOwnerAward = AnimalownerSvg
export const BuildingAward = BuildingsSvg
export const AwardsPane: FC<IAwardsProps> = ({
	awardsList,
	title
}) => {
	const breakPointMap = {
		1: 0,
		2: 350,
		3: 570,
		4: 750,
		5: 900
	}
	return (
		<div
			className={ s.awardPane }
		>
			<p
				className={ s.title }
			>
				{ title }
			</p>
			<ResponsiveCarousel
				breakPointMap={ breakPointMap }
				doesNotUseDefaultBreakPoints
			>
				{ awardsList.map((award) => (
					<div
						className={ s.award }
						key={ award.title ?? Math.random() }
					>
						{ award.image }
					</div>
				))}
			</ResponsiveCarousel>
		</div>
	)
}