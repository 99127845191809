import { ECommunicationViewType } from "../enum"
import { ICommunicationView, ICommunicationViewHeaderProps } from "./interface"
import React, { FC } from "react"
import StatusEntranceIcon from "../../../../../static/degenia/communication/status-entrance.svg"
import StatusExitIcon from "../../../../../static/degenia/communication/status-exit.svg"
import WriteMessageIcon from "../../../../../static/degenia/communication/write-message.svg"
import cn from "classnames"
import s from "./communication-view-header.module.scss"
export const CommunicationViewHeader: FC<ICommunicationViewHeaderProps> = ({
	currentView,
	onClick
}) => {
	const communicationViews: ICommunicationView[] = [
		{
			icon: <StatusEntranceIcon/>,
			label: ECommunicationViewType.inboxView
		},
		{
			icon: <StatusExitIcon/>,
			label: ECommunicationViewType.outboxView
		},
		{
			icon: <WriteMessageIcon/>,
			label: ECommunicationViewType.writeMessageView
		}
	]
	return (
		<section
			className={ s.communicationViewHeader }
		>
			{communicationViews.map(({
				icon,
				label
			}) => (
				<button
					className={ cn(
						s.communicationViewHeaderButton,
						{
							[s.communicationViewHeaderButtonCurrent]: currentView === label
						}
					) }
					key={ label }
					onClick={ (): void => onClick(label) }
					type="button"
				>
					{ icon }
					{ label }
				</button>
			))}
		</section>
	)
}