import { IEmployeeProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./employee.module.scss"
export const EmployeeBox: FC<IEmployeeProps> = ({
	email,
	fax,
	imageUrl,
	name,
	phone,
	priority,
	resort,
	role,
	vCardUrl
}) => {
	return (
		<div
			className={ cn(s.employeeBox) }
		>
			<div
				className={ cn(
					s.pictureBox
				) }
			>
				<img
					alt="images"
					className={ cn(s.picture) }
					src={ imageUrl }
				/>
			</div>
			<div
				className={ cn(
					s.contentBox
				) }
			>
				<div
					className={ cn(s.content) }
				>
					<div
						className={ cn(s.employeeName) }
					>
						{ name }<br/>
					</div>
					{ role }<br/>
					{ resort }<br/>
					<div
						className={ cn(s.contentContact) }
					>
						Fon: { phone }<br/>
						Fax: { fax }<br/>
						E-Mail: <a href={ `mailto:${email}` }>{ email }</a>
					</div>
				</div>
			</div>
			<div
				className={ cn(
					s.contactBox
				) }
			>
				<div
					className={ cn(s.contactLink) }
				>
					<a
						href={ vCardUrl }
					>
						vCard herunterladen
					</a>
				</div>
			</div>
		</div>
	)
}