import { ILayoutCardProps } from "./interface"
import { NavigationContainer } from "../navigation-container"
import React, { FC } from "react"
import s from "./layout-card.module.scss"
export const LayoutCard:FC<ILayoutCardProps> = ({
	navigation,
	header,
	children
}) => {
	return (
		<div>
			{ header }
			<section
				className={ s.layoutCard }
			>
				{
					navigation && (
						<div
							className={ s.navigationContainer }
						>
							<NavigationContainer
								{ ...navigation }
							/>
						</div>
					)
				}
				<div
					className={ s.content }
				>
					{children}
				</div>
			</section>
		</div>
	)
}