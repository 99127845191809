import { Button } from "../button"
import { DegeniaInputField } from "../degenia-input-field"
import { DetailsList } from "../../panes/details/details-list"
import { FormProvider, useForm } from "react-hook-form"
import { IDegeniaGenericFormProps } from "./interface"
import React, { FC, ReactNode } from "react"
import s from "./degenia-generic-form.module.scss"
export const DegeniaGenericForm: FC<IDegeniaGenericFormProps> = ({
	resetButtonTitle,
	fields,
	submitButtonTitle,
	shouldHaveMarginIfNoTitleIsPresent,
	submitCallback
}) => {
	const methods = useForm<IDegeniaGenericFormProps>()
	const isAnythingRequired = fields.find((field) => field.isRequired)
	const getFields = (): ReactNode[] => fields.map((field) => {
		const {
			isDetailEditable = true,
			title,
			name,
			...restFields
		} = field
		const editableField = (
			<DegeniaInputField
				{ ...restFields }
				key={ name }
				name={ name }
				shouldHaveMarginIfNoTitleIsPresent={ shouldHaveMarginIfNoTitleIsPresent }
				title={ title }
			/>
		)
		const nonEditableField = (
			<DetailsList
				details={ [{
					isEditable: isDetailEditable,
					label: title as string,
					value: name
				}] }
			/>
		)
		return (
			isDetailEditable
				? editableField
				: nonEditableField
		)
	})
	return (
		<FormProvider
			{ ...methods }
		>
			<form
				className={ s.degeniaGenericForm }
				onSubmit={ methods.handleSubmit(submitCallback) }
			>
				{ getFields() }
				<div
					className={ s.degeniaGenericFormButtonContainer }
				>
					{ submitButtonTitle && (
						<Button
							type="submit"
						>
							{ submitButtonTitle }
						</Button>
					)}
					{ resetButtonTitle && (
						<Button
							className={ s.degeniaGenericFormButtonReset }
							type="reset"
						>
							{ resetButtonTitle }
						</Button>
					)}
					<br/>
				</div>
				{ isAnythingRequired && <small>*Pflichtfelder</small> }
			</form>
		</FormProvider>
	)
}