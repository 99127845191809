import { INewsList } from "./interface"
import { NewsListItem } from "./news-list-item"
import React, { FC } from "react"
import s from "./news-list.module.scss"
export const NewsList: FC<INewsList> = ({
	newsListItemProps
}) => {
	return (
		<>
			{
				newsListItemProps.map((newsListItemProp) => (
					<div
						className={ s.item }
						key={ newsListItemProp.title.toString() }
					>
						<NewsListItem
							link={ newsListItemProp.link }
							publishDate={ newsListItemProp.publishDate }
							teaserText={ newsListItemProp.teaserText }
							title={ newsListItemProp.title }
						/>
					</div>
				))
			}
		</>
	)
}