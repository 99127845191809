import * as React from "react"
import { FC } from "react"
import { Header3 } from "../../../components/text/h3"
import { ILayoutCardHeaderProps } from "./interface"
import s from "./layout-card-header.module.scss"
export const LayoutCardHeader: FC<ILayoutCardHeaderProps> = ({
	description,
	title,
	children
}) => {
	return (
		<div
			className={ s.header }
		>
			{ title && (
				<Header3
					text={ title }
				/>
			)}
			{ description && (
				<p>
					{description}
				</p>
			)}
			{children}
		</div>
	)
}