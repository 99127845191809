import { DashboardLink } from "../../dashboard-link"
import { IDashboardNewsLinkProps } from "./interface"
import React, { FC } from "react"
import s from "./dashboard-news-link.module.scss"
export const DashboardNewsLink: FC<IDashboardNewsLinkProps> = ({
	href,
	children
}) => {
	return (
		<DashboardLink
			className={ s.dashboardNewsLink }
			hasNoIcon
			href={ href }
		>
			{ children }
		</DashboardLink>
	)
}