import { DetailsWrapperCard } from "../wrapper-card"
import { EInputType } from "../../../components/degenia-input-field/enum"
import { IDetailsProps } from "../details-list/interface"
import { SplitInputFormField } from "../../../components/split-input-form-field"
import { TNestedInputFieldProps } from "../../../components/split-input-form-field/type"
import { TPaymentProps } from "../type"
import React, { FC } from "react"
export const PaymentBox: FC<TPaymentProps> = ({
	cardTitle,
	paymentMethod: method,
	linkedAccount,
	btnTitle,
	href
}) => {
	const splitReversedAccountInput = linkedAccount.split(" ").reverse()
	const [bankName, ...accountData] = splitReversedAccountInput
	const bankAccount = accountData.reverse().join(" ")
	const accountProps: TNestedInputFieldProps = [
		{
			defaultValue: bankAccount,
			name: "bankAccount",
			type: EInputType.text
		}, {
			defaultValue: bankName,
			name: "bankName",
			type: EInputType.text
		}
	]
	const cardDetails: IDetailsProps[] = [
		{
			label: "Zahlunsgsart",
			value: method
		},
		{
			customInput: {
				element: SplitInputFormField,
				props: accountProps,
				type: "bankAccount"
			},
			detailsType: EInputType.custom,
			label: "Verbundenes Konto",
			value: `${bankAccount}\n${bankName}`
		}
	]
	return (
		<DetailsWrapperCard
			btnTitle={ btnTitle }
			cardTitle={ cardTitle }
			href={ href }
		>
			{ cardDetails }
		</DetailsWrapperCard>
	)
}