export enum EInputType {
	currency = "currency",
	custom = "custom",
	date = "date",
	file = "file",
	number = "number",
	password = "password",
	radiobutton = "radiobutton",
	select = "select",
	text = "text",
	textarea = "textarea"
}