import { DegeniaGenericForm } from "../degenia-generic-form"
import { EInputType } from "../degenia-input-field/enum"
import { IBankChangeFormData, IBankChangeProps } from "./interface"
import React, { FC } from "react"
export const BankChange: FC<IBankChangeProps> = ({
	onSubmit
}) => (
	<DegeniaGenericForm
		fields={ [
			{
				isRequired: true,
				name: "firstname",
				placeholder: "Vorname",
				title: "Vorname",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "lastname",
				placeholder: "Nachname",
				title: "Nachname",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "contractNumber",
				placeholder: "Vertragsnummer",
				title: "Vertragsnummer",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "currentIban",
				placeholder: "Aktuelle IBAN",
				title: "Aktuelle IBAN",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "currentInstitute",
				placeholder: "Aktuelles Geldinstitut",
				title: "Aktuelles Geldinstitut",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "newIban",
				placeholder: "Neue IBAN",
				title: "Neue IBAN",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "newInstitute",
				placeholder: "Neues Geldinstitut",
				title: "Neues Geldinstitut",
				type: EInputType.text
			},
			{
				name: "newFirstname",
				placeholder: "Vorname",
				title: "Neuer Vorname",
				type: EInputType.text
			},
			{
				name: "newLastname",
				placeholder: "Nachname",
				title: "Neuer Nachname",
				type: EInputType.text
			},
			{
				name: "newAddress",
				placeholder: "Strasse und Hausnummer",
				title: "Strasse und Hausnummer",
				type: EInputType.text
			},
			{
				name: "newZipcode",
				placeholder: "Postleitzahl",
				title: "Postleitzahl",
				type: EInputType.number
			},
			{
				name: "newEmail",
				placeholder: "E-Mail Adresse",
				title: "E-Mail",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "startingFrom",
				placeholder: "startingFrom",
				title: "Startdatum",
				type: EInputType.date
			}
		] }
		submitButtonTitle="Bankdaten ändern"
		submitCallback={ (args) :void => {
			const formData = args as IBankChangeFormData
			onSubmit(formData)
		} }
	/>
)