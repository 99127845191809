import { EIdisBrokerPool } from "@teamparallax/api-gateway-degenia-rest-api"
import DegeniaLogoSvg from "../../../static/degenia/degenia-logo.svg"
import DmuLogoSvg from "../../../static/degenia/dmu-logo.svg"
export { default as TelephoneSvg } from "../../../static/degenia/telephone.svg"
export { default as TelephoneStrokeSvg } from "../../../static/degenia/telephone-stroke.svg"
export { default as AttentionSvg } from "../../../static/degenia/attention.svg"
export { default as HandsSvg } from "../../../static/degenia/hands.svg"
export { default as DownloadSvg } from "../../../static/degenia/download.svg"
export { default as StarSvg } from "../../../static/degenia/star-filled.svg"
export { default as FilesArchiveSvg } from "../../../static/degenia/icons/filesArchive.svg"
export { default as GoBackArrowSvg } from "../../../static/degenia/icons/goBackArrow.svg"
export { default as AllianzSvg } from "../../../static/degenia/cooperation/allianz-logo.svg"
import DegeniaBadgeIcon from "../../../static/degenia/degenia-badge.svg"
import DmuBadgeIcon from "../../../static/degenia/dmu-badge.svg"
import React, { FC } from "react"
export { default as DegeniaBadgeIcon } from "../../../static/degenia/degenia-badge.svg"
export { default as DmuBadgeIcon } from "../../../static/degenia/dmu-badge.svg"
export const DegeniaLogo = DegeniaLogoSvg
export const DmuLogo = DmuLogoSvg
export const BrokerPoolIcon:FC<{icon: EIdisBrokerPool}> = ({
	icon
}) => {
	if (icon === EIdisBrokerPool.Degenia) {
		return <DegeniaBadgeIcon/>
	}
	else if (icon === EIdisBrokerPool.Dmu) {
		return <DmuBadgeIcon/>
	}
	else {
		return (
			<>icon</>
		)
	}
}