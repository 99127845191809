import { Button } from "../../button"
import { DashboardLink } from "../dashboard-link"
import { DashboardSection } from "../dashboard-section"
import { DashboardTitle } from "../dashboard-title"
import { DegeniaInputField } from "../../degenia-input-field"
import { EInputType } from "../../degenia-input-field/enum"
import { FormProvider, useForm } from "react-hook-form"
import { IDashboardCustomersProps } from "./interface"
import { Link } from "@teamparallax/react-components"
import { useRouter } from "next/router"
import DashboardWarningIcon from "../../../../static/degenia/dashboard-warning.svg"
import React, { FC, ReactNode } from "react"
import cn from "classnames"
import s from "./dashboard-customers.module.scss"
interface ISearch {
	contractId: string
	customerId: string
}
export const DashboardCustomers: FC<IDashboardCustomersProps> = ({
	birthdaysList,
	unpolicedList
}) => {
	const router = useRouter()
	const methods = useForm<ISearch>()
	const getUnpolicedItems = (): ReactNode[] => unpolicedList.map(({
		text,
		url
	}) => {
		return (
			<Link
				className={ s.dashboardCustomersUnpolicedItem }
				href={ url }
				key={ text }
			>
				<span>
					<DashboardWarningIcon
						className={ s.icon }
					/>
					{ text }
				</span>
			</Link>
		)
	})
	const submitSearch = async (searchData: ISearch): Promise<void> => {
		await router.push({
			pathname: "/portal/kunden-und-vertraege/suche",
			query: {
				...searchData
			}
		})
	}
	const getBirthdayItems = (): ReactNode[] => birthdaysList.map(({
		text,
		url
	}) => {
		return (
			<Link
				className={ s.dashboardCustomersBirthdayItem }
				href={ url }
				key={ text }
			>
				{ text }
			</Link>
		)
	})
	return (
		<DashboardSection
			className={ cn(s.dashboardCustomers) }
			title="Kunden & Verträge"
		>
			<ul
				className={ s.dashboardCustomersList }
			>
				<li
					className={ s.dashboardCustomersItem }
				>
					<DashboardTitle
						className={ s.dashboardCustomersFormTitle }
					>
						Schnellsuche:
					</DashboardTitle>
					<FormProvider
						{ ...methods }
					>
						<form onSubmit={ methods.handleSubmit(submitSearch) }>
							<DegeniaInputField
								name="customerId"
								placeholder="Kundennummer"
								type={ EInputType.text }
							/>
							<DegeniaInputField
								name="contractId"
								placeholder="Vertragsnummer"
								type={ EInputType.text }
							/>
							<Button
								type="submit"
							>
								Suchen
							</Button>
						</form>
					</FormProvider>
				</li>
				<li
					className={ s.dashboardCustomersItem }
				>
					<DashboardLink
						className={ s.dashboardCustomersLink }
						href="/portal/kunden-und-vertraege/nicht-policierte-antraege"
					>
						<strong>Nichtpoliciert</strong>
					</DashboardLink>
					<section
						className={ s.dashBoardCustomersUnpoliced }
					>
						{ getUnpolicedItems() }
					</section>
				</li>
				<li
					className={ s.dashboardCustomersItem }
				>
					<strong>
						Geburtstage
					</strong>
					<section
						className={ s.dashboardCustomersBirthday }
					>
						{ getBirthdayItems() }
					</section>
				</li>
			</ul>
		</DashboardSection>
	)
}