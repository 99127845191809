import { ContentPane } from "../../../panes/content"
import { EIcon, EIconColor } from "../../../utils/enum"
import { EIconSize } from "../../icon/enum"
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { INewsListItem } from "./interface"
import { Icon } from "../../icon"
import { Link } from "@teamparallax/react-components"
import { TransformToMarkdown } from "../../transform-to-markdown"
import { prettifyDate } from "../../../utils/date"
import { useTranslation } from "react-i18next"
import React, { FC } from "react"
import cn from "classnames"
import s from "./news-list-item.module.scss"
export const NewsListItem: FC<INewsListItem> = ({
	teaserText,
	title,
	publishDate,
	link
}) => {
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	const content = (
		<div>
			<div
				className={ cn(s.publishDate) }
			>
				<Icon
					color={ EIconColor.green }
					hasTransparentBackground
					icon={ EIcon.calendarSimple }
					size={ EIconSize.px30 }
				/>
				{`${t(`published`) }: ${ prettifyDate(publishDate)}` }
			</div>
			<div
				className={ cn(s.teaserText) }
			>
				<TransformToMarkdown
					component={ `${teaserText}...` }
				/>
				<Link href={ link }>
					{t(`continueReading`) }
				</Link>
			</div>
		</div>
	)
	return (
		<div
			className={ cn(s.newsListItemWrapper) }
		>
			<ContentPane
				content={ content }
				subTitle={ title }
			/>
		</div>
	)
}