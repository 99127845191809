import { DegeniaTable } from "../index"
import { Highlighted } from "./highlighted"
import { IServiceTableProps } from "./interface"
import { InfoCell } from "./info-cell"
import { Markdown } from "@teamparallax/react-components"
import { TDegeniaTableCell } from "../type"
import React, { FC } from "react"
export const ServicesTable: FC<IServiceTableProps> = ({
	data,
	id
}) => {
	const body: TDegeniaTableCell[][] = []
	for (const row of data.body) {
		const tableRow: TDegeniaTableCell[] = []
		for (const column of row) {
			let content
			if (column.isHighlighted) {
				content = (
					<Highlighted>
						{column.content}
					</Highlighted>
				)
			}
			else {
				content = column.content
			}
			if (column.info) {
				content = (
					<InfoCell description={ (
						<Markdown
							markdown={ column.info }
						/>
					) }
					>
						{ content }
					</InfoCell>
				)
			}
			tableRow.push(content)
		}
		body.push(tableRow)
	}
	return (
		<DegeniaTable
			data={ {
				body,
				head: data.head
			} }
			id={ id }
			isFirstColumnBold
			isOtherColumnLeft
			minWidthInRem={ 10 }
		/>
	)
}