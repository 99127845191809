import { Fade } from "@teamparallax/react-components"
import { IAdvantagesBoxProps } from "./interface"
import { SlideDown } from "react-slidedown"
import ArrowSvg from "../../../../static/degenia/arrow.svg"
import React, { FC, useState } from "react"
import StarSvg from "../../../../static/degenia/star-filled.svg"
import cn from "classnames"
import s from "./advantages-box.module.scss"
export const AdvantagesBox: FC<IAdvantagesBoxProps> = ({
	title,
	advantagesList,
	isExtended: isExtendedSuper,
	clickHandler: clickHandlerSuper
}) => {
	const [isExtendedLocal, setExtendedLocal] = useState<boolean>(false)
	const isExtended = isExtendedSuper ?? isExtendedLocal
	const clickHandler = (): void => {
		setExtendedLocal(!isExtendedLocal)
		clickHandlerSuper?.()
	}
	return (
		<Fade bottom>
			<div
				className={ cn(s.boxWrapper, {
					[s.active]: isExtended
				}) }
			>
				<div
					className={ s.titleWrapper }
					onClick={ clickHandler }
					role="button"
				>
					<div
						className={ cn(s.star, {
							[s.open]: isExtended
						}) }
					>
						<StarSvg/>
					</div>
					<span
						className={ s.title }
					>
						{title}
					</span>
				</div>
				<SlideDown
					className={ cn(s.reactSlidedown) }
				>
					{isExtended && (
						<ul
							className={ s.advantagesList }
						>
							{advantagesList.map((advantage) => (
								<div
									className={ s.advantage }
									key={ advantage }
								>
									<div
										className={ s.advantagesIcon }
									>
										<ArrowSvg/>
									</div>
									<li
										className={ s.advantagesName }
									>
										{advantage}
									</li>
								</div>
							))}
						</ul>
					)}
				</SlideDown>
			</div>
		</Fade>
	)
}