export enum EIconSize {
	px139 = 139,
	px109 = 109,
	px89 = 89,
	px74 = 74,
	px65 = 65,
	px55 = 55,
	px45 = 45,
	px30 = 30,
	px26 = 26,
	px22 = 22,
	px17 = 17,
	px13 = 13
}