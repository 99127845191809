import { DashboardLinkList } from "../dashboard-link-list"
import { DashboardSection } from "../dashboard-section"
import { IDashboardInventoryTransferProps } from "./interface"
import React, { FC } from "react"
import s from "./dashboard-inventory-transfer.module.scss"
export const DashboardInventoryTransfer: FC<IDashboardInventoryTransferProps> = ({
	links
}) => {
	return (
		<DashboardSection
			className={ s.dashboardInventoryTransfer }
			title="Bestandsübertragung"
		>
			<DashboardLinkList
				links={ links }
			/>
		</DashboardSection>
	)
}