import { EIconColor } from "../../.."
import { EIconSize } from "../../icon/enum"
import { IInfoBoxChildrenProps } from "./interface"
import { Icon } from "../../icon"
import {
	is,
	useAppConfig
} from "@teamparallax/react-components"
import { parseStrapiUrl } from "../../../utils/strapi"
import React, {
	FC,
	ReactElement
} from "react"
import cn from "classnames"
import s from "./info-box-children.module.scss"
export const InfoBoxChildren: FC<IInfoBoxChildrenProps> = ({
	label,
	icon,
	link
}) => {
	const {
		strapiUrl
	} = useAppConfig()
	let iconAsEnum
	let iconAsReactElement
	if (is<ReactElement>(icon, "props")) {
		iconAsReactElement = icon
	}
	else {
		iconAsEnum = icon
	}
	const isDownloadableFileLink = link?.endsWith(".pdf") ?? link?.endsWith(".zip")
	const renderedLink = isDownloadableFileLink
		? (
			<a
				className={ cn(s.label, {
					[s.labelReactElement]: !iconAsEnum
				}) }
				href={ parseStrapiUrl(strapiUrl, link as string) }
				rel="noreferrer"
				target="_blank"
			>
				{ label }
			</a>
		)
		: (
			<a
				className={ cn(s.label, {
					[s.labelReactElement]: !iconAsEnum
				}) }
				href={ link }
			>
				{ label }
			</a>
		)
	return (
		<div
			className={ cn(s.infoBoxChildren) }
		>
			<div
				className={ cn({
					[s.iconReactElement]: !iconAsEnum
				}) }
			>
				{ iconAsEnum
					? (
						<Icon
							backgroundColor={ EIconColor.darkgrey }
							color={ EIconColor.white }
							icon={ iconAsEnum }
							size={ EIconSize.px55 }
						/>
					)
					: (
						<div>
							{ iconAsReactElement }
						</div>
					)
				}
			</div>
			{ renderedLink }
		</div>
	)
}