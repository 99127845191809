import { DetailsWrapperCard } from "../wrapper-card"
import { IDetailsProps } from "../details-list/interface"
import { TBasicDataProps } from "../type"
import { prettifyDate } from "../../../utils/date"
import React, { FC } from "react"
export const BasicDataBox: FC<TBasicDataProps> = ({
	cardTitle,
	contractNumber,
	contractor,
	contribution,
	startDate
}) => {
	const cardDetails: IDetailsProps[] = [
		{
			label: "Vertragsnummer",
			value: contractNumber
		},
		{
			label: "Vertragsnehmer",
			value: contractor
		},
		{
			label: "Beitrag",
			value: contribution
		},
		{
			label: "Vertragsbegin",
			value: prettifyDate(new Date(startDate))
		}
	]
	return (
		<DetailsWrapperCard
			cardTitle={ cardTitle }
		>
			{ cardDetails }
		</DetailsWrapperCard>
	)
}