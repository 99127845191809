import { IBadgeProps } from "./interface"
import { useFormContext } from "react-hook-form"
import DegeniaBadgeIcon from "../../../static/degenia/degenia-badge.svg"
import DmuBadgeIcon from "../../../static/degenia/dmu-badge.svg"
import React, { FC } from "react"
import s from "./badge.module.scss"
export { DegeniaBadgeIcon, DmuBadgeIcon }
export const Badge: FC<IBadgeProps> = ({
	children,
	shouldDefaultChecked,
	name,
	value
}) => {
	const {
		register
	} = useFormContext()
	return (
		<label
			className={ s.badgeLabel }
		>
			<input
				className={ s.badgeInput }
				defaultChecked={ shouldDefaultChecked }
				name={ name }
				ref={ register() }
				type="radio"
				value={ value }
			/>
			<div
				className={ s.badge }
			>
				<span
					className={ s.badgeIndicator }
				/>
				<div
					className={ s.contentWrapper }
				>
					{ children }
				</div>
			</div>
		</label>
	)
}
export const DmuDegeniaBadge:FC = () => {
	return (
		<>
			<DegeniaBadgeIcon/>
			<DmuBadgeIcon/>
		</>
	)
}