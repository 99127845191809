import { AddressChange } from "../address-change"
import { BankChange } from "../bank-change"
import { DefaultSelect } from "@teamparallax/react-components"
import { IMessageAndFileProps } from "./interface"
import { ISelectOptions } from "@teamparallax/react-components/src/components/select/interface"
import { MessageAndFile } from "../message-and-file"
import { TContractContactFormData } from "./type"
import React, {
	FC, ReactElement, useState
} from "react"
import s from "./contract-contact.module.scss"
const selectOptions: ISelectOptions[] = [
	{
		label: "Direktnachricht",
		value: "direct"
	},
	{
		label: "Bankdaten ändern",
		value: "bank"
	},
	{
		label: "Adressdaten ändern",
		value: "address"
	}
]
export const ContractContact: FC<IMessageAndFileProps> = ({
	submitCallback
}) => {
	const [selectedOption, setSelectedOption] = useState<ISelectOptions>(selectOptions[0])
	const submit = (formData: TContractContactFormData): void => {
		submitCallback(formData)
	}
	// eslint-disable-next-line no-undef
	const getForm = (): ReactElement | null => {
		switch (selectedOption?.value) {
			case "address" : {
				return <AddressChange onSubmit={ submit }/>
			}
			case "bank" : {
				return <BankChange onSubmit={ submit }/>
			}
			case "direct": {
				return <MessageAndFile onSubmit={ submit }/>
			}
			default: {
				return null
			}
		}
	}
	return (
		<div
			className={ s.contractContact }
		>
			<DefaultSelect
				classNamePrefix={ s.contractContactSelect }
				options={ selectOptions }
				selectedOption={ selectedOption }
				setSelectedOption={ setSelectedOption }
			/>
			<div
				className={ s.contractContactForm }
			>
				{ getForm() }
			</div>
		</div>
	)
}