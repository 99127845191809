import { ICooperationsPaneProps, ICorporationImage } from "./interface"
import { ResponsiveCarousel } from "../../components/responsive-carousel"
import { TitleWrapper } from "../title"
import { parseStrapiImageUrl } from "../../utils/strapi"
import { useAppConfig } from "@teamparallax/react-components"
import { useBreakPoints } from "@teamparallax/client-libraries/lib/hooks/use-break-points"
import React, { FC } from "react"
import cn from "classnames"
import s from "./cooperations.module.scss"
export const CooperationsPane: FC<ICooperationsPaneProps> = ({
	title,
	corporationList
}) => {
	const setItemPadding = (): number[] => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isOneItem = !useBreakPoints().md
		const desktopPadding = 40
		const mobilePadding = 0
		const itemPaddingHorizontal = isOneItem
			? mobilePadding
			: desktopPadding
		const itemPaddingVertical = 0
		return [itemPaddingVertical, itemPaddingHorizontal]
	}
	const {
		strapiUrl
	} = useAppConfig()
	return (
		<div
			className={ cn(s.cooperationsPane) }
		>
			<div
				className={ cn(s.title) }
			>
				<TitleWrapper
					isInverted
					isTitleSmall
					title={ title }
				/>
			</div>
			<div
				className={ cn(s.imageWrap) }
			>
				<ResponsiveCarousel
					carouselProps={ {
						itemPadding: setItemPadding()
					} }
					shouldAutoPlay
				>
					{
						corporationList
							.sort((
								c1,
								c2
							) => (c1.priority ?? 0) - (c2.priority ?? 0))
							.map((corporation) => {
								const imageUrl = parseStrapiImageUrl(
									strapiUrl,
									corporation.imageUrl
								) ?? ""
								/**
								 * @TODO is currently not supported in nextjs
								 * it just renders empty objects
								 */
								// eslint-disable-next-line no-constant-condition
								if (false && imageUrl.endsWith(".svg")) {
									return (
										<object
											className={ s.image }
											data={ imageUrl }
											key={ imageUrl }
											type="image/svg+xml"
										>
											{ corporation.alt }
										</object>
									)
								}
								return (
									<img
										alt={ corporation.alt }
										className={ s.image }
										key={ imageUrl }
										src={ imageUrl }
									/>
								)
							})
					}
				</ResponsiveCarousel>
			</div>
		</div>
	)
}
export const corporationList: ICorporationImage[] = [
	{
		imageUrl: "/degenia/cooperation/allianz-logo.svg"
	},
	{
		imageUrl: "/degenia/cooperation/alte-leipziger-logo.svg"
	},
	{
		imageUrl: "/degenia/cooperation/barmenia-logo.svg"
	},
	{
		imageUrl: "/degenia/cooperation/chubb-logo.svg"
	},
	{
		imageUrl: "/degenia/cooperation/condor-logo.svg"
	},
	{
		imageUrl: "/degenia/cooperation/dmb-logo.svg"
	},
	{
		imageUrl: "/degenia/cooperation/gothaer-logo.svg"
	},
	{
		imageUrl: "/degenia/cooperation/iptiq-logo.svg"
	},
	{
		imageUrl: "/degenia/cooperation/kravag-logo.svg"
	},
	{
		imageUrl: "/degenia/cooperation/r-und-v-logo.svg"
	},
	{
		imageUrl: "/degenia/cooperation/waldenburger-logo.svg"
	}
]