import { ISpacerProps } from "./interface"
import React, { FC } from "react"
export const Spacer: FC<ISpacerProps> = ({
	style,
	heightInRem
}) => {
	return (
		<div
			style={ {
				...style,
				height: `${heightInRem}rem`
			} }
		/>
	)
}