import { DegeniaButton } from "../../degenia-button"
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import {
	EVariant, InputGroup, Text, useApiGatewayRest
} from "@teamparallax/react-components"
import { FormProvider, useForm } from "react-hook-form"
import { ILoginFormData, IRegisterFormData } from "../interface"
import { ILoginFormProps } from "../login-form/interface"
import { InputField } from "../input-field"
import { useTranslation } from "react-i18next"
import React, {
	FC, ReactElement, useState
} from "react"
import s from "../login.module.scss"
export const RegisterForm: FC<ILoginFormProps> = ({
	onLogin
}) => {
	const {
		t
	} = useTranslation(ENameSpace.common)
	const {
		userApi
	} = useApiGatewayRest()
	const [errorMessage, setErrorMessage] = useState<string|ReactElement|undefined>()
	const methods = useForm<ILoginFormData>()
	return (
		<FormProvider
			{ ...methods }
		>
			<form
				className={ s.form }
			>
				<InputGroup
					classnames={ s.input }
					key="email"
				>
					<InputField
						autocomplete="email"
						hint={ t(`hintEmail`) }
						isRequired
						name="mailAddress"
						placeholder={ t(`email`) }
						type="text"
					/>
				</InputGroup>
				<InputGroup
					classnames={ s.input }
					key="username"
				>
					<InputField
						autocomplete="username"
						hint={ t(`hintUsername`) }
						isRequired
						name="user"
						placeholder={ t(`username`) }
						type="text"
					/>
				</InputGroup>
				<InputGroup
					classnames={ s.input }
					key="password"
				>
					<InputField
						autocomplete="new-password"
						hint={ t(`hintPassword`) }
						isRequired
						name="password"
						placeholder={ t(`password`) }
						type="password"
					/>
				</InputGroup>
				{ errorMessage && (
					<Text
						hasLinefeed
						isBold
						style={ {
							marginTop: "30px",
							textAlign: "center"
						} }
						text={ errorMessage }
						variant={ EVariant.danger }
					/>
				)}
				<div
					className={ s.button }
				>
					<DegeniaButton
						hasBlueIcon
						onClick={ methods.handleSubmit(async (
							registerData: IRegisterFormData
						) => {
							const isErrorless = await methods.trigger()
							if (isErrorless) {
								try {
									await userApi.registerNewUser ({
										mailAddress: registerData.mailAddress,
										name: registerData.user,
										password: registerData.password
									})
									const loginError = await onLogin(registerData)
									setErrorMessage(loginError)
								}
								catch (e) {
									setErrorMessage(e.message)
								}
							}
						}) }
						title={ t(`register`) }
					/>
				</div>
			</form>
		</FormProvider>
	)
}