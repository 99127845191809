import { IIconBoxProps } from "./interface"
import ArrowSvg from "../../../../static/degenia/arrow.svg"
import BurgerMenuSvg from "../../../../static/degenia/burger-menu.svg"
import CrossSvg from "../../../../static/degenia/cross.svg"
import CustomerSvg from "../../../../static/degenia/customer.svg"
import React, { FC } from "react"
import SearchSvg from "../../../../static/degenia/icons/search.svg"
import cn from "classnames"
import s from "./icon-box.module.scss"
export const IconBox: FC<IIconBoxProps> = ({
	onClickForCustomers,
	onClickNavigation,
	onClickLogin,
	onClickSearch,
	onClickClose,
	isActive = false,
	isMobile = false
}) => {
	return (
		<div
			className={ cn(s.iconWrap) }
		>
			{ isActive && (
				<a
					className={ s.closeIcon }
					onClick={ onClickClose }
					role="button"
				>
					<CrossSvg/>
				</a>
			)}
			{ !isActive && (
				<div
					className={ s.icon }
				>
					{ !isMobile && (
						<>
							<a
								className={ cn(s.customerWrap) }
								onClick={ onClickForCustomers }
								role="button"
							>
								<span>
									Für Kunden
								</span>
								<ArrowSvg
									className={ cn(s.arrowIcon) }
								>
									Für Kunden
								</ArrowSvg>
							</a>
							<a
								aria-label="Suche"
								className={ cn(s.searchIcon) }
								onClick={ onClickSearch }
								role="button"
							>
								<SearchSvg>
									Suche
								</SearchSvg>
							</a>
							<a
								aria-label="Login"
								className={ cn(s.customerIcon) }
								onClick={ onClickLogin }
								role="button"
							>
								<CustomerSvg>
									Login
								</CustomerSvg>
							</a>
						</>
					)}
					<a
						aria-label="Menu"
						className={ cn(s.burgerIcon) }
						onClick={ onClickNavigation }
						role="button"
					>
						<BurgerMenuSvg>
							Menu
						</BurgerMenuSvg>
					</a>
				</div>
			)}
		</div>
	)
}