import { DegeniaGenericForm } from "../degenia-generic-form"
import { EInputType } from "../degenia-input-field/enum"
import { ICustomerContractSearchFormProps } from "./interface"
import { useRouter } from "next/router"
import React, {
	FC, useEffect, useState
} from "react"
interface ISearchQuery {
	contractId?: string
	customerId?: string
}
export const CustomerSearchForm: FC<ICustomerContractSearchFormProps> = ({
	resetButtonTitle,
	submitButtonTitle,
	submitCallback
}) => {
	const router = useRouter()
	const [defaultValues, setDefaultValues] = useState<ISearchQuery>({})
	useEffect(() => {
		void (async (): Promise<void> => {
			if (router?.query) {
				const {
					contractId,
					customerId
				} = router.query as ISearchQuery
				if (contractId !== defaultValues.contractId
					|| customerId !== defaultValues.customerId
					|| !defaultValues.customerId && !defaultValues.contractId
						&& (contractId || customerId)
				) {
					setDefaultValues({
						contractId,
						customerId
					})
					await submitCallback({
						contractId,
						customerId
					})
				}
			}
		})()
	}, [defaultValues.contractId, defaultValues.customerId, router?.query, submitCallback])
	return (
		<DegeniaGenericForm
			fields={ [
				{
					name: "surname",
					title: "Name",
					type: EInputType.text
				},
				{
					name: "name",
					title: "Vorname",
					type: EInputType.text
				},
				{
					name: "cityOrZipcode",
					title: "Ort / PLZ",
					type: EInputType.text
				},
				{
					defaultValue: defaultValues.customerId,
					name: "customerId",
					title: "Kundennumer",
					type: EInputType.text
				},
				{
					defaultValue: defaultValues.contractId,
					name: "contractId",
					title: "Vertragsnummer",
					type: EInputType.text
				},
				{
					name: "contractStatus",
					title: "Vertragsstatus",
					type: EInputType.text
				},
				{
					name: "corporation",
					title: "Gesellschaft",
					type: EInputType.text
				},
				{
					name: "kfzCode",
					title: "KfZ-Kennzeichen",
					type: EInputType.text
				},
				{
					name: "mandateId",
					title: "Mandantennummer",
					type: EInputType.text
				},
				{
					name: "dateSince",
					title: "Daten von",
					type: EInputType.date
				}
			] }
			resetButtonTitle={ resetButtonTitle }
			submitButtonTitle={ submitButtonTitle }
			submitCallback={ submitCallback }
		/>
	)
}