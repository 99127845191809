import { DetailsWrapperCard } from "../wrapper-card"
import { IDetailsProps } from "../details-list/interface"
import { TAboutProps } from "../type"
import { prettifyDate } from "../../../utils/date"
import React, { FC } from "react"
export const AboutMeBox: FC<TAboutProps> = ({
	cardTitle,
	policyholder,
	birthday,
	address,
	btnTitle,
	href
}) => {
	const cardDetails: IDetailsProps[] = [
		{
			label: "Versicherungsnehmer",
			value: policyholder
		},
		{
			label: "Geburtstag",
			value: prettifyDate(new Date(birthday))
		},
		{
			label: "Adresse",
			value: address
		}
	]
	return (
		<DetailsWrapperCard
			btnTitle={ btnTitle }
			cardTitle={ cardTitle }
			href={ href }
		>
			{ cardDetails }
		</DetailsWrapperCard>
	)
}