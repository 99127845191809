import { ISeparatorProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./separator.module.scss"
export const Separator: FC<ISeparatorProps> = ({
	className,
	isInverted
}) => {
	return (
		<hr
			className={ cn(s.separator, className, {
				[s.white]: isInverted
			}) }
		/>
	)
}