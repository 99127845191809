import { DynamicLoader, TLazyImport } from "@teamparallax/react-components"
import React, { FC } from "react"
export const NavbarHeader: FC = (props) => {
	return (
		<DynamicLoader
			importPromise={ (async () => import("./navbar-header")) as TLazyImport }
			props={ props }
		/>
	)
}
export default NavbarHeader