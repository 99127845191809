import { ICmsText, getContentOfEntryElement } from "@teamparallax/react-components"
import { ICorporationEntry } from "../../admin-portal/pages/corporation-list/corporation/interface"
import { INavigationEntry } from "../../admin-portal/layout/navigation-container/interface"
import { ReactElement } from "react"
import { isString } from "lodash-es"
export const getEntryContent = (
	entry: string|ReactElement<ICmsText<ICorporationEntry>>
): string => {
	return isString(entry)
		? entry as string
		: getContentOfEntryElement(entry as ReactElement<ICmsText<ICorporationEntry>>)
}
export const createEntry = (
	title: string|ReactElement<ICmsText<ICorporationEntry>>
): INavigationEntry => {
	return {
		id: getEntryContent(title),
		title
	}
}