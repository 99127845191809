import { DashboardSection } from "../dashboard-section"
import { EIcon } from "../../../utils/enum"
import { ICalendarProps } from "./interface"
import { Icon } from "@teamparallax/react-components"
import { getIcon } from "../../../utils"
import { prettifyDate } from "../../../utils/date"
import React, { FC, ReactNode } from "react"
import s from "./dashboard-calendar.module.scss"
export const DashboardCalendar: FC<ICalendarProps> = ({
	events
}) => {
	const getEvents = (): ReactNode[] => events.map(({
		startDate,
		name,
		description
	}) => {
		return (
			<li
				className={ s.dashboardCalendarItem }
				key={ name }
			>
				<p><big>{ name }</big></p>
				<small>{description}</small>
				<span
					className={ s.dashboardCalendarDate }
				>
					<Icon
						classnames={ s.icon }
						icon={ getIcon(EIcon.calendar) }
						size={ 14 }
					/>
					{ prettifyDate(new Date(startDate)) }
				</span>
			</li>
		)
	})
	return (
		<DashboardSection
			className={ s.dashboardCalendar }
			title="Kalender"
			titleUrl="/portal/kalender"
		>
			<ul>
				{getEvents()}
			</ul>
		</DashboardSection>
	)
}