import { IResponsiveCarouselProps } from "./interface"
import Carousel, { ReactElasticCarouselProps } from "react-elastic-carousel"
import React, { FC } from "react"
import cn from "classnames"
import s from "./responsive-carousel.module.scss"
export const ResponsiveCarousel: FC<IResponsiveCarouselProps> = ({
	carouselProps,
	breakPointMap,
	shouldAutoPlay,
	children,
	doesNotUseDefaultBreakPoints,
	hasGreenArrows,
	shouldFocusOnSelect
}) => {
	const defaultBreakPointMap = {
		1: 0,
		2: 495,
		3: 700,
		4: 900
	}
	const baseProperties: Partial<ReactElasticCarouselProps> = {
		autoPlaySpeed: 3000,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		enableAutoPlay: shouldAutoPlay,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		focusOnSelect: shouldFocusOnSelect,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		itemsToShow: 3,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		pagination: false
	}
	if (!doesNotUseDefaultBreakPoints) {
		baseProperties.breakPoints = Object.entries(defaultBreakPointMap)
			.map(([itemsToShow, width]: [string, number]) => ({
				itemsToShow: Number(itemsToShow),
				width: breakPointMap?.[Number(itemsToShow)] ?? width
			}))
	}
	else if (breakPointMap) {
		baseProperties.breakPoints = Object.entries(breakPointMap)
			.map(([itemsToShow, width]: [string, number]) => ({
				itemsToShow: Number(itemsToShow),
				width
			}))
	}
	return (
		<Carousel
			className={ cn(s.carousel, {
				[s.greenArrow]: hasGreenArrows
			}) }
			{ ...baseProperties }
			{ ...carouselProps }
			isRTL={ false }
		>
			{ children }
		</Carousel>
	)
}