import { Scrollbars, positionValues } from "react-custom-scrollbars"
import React, { FC, useState } from "react"
import cn from "classnames"
const twenty = 40
const eightBit = 256
const defaultShadowLength = 40
import { IShadowScrollBarProps } from "./interface"
import s from "./index.module.scss"
export const ShadowScrollBar: FC<IShadowScrollBarProps> = ({
	children,
	height,
	shadowLength = defaultShadowLength
}) => {
	const [shadowTopOpacity, setShadowTopOpacity] = useState<number>(0)
	const [shadowBottomOpacity, setShadowBottomOpacity] = useState<number>(1)
	const handleUpdate = (values: positionValues): void => {
		const {
			scrollTop, scrollHeight, clientHeight
		} = values
		const shadowTopOpacity = 1 / twenty * Math.min(scrollTop, twenty)
		const bottomScrollTop = scrollHeight - clientHeight
		const shadowBottomOpacity = 1 / twenty
			* (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - twenty))
		setShadowTopOpacity(eightBit - shadowTopOpacity * eightBit)
		setShadowBottomOpacity(eightBit - shadowBottomOpacity * eightBit)
	}
	const linearGradient = `linear-gradient(to top, rgba(0,0,0,${shadowBottomOpacity}) 0%, #000F ${shadowLength}px, #000F calc(100% - ${shadowLength}px), rgba(0,0,0,${shadowTopOpacity}) 100%)`
	const style = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		WebkitMask: linearGradient,
		mask: linearGradient
	}
	return (
		<div
			style={ {
				height: `${height}px`,
				position: "relative"
			} }
		>
			<Scrollbars
				className={ cn(s.shadow, s.bottom) }
				onUpdate={ handleUpdate }
				style={ style }
			>
				{children}
			</Scrollbars>
		</div>
	)
}