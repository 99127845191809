import { ISocialMediaProps } from "./interface"
import { Link } from "@teamparallax/react-components"
import { Separator } from "../../../panes/separator"
import FacebookSvg from "../../../../static/degenia/facebook.svg"
import PrintSvg from "../../../../static/degenia/printer.svg"
import React, { FC } from "react"
import ShareSvg from "../../../../static/degenia/share.svg"
import ToTopSvg from "../../../../static/degenia/to-top.svg"
import YouTubeSvg from "../../../../static/degenia/youtube.svg"
import cn from "classnames"
import s from "./socialmedia.module.scss"
export const SocialMedia: FC<ISocialMediaProps> = ({
	facebookLink,
	youtubeLink
}) => {
	return (
		<div
			className={ s.socialmediaWrapper }
		>
			<div
				className={ s.row }
			>
				<div
					className={ s.icon }
				>
					<Link
						aria-label="Facebook"
						href={ facebookLink }
						key={ facebookLink }
					>
						<FacebookSvg/>
					</Link>
				</div>
				<div
					className={ s.icon }
				>
					<a
						aria-label="YouTube"
						href={ youtubeLink }
						key={ youtubeLink }
					>
						<YouTubeSvg/>
					</a>
				</div>
				<a
					aria-label="Teilen"
					className={ s.icon }
					href={ facebookLink }
				>
					<ShareSvg/>
				</a>
			</div>
			<Separator
				className={ cn(s.horizontalRuler, s.mobileHide) }
			/>
			<a
				className={ cn(s.row, s.mobileHide) }
				onClick={ (): void => {
					window?.print()
				} }
				role="button"
			>
				<div
					className={ s.icon }
				>
					<PrintSvg/>
				</div>
				<span>Seite drucken</span>
			</a>
			<Separator
				className={ cn(s.horizontalRuler, s.mobileHide) }
			/>
			<a
				className={ s.row }
				onClick={ ():void => {
					window?.scrollTo(0, 0)
				} }
				role="button"
			>
				<div
					className={ s.icon }
				>
					<ToTopSvg/>
				</div>
				<span
					className={ s.mobileHide }
				>
					Nach Oben
				</span>
			</a>
		</div>
	)
}