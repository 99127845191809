import { isDate } from "lodash-es"
/* before 1800 */
const CMinTime = -5364662400000
export const prettifyDate = (
	dateToTransform?: Date|string,
	options?: Partial<Intl.DateTimeFormatOptions>,
	locale?: string
): string => {
	const defaultDateOptions: Intl.DateTimeFormatOptions = {
		day: "2-digit",
		month: "2-digit",
		year: "numeric"
	}
	try {
		if (dateToTransform) {
			const sanitizedDate: Date = isDate(dateToTransform)
				? dateToTransform as Date
				: new Date(dateToTransform as string)
			const localeDate = sanitizedDate.toLocaleString(
				locale,
				{
					...defaultDateOptions,
					...options
				}
			)
			if (localeDate !== "Invalid Date" && sanitizedDate.getTime() >= CMinTime) {
				return localeDate
			}
		}
	}
	catch (e) {
		console.warn(e)
	}
	return dateToTransform?.toString() ?? ""
}