import { ISideMenuBoxProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./side-menu-box.module.scss"
export const SideMenuBox: FC<ISideMenuBoxProps> = ({
	icon,
	isActive
}) => {
	return (
		<div
			className={ cn(s.iconWrapper, {
				[s.active]: isActive
			}) }
		>
			<div
				className={ s.icon }
			>
				{ icon }
			</div>
		</div>
	)
}