import { IDetailsPaneProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./details.module.scss"
export const DetailsPane: FC<IDetailsPaneProps> = ({
	detailsBoxes: boxes
}) => {
	const maxBoxesCount = 4
	const lastBoxElement = 3
	const hasFourthBox = boxes?.length === maxBoxesCount
	return (
		<>
			<div className={ s.detailWrapper }>
				<div
					className={ cn(s.box, s.a) }
				>
					{boxes[0]}
				</div>
				<div
					className={ cn(s.box, s.b, {
						[s.fullHeight]: !hasFourthBox
					}) }
				>
					{boxes[1]}
				</div>
				<div
					className={ cn(s.box, s.c) }
				>
					{boxes[2]}
				</div>
				{hasFourthBox
					&& (
						<div
							className={ cn(s.box, s.d) }
						>
							{boxes[lastBoxElement]}
						</div>
					)
				}
			</div>
		</>
	)
}