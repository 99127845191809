import { DegeniaButton } from "../degenia-button"
import { DegeniaCheckbox } from "../checkbox"
import { EButtonSize, EIconColor } from "../.."
import { EIconSize } from "../icon/enum"
import { INewsletterProps } from "./interface"
import { Icon } from "../icon"
import { InputText } from "../input-text"
import { TransformToMarkdown } from "../transform-to-markdown"
import React, { FC } from "react"
import cn from "classnames"
import s from "./newsletter.module.scss"
export const Newsletter: FC<INewsletterProps> = ({
	icon,
	onClick,
	buttonText,
	privacyStatement
}) => {
	return (
		<div
			className={ cn(s.wrapper) }
		>
			<div
				className={ cn(s.icon) }
			>
				<Icon
					alt={ buttonText }
					backgroundColor={ EIconColor.darkgrey }
					color={ EIconColor.white }
					icon={ icon }
					size={ EIconSize.px109 }
				/>
			</div>
			<div
				className={ cn(s.contentWrapper) }
			>
				<InputText
					className={ cn(s.email) }
					title="Emailadresse"
				/>
				<div
					className={ cn(s.privacyStatement) }
				>
					<DegeniaCheckbox/>
					<TransformToMarkdown
						component={ privacyStatement }
					/>
				</div>
			</div>
			<div
				className={ cn(s.button) }
			>
				<DegeniaButton
					isInverted
					onClick={ onClick }
					size={ EButtonSize.big }
					title={ buttonText }
				/>
			</div>
		</div>
	)
}