import { IJobPaneProps } from "./interface"
import { JobBox } from "./job-box"
import { TitleWrapper } from "../title"
import React, { FC } from "react"
import cn from "classnames"
import s from "./job.module.scss"
export const JobPane: FC<IJobPaneProps> = ({
	subTitle,
	title,
	jobBoxProps,
	isFlatTitleBox,
	hasCenteredTitle
}) => {
	const boxStyle = jobBoxProps.length > 1
		? cn(s.boxWrap)
		: cn(s.boxWrapSingleElement)
	const hasMultipleElements = jobBoxProps.length > 1
	return (
		<div
			className={ cn(s.jobPane) }
		>
			<div
				className={ cn(s.title, {
					[s.titleSingleElement]: !hasMultipleElements
				}) }
			>
				<TitleWrapper
					isCentered={ hasCenteredTitle }
					subTitle={ subTitle }
					title={ title }
				/>
			</div>
			<div
				className={ boxStyle }
			>
				{ jobBoxProps.map(({
					description,
					button,
					icon,
					title
				}) => (
					<JobBox
						button={ button }
						description={ description }
						icon={ icon }
						isFlatTitleBox={ isFlatTitleBox }
						key={ title.toString() }
						title={ title }
					/>
				)) }
			</div>
		</div>
	)
}