import { IContainerProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./container.module.scss"
export const Container: FC<IContainerProps> = ({
	children,
	hasNoPadding
}) => {
	return (
		<div
			className={ cn(s.container, {
				[s.noPadding]: hasNoPadding
			}) }
		>
			{children}
		</div>
	)
}