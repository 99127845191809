import { DegeniaButton } from "../degenia-button"
import { DegeniaMarkdown } from "../markdown"
import { EButtonSize, Spacer } from "../.."
import { IModalButtonWrapperProps } from "./interface"
import { ModalWrapper } from "@teamparallax/react-components"
import { Reoverlay } from "reoverlay"
import { Separator } from "../../panes/separator"
import React, { FC } from "react"
import s from "./modal-button-wrapper.module.scss"
export const ModalButtonWrapper: FC<IModalButtonWrapperProps> = ({
	button,
	markdownContent,
	modalButtonText
}) => {
	const {
		onClick,
		href,
		...degeniaBeforeModal
	} = button
	return (
		<DegeniaButton
			{ ...degeniaBeforeModal }
			onClick={ (): void => {
				Reoverlay.showModal(ModalContent, {
					button,
					markdownContent,
					modalButtonText
				})
			} }
		/>
	)
}
const ModalContent: FC<IModalButtonWrapperProps> = ({
	markdownContent,
	button,
	modalButtonText
}) => {
	return (
		<ModalWrapper
			contentContainerClassName={ s.contentContainer }
			onClose={ (): void => {
				Reoverlay.hideModal()
			} }
		>
			<DegeniaMarkdown
				markdown={ markdownContent }
			/>
			<Spacer heightInRem={ 1 }/>
			<Separator/>
			<Spacer heightInRem={ 1 }/>
			<DegeniaButton
				{ ...button }
				size={ EButtonSize.small }
				style={ {} }
				title={ modalButtonText ?? button.title }
			/>
		</ModalWrapper>
	)
}