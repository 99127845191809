import { DividedBoxGeneric } from "../generic"
import { IDividedContactProps } from "./interface"
import { Small } from "../../../components/text/small"
import React, { FC } from "react"
import cn from "classnames"
import s from "./index.module.scss"
export const DividedBoxContact: FC<IDividedContactProps> = ({
	contentPrimary,
	contentSecondary,
	contentTitle,
	icon
}) => {
	return (
		<DividedBoxGeneric
			icon={ icon }
		>
			<strong>
				<Small>
					{ contentTitle }
				</Small>
			</strong>
			<div
				className={ cn(s.contentSecondary) }
			>
				{ contentSecondary }
			</div>
			<div
				className={ cn(s.contentPrimary) }
			>
				{ contentPrimary }
			</div>
		</DividedBoxGeneric>
	)
}