import {
	AnchorButton,
	CmsText,
	CmsToggle,
	CmsWrapper,
	ICms,
	IMarkdownPlugins,
	Tooltip, useAuth,
	useCms
} from "@teamparallax/react-components"
import { Button } from "../../../../components/button"
import { DegeniaGenericForm } from "../../../../components/degenia-generic-form"
import { EInputType } from "../../../../components/degenia-input-field/enum"
import { ERoleCapabilities } from "@teamparallax/common-libraries/lib/rbac/enum"
import { Header3 } from "../../../../components/text/h3"
import { ICmsDegeniaMicrositeContact } from "@teamparallax/api-gateway-rest-api"
import {
	IContactsProps, ICorporationEntry, ICorporationProps
} from "./interface"
import { InfoBoxChildren } from "../../../../components/info-box/info-box-children"
import { ListHeader } from "../../../components/list-header"
import { NavigationContainer } from "../../../layout/navigation-container"
import { attachmentShortcode } from "../../../../components/shortcode-links/attachment/shortcode"
import { calculatorLinkShortcode } from "../../../../components/shortcode-links/calculator-link/shortcode"
import { createEntry, getEntryContent } from "../../../../utils/corporation"
import { externalFileShortcode } from "../../../../components/shortcode-links/external-file/shortcode"
import { isNil } from "lodash-es"
import NewsletterSvg from "../../../../../static/degenia/icons/sign.svg"
import React, {
	FC, ReactElement, useEffect, useState
} from "react"
import cn from "classnames"
import s from "./corporation.module.scss"
const CUploadPath = "Microsites"
export const CmsCorporation: FC<ICms<ICorporationEntry>> = ({
	entry,
	onSaveCallback
}) => {
	const {
		getEntryById,
		currentEntryId,
		isMetaVisible
	} = useCms()
	const {
		checkAllowed
	} = useAuth()
	const [icon, setIcon] = useState<ReactElement|string>(entry.imageComponent)
	useEffect(() => {
		const alteredEntry = getEntryById(entry.id) as ICorporationEntry
		setIcon(alteredEntry.imageComponent)
	}, [currentEntryId, entry.id, getEntryById])
	const plugins: IMarkdownPlugins = {
		text: [
			externalFileShortcode,
			calculatorLinkShortcode,
			attachmentShortcode
		]
	}
	const [isAllowedToPublish, setIsAllowedToPublish] = useState(false)
	useEffect(() => {
		void (async (): Promise<void> => {
			const isAllowed = await checkAllowed(ERoleCapabilities.frontEndApproveMicrosite)
			setIsAllowedToPublish(isAllowed)
		})()
	}, [checkAllowed])
	return (
		<CmsWrapper
			editEntryId={ entry.id }
			onSaveCallback={ onSaveCallback }
			rbac={ {
				requiredCapability: ERoleCapabilities.frontEndEditMicrosite,
				requiredValue: entry.itemId
			} }
			shouldAlwaysEnableMeta
			shouldAlwaysShowMenu
		>
			{ isMetaVisible && isAllowedToPublish && (
				<div
					className={ cn(s.corporationDraftToggle) }
				>
					<Tooltip content="Publiziert die Microsite">
						<InfoBoxChildren
							icon={ <NewsletterSvg/> }
							label={ (
								<CmsToggle
									entry={ entry }
									isMeta
									isToggleVisible={ false }
									keyofEntryId="isDraft"
								/>
							) }
						/>
					</Tooltip>
				</div>
			) }
			<Corporation
				contactsOverwrite={ (
					<Contacts
						contactsArray={ entry.contacts }
						entryId={ entry.id }
						keyofEntryId="contacts"
					/>
				) }
				contents={ {
					aboutUs: {
						content: (
							<CmsText
								entry={ entry }
								keyofEntryId="aboutUsContent"
								plugins={ plugins }
								shouldUseMarkdownEditor
								storeUploadFilesPath={ CUploadPath }
							/>),
						title: (
							<CmsText
								entry={ entry }
								keyofEntryId="aboutUsTitle"
								shouldNotRenderMarkdown
							/>)
					},
					applicationsAndForms: {
						content: (
							<CmsText
								entry={ entry }
								keyofEntryId="applicationsAndFormsContent"
								plugins={ plugins }
								shouldUseMarkdownEditor
								storeUploadFilesPath={ CUploadPath }
							/>),
						title: (
							<CmsText
								entry={ entry }
								keyofEntryId="applicationsAndFormsTitle"
								shouldNotRenderMarkdown
							/>)
					},
					contacts: {
						contactsArray: entry.contacts,
						title: (
							<CmsText
								entry={ entry }
								keyofEntryId="contactsTitle"
								shouldNotRenderMarkdown
							/>)
					},
					furtherInformation: {
						content: (
							<CmsText
								entry={ entry }
								keyofEntryId="furtherInformationContent"
								plugins={ plugins }
								shouldUseMarkdownEditor
								storeUploadFilesPath={ CUploadPath }
							/>),
						title: (
							<CmsText
								entry={ entry }
								keyofEntryId="furtherInformationTitle"
								shouldNotRenderMarkdown
							/>)
					},
					portfolioTransfer: {
						content: (
							<CmsText
								entry={ entry }
								keyofEntryId="portfolioTransferContent"
								plugins={ plugins }
								shouldUseMarkdownEditor
								storeUploadFilesPath={ CUploadPath }
							/>),
						title: (
							<CmsText
								entry={ entry }
								keyofEntryId="portfolioTransferTitle"
								shouldNotRenderMarkdown
							/>)
					},
					products: {
						content: (
							<CmsText
								entry={ entry }
								keyofEntryId="productsContent"
								plugins={ plugins }
								shouldUseMarkdownEditor
								storeUploadFilesPath={ CUploadPath }
							/>),
						title: (
							<CmsText
								entry={ entry }
								keyofEntryId="productsTitle"
								shouldNotRenderMarkdown
							/>)
					}
				} }
				corporationHeaderOverwrite={ (
					<ListHeader
						elementKey={ entry.corporationKey }
						entryId={ entry.id }
						imageComponent={ icon }
						keyofEntryId="imageUrl"
						lastEdited={ entry.lastEdited }
						title={ entry.itemName }
					/>
				) }
				corporationKey={ entry.corporationKey }
				imageComponent={ icon }
				itemId={ entry.itemId }
				itemName={ entry.itemName }
				lastEdited={ entry.lastEdited }
				url={ entry.url }
			/>
		</CmsWrapper>
	)
}
export const Corporation:FC<ICorporationProps> = ({
	corporationHeaderOverwrite,
	lastEdited,
	corporationKey,
	itemName: elementName,
	imageComponent: icon,
	contents,
	contactsOverwrite
}) => {
	return (
		<div className={ s.corporationList }>
			{ corporationHeaderOverwrite ?? (
				<ListHeader
					elementKey={ corporationKey }
					imageComponent={ icon }
					lastEdited={ lastEdited }
					title={ elementName }
				/>
			) }
			<div className={ s.corporationListContainer }>
				<NavigationContainer
					entries={ [
						createEntry(contents.aboutUs.title),
						createEntry(contents.products.title),
						createEntry(contents.applicationsAndForms.title),
						createEntry(contents.portfolioTransfer.title),
						createEntry(contents.furtherInformation.title),
						createEntry(contents.contacts.title)
					] }
				/>
				<section className={ s.corporationListContent }>
					<Header3
						id={ getEntryContent(contents.aboutUs.title) }
						shouldInheritColor
						text={ contents.aboutUs.title }
					/>
					<div>{contents.aboutUs.content}</div>
					<Header3
						id={ getEntryContent(contents.products.title) }
						shouldInheritColor
						text={ contents.products.title }
					/>
					<div>{contents.products.content}</div>
					<Header3
						id={ getEntryContent(contents.applicationsAndForms.title) }
						shouldInheritColor
						text={ contents.applicationsAndForms.title }
					/>
					<div>{contents.applicationsAndForms.content}</div>
					<Header3
						id={ getEntryContent(contents.portfolioTransfer.title) }
						shouldInheritColor
						text={ contents.portfolioTransfer.title }
					/>
					<div>{contents.portfolioTransfer.content}</div>
					{/* <section className={ s.corporationDropzone }> */}
					{/*	<span className={ s.corporationDropzoneTitle }> */}
					{/*		Nutzen Sie unser Upload-Formular: */}
					{/*	</span> */}
					{/*	<DropZone/> */}
					{/* </section> */}
					<Header3
						id={ getEntryContent(contents.furtherInformation.title) }
						shouldInheritColor
						text={ contents.furtherInformation.title }
					/>
					<div>{contents.furtherInformation.content}</div>
					{/* <section className={ s.corporationListDropzone }> */}
					{/*	<span className={ s.corporationListDropzoneTitle }> */}
					{/*		Nutzen Sie unser Upload-Formular: */}
					{/*	</span> */}
					{/*	<DropZone/> */}
					{/* </section> */}
					<Header3
						id={ getEntryContent(contents.contacts.title) }
						shouldInheritColor
						text={ contents.contacts.title }
					/>
					{ contactsOverwrite
						? contactsOverwrite
						: (
							<Contacts
								contactsArray={ contents.contacts.contactsArray }
							/>
						)
					}
				</section>
			</div>
		</div>
	)
}
export const Contacts: FC<IContactsProps> = ({
	contactsArray,
	entryId,
	keyofEntryId
}) => {
	const {
		currentEntryId,
		setContent: setContentInProvider
	} = useCms()
	const isWriteMode = entryId && keyofEntryId && currentEntryId === entryId
	const [isAddingContact, setIsAddingContact] = useState<boolean>(false)
	const [contacts, setContacts] = useState<ICmsDegeniaMicrositeContact[]>(contactsArray ?? [])
	return (
		<div>
			{contacts.map((contact) => (
				<div
					className={ s.corporationListContacts }
					key={ contact.name }
				>
					<strong>
						{contact.name} { isWriteMode && (
							<AnchorButton
								className={ cn(s.corporationListContactsRemoveContact) }
								onClick={ (): void => {
									const newContacts = contacts
										.filter((
											existingContact
										): boolean => existingContact.name !== contact.name)
									setContacts(newContacts)
									if (!isNil(entryId) && !isNil(keyofEntryId)) {
										setContentInProvider(
											entryId as string,
											keyofEntryId as string,
											newContacts
										)
									}
								} }
							>
								(Diesen Kontakt entfernen)
							</AnchorButton>
						) }
					</strong>
					<span>
						{contact.role}
					</span>
					<a href={ `tel:${contact.mobile}` }>
						{contact.mobile}
					</a>
					<span>
						{contact.landline}
					</span>
					<a href={ `mailto:${contact.email}` }>
						{contact.email}
					</a>
				</div>
			))
			}
			{ isAddingContact
				? (
					<div>
						<DegeniaGenericForm
							fields={ [{
								isRequired: true,
								name: "name",
								title: "Name",
								type: EInputType.text
							}, {
								isRequired: true,
								name: "role",
								title: "Rolle",
								type: EInputType.text
							}, {
								isRequired: true,
								name: "mobile",
								title: "Mobile",
								type: EInputType.text
							}, {
								isRequired: true,
								name: "landline",
								title: "Festnetz",
								type: EInputType.text
							}, {
								isRequired: true,
								name: "email",
								title: "E-mail",
								type: EInputType.text
							}] }
							submitButtonTitle="Kontakt hinzufügen"
							submitCallback={ (contact: ICmsDegeniaMicrositeContact): void => {
								const newContacts = [
									...contacts,
									contact
								]
								setContacts(newContacts)
								if (!isNil(entryId) && !isNil(keyofEntryId)) {
									setContentInProvider(
										entryId as string,
										keyofEntryId as string,
										newContacts
									)
								}
								setIsAddingContact(false)
							} }
						/>
					</div>
				)
				: isWriteMode && (
					<Button
						onClick={ (): void => {
							setIsAddingContact(true)
						} }
						type="button"
					>
						Neuer Kontakt hinzufügen
					</Button>
				)}
			<br/>
		</div>
	)
}