import { AreaContainer } from "../area-container"
import {
	Header1, Header2, Header3, ListContainer, accordionShortcode, attachmentShortcode
} from "../.."
import { IDegeniaMarkdownProps } from "./interface"
import { Markdown, useAppConfig } from "@teamparallax/react-components"
import { TContent } from "./type"
import { calculatorLinkShortcode } from "../shortcode-links/calculator-link/shortcode"
import { checkShortcode } from "../shortcodes/check"
import { degeniaButtonShortcode } from "../shortcodes/button"
import { externalFileShortcode } from "../shortcode-links/external-file/shortcode"
import { youtubeEmbedShortcode } from "../shortcodes/youtube-embed"
import React, {
	ElementType,
	FC, PropsWithChildren, ReactElement
} from "react"
import slugify from "slugify"
interface IImageProps {
	alt: string
	src: string
}
type THeaderLevel = 1|2|3|4|5|6
interface IHeadingProps {
	children: ReactElement
	level: THeaderLevel
	node: {
		children: {
			type: "text"
			value: string
		}[]
	}
}
export const DegeniaMarkdown:FC<IDegeniaMarkdownProps> = ({
	markdown,
	shouldApplyStrapiUrlToImageRelativePath,
	plugins
}) => {
	const containerPlugins = plugins?.container ?? []
	const textPlugins = plugins?.text ?? []
	const {
		strapiUrl
	} = useAppConfig()
	const imageRenderer = (props: PropsWithChildren<IImageProps>): ReactElement => {
		let src = props.src
		if (!props.src.startsWith("http")) {
			src = `${strapiUrl}${props.src}`
		}
		/* @TODO use NextImage */
		return (
			<img
				alt={ props.alt }
				src={ src }
			/>
		)
	}
	const heading = (props: PropsWithChildren<IHeadingProps>): ReactElement => {
		const id = getIdFromString(props.node.children[0]?.value)
		switch (props.level) {
			case 1:
				return (
					<Header1
						id={ id }
						text={ props.children }
					/>
				)
			case 2:
				return (
					<Header2
						id={ id }
						text={ props.children }
					/>
				)
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			case 3:
				return (
					<Header3
						id={ id }
						text={ props.children }
					/>
				)
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			case 4:
				return (
					<h4
						id={ id }
					>
						{ props.children }
					</h4>
				)
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			case 5:
				return (
					<h5
						id={ id }
					>
						{ props.children }
					</h5>
				)
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			case 6:
			default:
				return (
					<h6
						id={ id }
					>
						{ props.children }
					</h6>
				)
		}
	}
	const additionalRenderers: {[nodeType: string]: ElementType} = {}
	additionalRenderers.heading = heading
	/* @link https://github.com/remarkjs/react-markdown/blob/main/changelog.md#change-renderers-to-components */
	if (shouldApplyStrapiUrlToImageRelativePath) {
		additionalRenderers.image = imageRenderer
	}
	/**
	 * With this we can create an empty line
	 * like we always do it
	 */
	const source = markdown?.replace(/\n\n\n/gi, "\n\n&nbsp;  \n")
	return (
		<ListContainer>
			<AreaContainer>
				<Markdown
					additionalRenderers={ additionalRenderers }
					markdown={ source }
					plugins={ {
						container: [
							accordionShortcode,
							...containerPlugins
						],
						text: [
							degeniaButtonShortcode,
							calculatorLinkShortcode,
							checkShortcode,
							externalFileShortcode,
							attachmentShortcode,
							youtubeEmbedShortcode,
							...textPlugins
						]
					} }
				/>
			</AreaContainer>
		</ListContainer>
	)
}
export const getMarkdownContentFromProps = (content: TContent): string => {
	if (typeof content === "string") {
		return content
	}
	return content.props?.markdown ?? ""
}
export const getIdFromString = (content: string): string|undefined => {
	if (typeof content !== "string") {
		return undefined
	}
	return slugify(content, {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		lower: true
	})
}