import { getIdFromString, getMarkdownContentFromProps } from "../../markdown"
import React, { FC, ReactElement } from "react"
import cn from "classnames"
import s from "./h2.module.scss"
interface IProps {
	id?: string
	shouldInheritColor?: boolean
	shouldInheritFontSize?: boolean
	text: string|ReactElement
}
export const Header2: FC<IProps> = ({
	id,
	text,
	shouldInheritFontSize,
	shouldInheritColor
}) => {
	const parsedId = id ?? getIdFromString(getMarkdownContentFromProps(text))
	return (
		<h2
			className={ cn(s.h2, {
				[s.inheritFontSize]: shouldInheritFontSize,
				[s.inheritColor]: shouldInheritColor
			}) }
			id={ parsedId }
		>
			{text}
		</h2>
	)
}