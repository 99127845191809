import { EDepartment } from "../../components/employee/enum"
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { EmployeeBox } from "../../components/employee/index"
import { IDegeniaDepartmentDropdownProps, IEmployeesProps } from "./interface"
import { IEmployeeProps } from "../../components/employee/interface"
import { SelectInputField } from "../../components/select-input-field"
import { TitleWrapper } from "../title/index"
import { isNil } from "lodash-es"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import React, {
	FC, useRef, useState
} from "react"
import cn from "classnames"
import s from "./employees.module.scss"
const departmentOrder: EDepartment[] = [
	EDepartment.management,
	EDepartment.assistance,
	EDepartment.marketing,
	EDepartment.advisor,
	EDepartment.support,
	EDepartment.productManagement,
	EDepartment.officeManagement,
	EDepartment.backofficeShuKRs,
	EDepartment.backofficeHrWg,
	EDepartment.business,
	EDepartment.insurance,
	EDepartment.accounting,
	EDepartment.it,
	EDepartment.trainee,
	EDepartment.archiving
]
export const EmployeesPane: FC<IEmployeesProps> = ({
	employeeProps,
	onlyShowDepartments,
	hasDropdown
}) => {
	type TDepartmentMap = Record<EDepartment, IEmployeeProps[]>
	const departmentMap: Partial<TDepartmentMap> = {}
	for (const employee of employeeProps
		.sort((
			a,
			b
		) => (a.priority ?? Infinity) - (b.priority ?? Infinity))) {
		departmentMap[employee.department] = departmentMap[employee.department] ?? [];
		(departmentMap[employee.department] as IEmployeeProps[]).push(employee)
	}
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	const selectOptions: IDegeniaDepartmentDropdownProps[] = Object.values(EDepartment)
		.map((label) => (
			{
				label: t(`empolyeeDepartments|${label}`),
				value: label
			}
		))
	const router = useRouter()
	const preSelect: EDepartment = EDepartment[
		router?.query?.department as keyof typeof EDepartment
	]
	const employerRef = useRef<HTMLDivElement>(null)
	const scrollToView = (): void => {
		setTimeout(() => {
			employerRef?.current?.scrollIntoView({
				behavior: "smooth"
			})
		})
	}
	if (preSelect) {
		scrollToView()
	}
	const [
		selectedValue,
		setSelectedValue
	] = useState<EDepartment | undefined>(preSelect ?? undefined)
	const onChange = (option: unknown): void => {
		const {
			value
		} = option as IDegeniaDepartmentDropdownProps
		setSelectedValue(value)
	}
	const employeesToShow = hasDropdown
		? selectedValue
		: onlyShowDepartments
	return (
		<div
			className={ cn(s.employees) }
			ref={ employerRef }
		>
			{
				hasDropdown && (
					<div
						className={ cn(s.dropdown) }
					>
						<TitleWrapper
							subTitle={ t(`chooseDepartment`) }
						/>
						<div
							className={ cn(s.dropdownInput) }
						>
							<SelectInputField
								getOptionValue={ (opt): string => opt.value }
								onChange={ onChange }
								options={ selectOptions }
								placeholder={ preSelect ?? t("showAll") }
							/>
						</div>
					</div>
				)
			}
			{ departmentOrder.map((departmentKey) => {
				const employees = (departmentMap as TDepartmentMap)[departmentKey]
				if (employeesToShow && !employeesToShow.includes(departmentKey)) {
					return null
				}
				if (isNil(employees)) {
					return null
				}
				return (
					<div
						className={ cn(s.department) }
						key={ departmentKey }
					>
						<TitleWrapper
							subTitle={ t(`empolyeeDepartments|${departmentKey}`) }
						/>
						<div
							className={ cn(s.boxWrap) }
						>
							{employees
								.map((employee) => (
									<EmployeeBox
										{ ...employee }
										key={ employee.name }
									/>
								))}
						</div>
					</div>
				)
			}) }
		</div>
	)
}