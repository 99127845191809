import { DashboardLink } from "../dashboard-link"
import { IDashboardLinkListProps } from "./interface"
import { Tooltip } from "@teamparallax/react-components"
import React, { FC } from "react"
import s from "./dashboard-link-list.module.scss"
export const DashboardLinkList: FC<IDashboardLinkListProps> = ({
	hasNoIconLinks,
	links
}) => {
	return (
		<ul
			className={ s.dashboardLinkList }
		>
			{ links.map(({
				imageUrl,
				url,
				title
			}, index) => (
				<li
					className={ s.dashboardLinkListItem }
					key={ `link-list-${index}` }
				>
					<DashboardLink
						hasNoIcon={ hasNoIconLinks }
						href={ url }
					>
						<LinkContentWrapper
							imageUrl={ imageUrl }
							title={ title }
						/>
					</DashboardLink>
				</li>
			)) }
		</ul>
	)
}
interface ILinkContentWrapperProps {
	imageUrl?: string
	title?: string
}
const LinkContentWrapper: FC<ILinkContentWrapperProps> = ({
	title,
	imageUrl
}) => {
	if (imageUrl) {
		const imgComponent = (
			<img
				alt={ title }
				height={ 80 }
				src={ imageUrl }
				width={ 200 }
			/>
		)
		if (title) {
			return (
				<Tooltip content={ title ?? "" }>
					{imgComponent}
				</Tooltip>
			)
		}
		return (
			<>
				{imgComponent}
			</>
		)
	}
	return (
		<span>
			{title}
		</span>
	)
}