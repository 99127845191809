import { getIdFromString, getMarkdownContentFromProps } from "../../markdown"
import React, { FC, ReactElement } from "react"
import s from "./h1.module.scss"
interface IProps {
	id?: string
	shouldInheritColor?: boolean
	shouldTransformUppercase?: boolean
	text: string|ReactElement
}
export const Header1: FC<IProps> = ({
	id,
	text
}) => {
	const parsedId = id ?? getIdFromString(getMarkdownContentFromProps(text))
	return (
		<h1
			className={ s.h1 }
			id={ parsedId }
		>
			{text}
		</h1>
	)
}