import { DetailsWrapperCard } from "../wrapper-card"
import { IDocumentsProps } from "./interface"
import { prettifyDate } from "../../../utils/date"
import IconSVG from "../../../../static/degenia/download.svg"
import React, { FC } from "react"
import s from "./documents.module.scss"
export const DocumentsBox: FC<IDocumentsProps> = ({
	title,
	documentsList
}) => {
	return (
		<DetailsWrapperCard
			cardTitle={ title }
			hasList={ false }
		>
			<ul>
				{
					documentsList?.map((document) => (
						<li
							key={ document.name }
						>
							<a
								className={ s.attachment }
								data-shortcode="attachment"
								href={ document.href }
							>
								<IconSVG
									className={ s.icon }
								/>
								<span
									className={ s.attachmentText }
								>
									{document.name}
								</span>
								<span>
									{ prettifyDate(new Date(document.date)) }
								</span>
							</a>
						</li>
					))
				}
			</ul>
		</DetailsWrapperCard>
	)
}