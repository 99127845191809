import { IAttachmentProps } from "./interface"
import { parseStrapiUrl } from "../../../utils/strapi"
import { useAppConfig } from "@teamparallax/react-components"
import IconSVG from "../../../../static/degenia/download.svg"
import React, { FC } from "react"
import cn from "classnames"
import s from "./attachment.module.scss"
export const Attachment: FC<IAttachmentProps> = ({
	className,
	description,
	title,
	url,
	isUsedInPortal,
	isUsedInCustomerBackend
}) => {
	const {
		strapiUrl
	} = useAppConfig()
	return (
		<a
			className={ cn(
				s.attachment,
				className,
				{
					[s.attachmentAdminPortal]: isUsedInPortal,
					[s.attachmentCustomerBackend]: isUsedInCustomerBackend
				}
			) }
			data-shortcode="attachment"
			href={ parseStrapiUrl(strapiUrl, url) }
			rel="noreferrer"
			target="_blank"
		>
			<IconSVG
				className={ cn(s.icon) }
			/>
			<span
				className={ s.attachmentText }
			>
				{ title }
				{ description && (
					<small>
						({ description })
					</small>
				) }
			</span>
		</a>
	)
}