import { EIconColor } from "../../.."
import { EIconSize } from "../../../components/icon/enum"
import { IDividedGenericProps } from "./interface"
import { Icon } from "../../../components/icon"
import React, { FC } from "react"
import cn from "classnames"
import s from "./index.module.scss"
export const DividedBoxGeneric: FC<IDividedGenericProps> = ({
	children,
	icon
}) => {
	return (
		<div
			className={ cn(s.helpBox) }
		>
			<div
				className={ cn(s.contentBox) }
			>
				<div className={ cn(s.iconBox) }>
					<Icon
						backgroundColor={ EIconColor.darkgrey }
						color={ EIconColor.white }
						icon={ icon }
						size={ EIconSize.px109 }
					/>
				</div>
				<div
					className={ cn(s.content) }
				>
					{ children }
				</div>
			</div>
		</div>
	)
}