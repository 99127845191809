import { ICommunicationPaginationProps } from "./interface"
import React, { FC, ReactNode } from "react"
import s from "./communication-pagination.module.scss"
export const CommunicationPagination: FC<ICommunicationPaginationProps> = ({
	activePageNumber,
	amountOfItems,
	changePageCallback,
	itemsPerPage
}) => {
	const amountOfPages = Math.ceil(amountOfItems / itemsPerPage)
	if (amountOfPages <= 1) {
		return null
	}
	const pages: number[] = Array.from(Array(amountOfPages)).map((i, index) => index + 1)
	const getPages = (): ReactNode[] => pages.map((page) => {
		const pageLink = activePageNumber === page
			? (
				<strong>
					{ page }
				</strong>
			)
			: (
				<a
					className={ s.communicationPaginationLink }
					href="#"
					onClick={ (): void => changePageCallback(page) }
				>
					{ page }
				</a>
			)
		return (
			<li
				className={ s.communicationPaginationItem }
				key={ `page-${page}` }
			>
				{ pageLink }
			</li>
		)
	})
	return (
		<section
			className={ s.communicationPaginationContainer }
		>
			Seite:
			<ul
				className={ s.communicationPagination }
			>
				{ getPages() }
			</ul>
		</section>
	)
}