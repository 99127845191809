import { EIconColor } from "../../../../utils/enum"
import { EIconSize } from "../../../../components/icon/enum"
import { IOverviewBoxProps } from "./interface"
import { Icon } from "../../../../components/icon"
import React, { FC } from "react"
import cn from "classnames"
import s from "./overview-box.module.scss"
export const OverviewBox: FC<IOverviewBoxProps> = ({
	icon,
	title,
	url,
	children
}) => {
	return (
		<div className={ cn(s.overviewBoxWrap) }>
			<a
				className={ cn(s.icon) }
				href={ url }
			>
				<Icon
					alt={ title }
					borderColor={ EIconColor.blue }
					icon={ icon }
					size={ EIconSize.px109 }
				/>
			</a>
			<div
				className={ cn(s.textBox) }
			>
				<a
					className={ cn(s.title) }
					href={ url }
				>
					{title}
				</a>
				<div
					className={ cn(s.text) }
				>
					{ children }
				</div>
			</div>
		</div>
	)
}