import { DegeniaButton } from "../../../components/degenia-button"
import { EButtonSize } from "../../../utils/enum"
import { EIconSize } from "../../../components/icon/enum"
import { IOtherCustomersBoxProps } from "./interface"
import { Icon } from "../../../components/icon"
import { parseStrapiUrl } from "../../../utils/strapi"
import { useAppConfig } from "@teamparallax/react-components"
import { useTranslation } from "react-i18next"
import React, { FC } from "react"
import s from "./othercustomers-box.module.scss"
export const OtherCustomersBox: FC<IOtherCustomersBoxProps> = ({
	icon,
	title,
	subtitle,
	button,
	imageUrl
}) => {
	const {
		t
	} = useTranslation()
	const {
		strapiUrl
	} = useAppConfig()
	return (
		<div
			className={ s.boxWrapper }
			style={ {
				backgroundImage: `linear-gradient(
					131.3deg,
					rgba(199, 213, 48, 0.85),
					rgba(99, 132, 155, 0.15)
				),
				url(${parseStrapiUrl(strapiUrl, imageUrl)})`
			} }
		>
			<Icon
				alt={ title }
				icon={ icon }
				size={ EIconSize.px55 }
			/>
			<p
				className={ s.title }
			>
				{title}
			</p>
			<p
				className={ s.subtitle }
			>
				{subtitle}
			</p>
			<DegeniaButton
				href={ button.href }
				isInverted={ button.isInverted }
				size={ EButtonSize.medium }
				title={ t("degenia~information") }
			/>
		</div>
	)
}