import { TTextShortCodePlugin } from "@teamparallax/react-components"
import CheckSvg from "../../../../static/degenia/check.svg"
import React, { CSSProperties, ReactElement } from "react"
const render: TTextShortCodePlugin<"isCentered"> = (
	attributes: Record<"isCentered", unknown>
): ReactElement => {
	const isCentered = attributes.hasOwnProperty("isCentered")
	const style: CSSProperties = {}
	if (isCentered) {
		style.margin = "0 auto"
	}
	return (
		<CheckSvg
			style={ style }
		/>
	)
}
export const checkShortcode = {
	name: "check",
	render
}