import React, { FC } from "react"
import s from "./container.module.scss"
export const AdminContainer:FC = ({
	children
}) => {
	return (
		<div
			className={ s.adminContainer }
		>
			{ children }
		</div>
	)
}