import { DegeniaGenericForm } from "../degenia-generic-form"
import { IDamageReportFormProps } from "./interface"
import React, { FC } from "react"
export const DamageReportForm: FC<IDamageReportFormProps> = ({
	fields,
	submitCallback
}) => {
	return (
		<DegeniaGenericForm
			fields={ fields }
			submitButtonTitle="Schadenmeldung einreichen"
			submitCallback={ submitCallback }
		/>
	)
}