import { EIconColor } from "../../.."
import { EIconSize } from "../../../components/icon/enum"
import { Fade } from "@teamparallax/react-components"
import { IStatsBoxProps } from "./interface"
import { Icon } from "../../../components/icon"
import React, { FC } from "react"
import cn from "classnames"
import s from "./stats-box.module.scss"
export const StatsBox: FC<IStatsBoxProps> = ({
	icon,
	text,
	value
}) => {
	return (
		<div className={ cn(s.statsBoxWrap) }>
			<Fade bottom>
				<div
					className={ cn(s.icon) }
				>
					<Icon
						alt={ text }
						backgroundColor={ EIconColor.darkgrey }
						color={ EIconColor.white }
						icon={ icon }
						size={ EIconSize.px109 }
					/>
				</div>
				<div
					className={ cn(s.textBox) }
				>
					<strong
						className={ cn(s.text) }
					>
						{text}
					</strong>
					<strong
						className={ cn(s.value) }
					>
						{value}
					</strong>
				</div>
			</Fade>
		</div>
	)
}