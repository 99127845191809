import { TServicesProps } from "../type"
import { TitleWrapper } from "../../title"
import React, { FC } from "react"
import s from "./services.module.scss"
export const ServicesBox: FC<TServicesProps> = ({
	cardTitle,
	sumInsured,
	deductible,
	benefitsTitle,
	benefitsList
}) => {
	return (
		<div
			className={ s.servicesWrapper }
		>
			<TitleWrapper
				isTitleSmall
				title={ cardTitle }
			/>
			<div
				className={ s.services }
			>
				<div
					className={ s.serviceItem }
				>
					<p>Versicherungssumme:</p>
					<p
						className={ s.serviceValue }
					>
						{sumInsured} €
					</p>
				</div>
				<div
					className={ s.serviceItem }
				>
					<p>Selbstbeteiligung:</p>
					<p
						className={ s.serviceValue }
					>
						{deductible} €
					</p>
				</div>
			</div>
			<div className={ s.benefits }>
				<p
					className={ s.benefitsTitle }
				>
					{benefitsTitle}
				</p>
				<ul>
					{
						benefitsList?.map((item) => (
							<li key={ item }>
								{item}
							</li>
						))
					}
				</ul>
			</div>
		</div>
	)
}