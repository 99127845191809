import { CardItemsList } from "../../components/card-items-list"
import { ICardItem } from "../../.."
import { ICardItemsList } from "../../components/card-items-list/interface"
import { INavigationEntry } from "../../layout/navigation-container/interface"
import { NavigationContainer } from "../../layout/navigation-container"
import React, { FC, useMemo } from "react"
import s from "./corporation-list.module.scss"
export const CorporationList: FC<ICardItemsList> = ({
	cardItemProps,
	...props
}) => {
	const getCardItems = useMemo(() => {
		return cartItemListToNavigationEntries(cardItemProps)
	}
	, [cardItemProps])
	return (
		<div
			className={ s.corporationList }
		>
			<div
				className={ s.corporationListHeader }
			>
				<h1 className={ s.corporationListHeaderTitle }>
					Gesellschaften
				</h1>
				<strong className={ s.corporationListHeaderSubTitle }>
					Bitte beachten Sie:
				</strong>
				<p className={ s.corporationListHeaderDescription }>
					Die Inhalte und Pflege der Micro-Site
					(Präsentationsseiten unserer Kooperationspartner) inkl.
					aller Tarife und Tarifrechner unterliegen der rechtlichen
					Verantwortung der teilnehmenden Gesellschaften. degenia/DMU
					übernehmen keinerlei Haftung für falsche bzw. nicht aktualisierte
					Inhalte und Unterlagen.
				</p>
			</div>
			<section className={ s.corporationListContainer }>
				<NavigationContainer
					entries={ getCardItems }
					isSmall
				/>
				<CardItemsList
					{ ...props }
					cardItemProps={ cardItemProps }
				/>
			</section>
		</div>
	)
}
export const cartItemListToNavigationEntries = (cardItemProps: ICardItem[]): INavigationEntry[] => {
	return cardItemProps
		.sort((a, b) => a.itemName.localeCompare(b.itemName))
		.reduce((acc: INavigationEntry[], item) => {
			const temp = acc.filter((el) => {
				const stringTitle = el.title as string
				return stringTitle[0].toLowerCase() === item.itemName[0].toLowerCase()
			})
			if (temp.length) {
				return acc
			}
			const navEntry = {
				/* @TODO use good method to id-ify this */
				id: item.itemId ?? item.itemName,
				title: item.itemName
			}
			return [...acc, navEntry]
		}, [])
}