import { CommunicationMessageForm } from "../../communication-message-form"
import { CommunicationMessageList } from "../../communication-message-list"
import { ECommunicationViewType } from "../enum"
import { ICommunicationViewContainerProps } from "./interface"
import React, { FC, ReactNode } from "react"
export const CommunicationViewContainer: FC<ICommunicationViewContainerProps> = ({
	currentView,
	inboxMessageList,
	outboxMessageList
}) => {
	const getViewModel = (): ReactNode => {
		switch (currentView) {
			case ECommunicationViewType.inboxView:
				return (
					<CommunicationMessageList
						messageList={ inboxMessageList }
					/>
				)
			case ECommunicationViewType.outboxView:
				return (
					<CommunicationMessageList
						messageList={ outboxMessageList }
					/>
				)
			case ECommunicationViewType.writeMessageView:
				return (
					<CommunicationMessageForm/>
				)
			default:
				return (
					<CommunicationMessageForm/>
				)
		}
	}
	return (
		<>
			{ getViewModel() }
		</>
	)
}