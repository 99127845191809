import { ChildrenBox } from "./childrenBox"
import { IChildrenBoxProps } from "./childrenBox/interface"
import { IExamplePaneProps } from "./interface"
import { ResponsiveCarousel } from "../../components/responsive-carousel"
import { TitleWrapper } from "../title"
import React, {
	FC,
	useState
} from "react"
import cn from "classnames"
import s from "./examples.module.scss"
export const ExamplesPane: FC<IExamplePaneProps> = ({
	childrenBoxProps,
	title
}) => {
	const [activeChild, setActiveChild] = useState<IChildrenBoxProps | undefined>(undefined)
	const onStart = (): void => {
		setActiveChild(undefined)
	}
	const breakPointMap = {
		1: 0,
		2: 530,
		3: 800
	}
	return (
		<div
			className={ cn(s.examplesPane) }
		>
			<div
				className={ cn(s.titleWrapper) }
			>
				<TitleWrapper
					isCentered
					isInverted
					isSubtitleUppercase
					isTitleSmall
					title={ title }
				/>
			</div>
			<ResponsiveCarousel
				breakPointMap={ breakPointMap }
				carouselProps={ {
					onNextStart: onStart,
					onPrevStart: onStart
				} }
				doesNotUseDefaultBreakPoints
				shouldFocusOnSelect
			>
				{
					childrenBoxProps.map((childrenBoxProps, index) => {
						return (
							<div
								className={ cn({
									[s.invisible]: activeChild
								}) }
								key={ childrenBoxProps.title }
							>
								<ChildrenBox
									clickHandler={ (): void => {
										if (activeChild?.title === childrenBoxProps.title) {
											onStart()
										}
										else {
											setTimeout(() => {
												setActiveChild(childrenBoxProps)
												// eslint-disable-next-line max-len
												// eslint-disable-next-line @typescript-eslint/no-magic-numbers
											}, 450)
										}
									} }
									description={ childrenBoxProps.description }
									icon={ childrenBoxProps.icon }
									title={ childrenBoxProps.title }
								/>
							</div>
						)
					})
				}
			</ResponsiveCarousel>
			{
				activeChild && (
					<div
						className={ cn(s.childUpperLayer) }
					>
						<ChildrenBox
							clickHandler={ (): void => {
								onStart()
							} }
							description={ activeChild.description }
							icon={ activeChild.icon }
							isExtended
							title={ activeChild.title }
						/>
					</div>
				)
			}
		</div>
	)
}