import React, { FC } from "react"
import s from "./center-child.module.scss"
export const CenterChild:FC = ({
	children
}) => {
	return (
		<div
			className={ s.centerChildWrapper }
		>
			{children}
		</div>
	)
}