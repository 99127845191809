import { DegeniaButton } from "../../../components/degenia-button"
import { DividedBoxGeneric } from "../generic"
import { EButtonSize } from "../../../utils/enum"
import { IDividedBoxSearchProps } from "./interface"
import { InputText } from "../../../components/input-text"
import React, { FC } from "react"
import cn from "classnames"
import s from "./index.module.scss"
export const DividedBoxSearch: FC<IDividedBoxSearchProps> = ({
	buttonText,
	icon
}) => {
	return (
		<DividedBoxGeneric
			icon={ icon }
		>
			<div
				className={ cn(s.searchBox) }
			>
				<InputText
					className={ s.searchInput }
					title="Suchen..."
				/>
				<div
					className={ cn(s.button) }
				>
					<DegeniaButton
						isInverted
						onClick={ (): void => { alert("TODO") } }
						size={ EButtonSize.small }
						title={ buttonText }
					/>
				</div>
			</div>
		</DividedBoxGeneric>
	)
}