/* eslint-disable react/no-array-index-key */
import { DegeniaMarkdown } from "../markdown"
import { IDegeniaTableProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./index.module.scss"
export const DegeniaTable: FC<IDegeniaTableProps> = ({
	data,
	id,
	isFirstColumnBold,
	isOtherColumnLeft,
	minWidthInRem
}) => {
	return (
		<div
			className={ s.tableContainer }
		>
			<table
				className={ cn(s.table) }
				id={ id }
			>
				<thead>
					<tr>
						{ data.head.map((cell, index) => (
							<th
								key={ index }
							>
								{typeof cell === "string"
									? <DegeniaMarkdown markdown={ cell }/>
									: cell }
							</th>
						)) }
					</tr>
				</thead>
				<tbody>
					{ data.body.map((row, index) => (
						<tr
							key={ index }
						>
							{ row.map((cell, cellIndex) => (
								<td
									className={ cn({
										[s.bold]: cellIndex === 0 && isFirstColumnBold,
										[s.left]: cellIndex !== 0 && isOtherColumnLeft
									}) }
									key={ cellIndex }
									style={ {
										"minWidth": `${minWidthInRem ?? 0}rem`
									} }
								>
									{typeof cell === "string"
										? <DegeniaMarkdown markdown={ cell }/>
										: cell }
								</td>
							)) }
						</tr>
					)) }
				</tbody>
			</table>
		</div>
	)
}