import { Button } from "../button"
import { DropZone } from "@teamparallax/react-components"
import { IMessageProps } from "./interface"
import React, { FC, useState } from "react"
import s from "./message-and-file.module.scss"
export const MessageAndFile: FC<IMessageProps> = ({
	onSubmit
}) => {
	const [message, setMessage] = useState<string>("")
	const [files, setFiles] = useState<File[]>([])
	return (
		<>
			<textarea
				className={ s.messageAndFileArea }
				onChange={ (e): void => {
					setMessage(e.target.value)
				} }
				placeholder="Kurzmitteilung"
				value={ message }
			/>
			<DropZone onChange={ setFiles }/>
			<Button
				className={ s.messageAndFileButton }
				onClick={ (): void => {
					onSubmit({
						files,
						message
					})
				} }
				type="submit"
			>
				Mitteilung senden
			</Button>
		</>
	)
}