import {
	EIcon, EIconColor, EIconSize, Icon
} from "../../.."
import { FC } from "react"
import { IResult, IResultList } from "./interface"
import React from "react"
import cn from "classnames"
import s from "./result-list.module.scss"
export const ResultList: FC<IResultList> = ({
	resultProps
}) => {
	return (
		<>
			{
				resultProps.map((resultProp: IResult) => (
					<a
						className={ cn(s.wrapper) }
						href={ resultProp.url }
						key={ resultProp.title }
					>
						<div
							className={ cn(s.title) }
						>
							<div
								className={ cn(s.icon) }
							>
								<Icon
									color={ EIconColor.white }
									hasTransparentBackground
									icon={ EIcon.arrow }
									size={ EIconSize.px30 }
								/>
							</div>
							{resultProp.title}
						</div>
						<div
							className={ cn(s.subTitle) }
						>
							{resultProp.subTitle}
						</div>
					</a>
				))
			}
		</>
	)
}