import { DetailsWrapperCard } from "../wrapper-card"
import { EInputType } from "../../../components/degenia-input-field/enum"
import { IDetailsProps } from "../details-list/interface"
import { SplitInputFormField } from "../../../components/split-input-form-field"
import { TNestedInputFieldProps } from "../../../components/split-input-form-field/type"
import { TPersonalDataProps } from "../type"
import { prettifyDate } from "../../../utils/date"
import React, { FC } from "react"
export const PersonalDataBox: FC<TPersonalDataProps> = ({
	cardTitle,
	btnTitle,
	href,
	address,
	birthday,
	policyholder
}) => {
	const [zipCode, city, ...streetAndNumberData] = address.split(" ")
	const streetAndNumber = streetAndNumberData.join(" ")
	const adressFieldProps: TNestedInputFieldProps = [
		{
			defaultValue: zipCode,
			name: "zipCode",
			type: EInputType.text
		}, {
			defaultValue: city,
			name: "city",
			type: EInputType.text
		},
		{
			defaultValue: streetAndNumber,
			name: "street",
			type: EInputType.text
		}
	]
	const cardDetails: IDetailsProps[] = [
		{
			label: "Versicherungsnehmer",
			value: policyholder
		},
		{
			isEditable: false,
			label: "Geburtstag",
			value: prettifyDate(new Date(birthday))
		},
		{
			customInput: {
				element: SplitInputFormField,
				props: adressFieldProps,
				type: "address"
			},
			detailsType: EInputType.custom,
			label: "Adresse",
			value: `${zipCode} ${city}\n${streetAndNumber}`
		}
	]
	return (
		<DetailsWrapperCard
			btnTitle={ btnTitle }
			cardTitle={ cardTitle }
			href={ href }
		>
			{ cardDetails }
		</DetailsWrapperCard>
	)
}