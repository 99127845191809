import React, { FC } from "react"
import s from "./overlay.module.scss"
export const Overlay:FC = ({
	children
}) => {
	return (
		<div
			className={ s.overlay }
		>
			{children}
		</div>
	)
}