import { EIconColor } from "../../../utils/enum"
import { EIconSize } from "../../../components/icon/enum"
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { IChildrenBoxProps } from "./interface"
import { Icon } from "../../../components/icon"
import { TitleWrapper } from "../../title"
import { useBreakPoints } from "@teamparallax/client-libraries/lib/hooks/use-break-points"
import { useTranslation } from "react-i18next"
import React, { FC, ReactElement } from "react"
import cn from "classnames"
import s from "./children-box.module.scss"
export const ChildrenBox: FC<IChildrenBoxProps> = ({
	title,
	icon,
	description,
	isExtended = false,
	clickHandler: clickHandlerSuper
}) => {
	const clickHandler = (): void => {
		clickHandlerSuper?.()
	}
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	const isMobile = !useBreakPoints().md
	const getDescriptionElement = (): ReactElement => {
		return (
			<div
				className={ cn({
					[s.description]: isMobile,
					[s.secondaryCardWrapper]: !isMobile
				}) }
			>
				{description}
			</div>
		)
	}
	return (
		<div
			className={ cn(s.childrenBox, {
				[s.mobile]: isMobile
			}) }
		>
			<div
				className={ cn(s.primaryCardWrapper, {
					[s.mobileExtended]: isExtended && isMobile
				}) }
				onClick={ clickHandler }
			>
				<div
					className={ cn(s.iconWrapper) }
				>
					<Icon
						alt="icon"
						borderColor={ EIconColor.lightgrey }
						icon={ icon }
						size={ EIconSize.px109 }
					/>
				</div>
				<div
					className={ cn(s.contentWrapper) }
				>
					<div
						className={ cn(s.title) }
					>
						<TitleWrapper
							isCentered
							subTitle={ title }
						/>
					</div>
					<div
						className={ cn(s.showMore) }
					>
						{t(`showMore${Number(isExtended)}`)}
					</div>
					{
						isExtended && isMobile && getDescriptionElement()
					}
				</div>
			</div>
			{
				isExtended && !isMobile && getDescriptionElement()
			}
		</div>
	)
}