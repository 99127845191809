import { AnchorButton } from "@teamparallax/react-components"
import { IFooterListProps } from "./interface"
import { Link } from "@teamparallax/react-components"
import { Separator } from "../../../panes/separator"
import React, { FC } from "react"
import cn from "classnames"
import s from "./footer-list.module.scss"
export const FooterList: FC<IFooterListProps> = ({
	title,
	linkProps,
	isExtended,
	accordionHandler
}) => {
	return (
		<div
			className={ s.listWrapper }
		>
			<Separator
				className={ s.horizontalRuler }
			/>
			<p
				className={ s.title }
				onClick={ ():void => {
					accordionHandler?.(title)
				} }
			>
				{ title }
			</p>
			<ul
				className={ cn (s.linkList, {
					[s.mobileHide]: !isExtended
				}) }
			>
				{linkProps.map((linkElement) => (
					<li
						className={ s.link }
						key={ linkElement.name }
					>
						<AnchorButton>
							<Link
								href={ linkElement.linkUrl }
							>
								{linkElement.name}
							</Link>
						</AnchorButton>
					</li>
				)) }
			</ul>
		</div>
	)
}