import { TTableButton } from "./type"
import React, { AnchorHTMLAttributes, FC } from "react"
import s from "./table-button.module.scss"
export const TableButton: FC<TTableButton> = ({
	title,
	url,
	shouldOpenInNewTab,
	onClick
}) => {
	if (url) {
		const aProps: AnchorHTMLAttributes<HTMLAnchorElement> = {}
		if (shouldOpenInNewTab) {
			aProps.rel = "noreferrer"
			aProps.target = "_blank"
		}
		return (
			<a
				className={ s.button }
				href={ url }
				type="button"
				{ ...aProps }
			>
				{title}
			</a>
		)
	}
	return (
		<button
			className={ s.button }
			onClick={ onClick }
			type="button"
		>
			{title}
		</button>
	)
}