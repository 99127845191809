import {
	EIcon, EIconColor, Icon
} from "../../../.."
import { ICardItem } from "./interface"
import { Link } from "@teamparallax/react-components"
import React, {
	FC, ReactElement, ReactNode
} from "react"
import cn from "classnames"
import s from "./card-item.module.scss"
export const determineImageOrIcon = (imageOrIcon: ReactElement | string): ReactNode => {
	if (typeof imageOrIcon === "string") {
		if (EIcon[imageOrIcon as EIcon]) {
			return (
				<Icon
					backgroundColor={ EIconColor.darkgrey }
					color={ EIconColor.white }
					icon={ imageOrIcon as EIcon }
				/>
			)
		}
		return <img src={ imageOrIcon }/>
	}
	else {
		return imageOrIcon
	}
}
export const CardItem: FC<ICardItem> = ({
	hasBorder,
	imageComponent,
	shouldOpenInNewTab,
	itemId,
	isNew,
	isPrivate,
	itemName,
	url
}) => {
	return (
		<div>
			<Link
				href={ `${url}` }
				shouldOpenInNewTab={ shouldOpenInNewTab }
			>
				<div
					className={ cn(s.cardItem, {
						[s.cardItemWithBorder]: hasBorder
					}) }
					id={ itemId }
				>
					{isNew && hasBorder && (
						<span
							className={ s.label }
						>
							Neu
						</span>
					) }
					{isPrivate && hasBorder && (
						<span
							className={ s.label }
						>
							Unveröffentlicht
						</span>
					) }
					<div
						className={ s.image }
					>
						{determineImageOrIcon(imageComponent)}
						{isNew && !hasBorder && (
							<span
								className={ s.label }
							>
								Neu
							</span>
						) }
					</div>
					<div
						className={ s.cardItemTitle }
					>
						{itemName}
					</div>
				</div>
			</Link>
		</div>
	)
}