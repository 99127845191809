import { DashboardLinkList } from "../dashboard-link-list"
import { DashboardSection } from "../dashboard-section"
import { IDashboardCalculatorProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./dashboard-calculator.module.scss"
export const DashboardCalculator: FC<IDashboardCalculatorProps> = ({
	links
}) => {
	return (
		<DashboardSection
			className={ cn(s.dashboardCalculator) }
			title="Rechner"
		>
			<DashboardLinkList
				links={ links }
			/>
		</DashboardSection>
	)
}