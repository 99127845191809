import { Contact } from "./contact"
import { EFooterCategory } from "./enum"
import { FooterList } from "./footer-list"
import { IFooterProps } from "./interface"
import { Link } from "@teamparallax/react-components"
import { SocialMedia } from "./socialmedia"
import React, { FC, useState } from "react"
import s from "./footer.module.scss"
export const Footer: FC<IFooterProps> = ({
	hasDmu,
	footerListProps,
	contactProps,
	logoMap,
	socialMediaProps
}) => {
	const [extendedItem, setExtendedItem] = useState<string>(EFooterCategory.contact)
	const accordionHandler = (value:string): void => {
		if (extendedItem === value) {
			setExtendedItem("")
		}
		else {
			setExtendedItem(value)
		}
	}
	return (
		<div
			className={ s.footer }
		>
			<div
				className={ s.logoWrapper }
			>
				{ Object.entries(logoMap).map(([key, value]) => {
					if (!hasDmu && key === "dmu") {
						return null
					}
					else {
						return (
							<Link
								href="/"
								key={ key }
							>
								<div
									className={ s.logo }
									key={ key }
								>
									{ value }
								</div>
							</Link>
						)
					}
				})}
			</div>
			<div
				className={ s.linkWrapper }
			>
				<div
					className={ s.contact }
				>
					<Contact
						accordionHandler={ accordionHandler }
						address={ contactProps.address }
						companyList={ contactProps.companyList }
						fax={ contactProps.fax }
						fon={ contactProps.fon }
						hasDmu={ hasDmu }
						isExtended={ extendedItem === EFooterCategory.contact }
					/>
				</div>
				{ footerListProps.map((linkList) => {
					if (!hasDmu && linkList.title === EFooterCategory.partners) {
						return null
					}
					else {
						return (
							<div
								className={ s.list }
								key={ linkList.title }
							>
								<FooterList
									accordionHandler={ accordionHandler }
									isExtended={ extendedItem === linkList.title }
									linkProps={ linkList.linkProps }
									title={ linkList.title }
								/>
							</div>
						)
					}
				}) }
				<SocialMedia
					facebookLink={ socialMediaProps.facebookLink }
					youtubeLink={ socialMediaProps.youtubeLink }
				/>
			</div>
		</div>
	)
}