import { ContractBox } from "./contract-box"
import { IContractPaneProps } from "./interface"
import { Separator } from "../../../panes/separator"
import React, { FC } from "react"
export const ContractPane:FC<IContractPaneProps> = ({
	contractBoxProps
}) => {
	return (
		<div>
			{ contractBoxProps.length
				? contractBoxProps.map((contractBoxProps, index, arr) => (
					<div
						key={ `container-${contractBoxProps.title}` }
					>
						<ContractBox
							description={ contractBoxProps.description }
							icon={ contractBoxProps.icon }
							key={ contractBoxProps.title }
							link={ contractBoxProps.link }
							price={ contractBoxProps.price }
							title={ contractBoxProps.title }
						/>
						{
							index !== arr.length - 1
								? (
									<Separator
										isInverted
									/>
								)
								: null
						}
					</div>
				))
				: <h3>Keine Verträge</h3>
			}
		</div>
	)
}