import { INavigationExtendedProps } from "./interface"
import { LinkList } from "./link-list"
import { Navigation } from "../navigation"
import { Overlay } from "../overlay"
import { useBreakPoints } from "@teamparallax/client-libraries/lib/hooks/use-break-points"
import React, { FC, useState } from "react"
import cn from "classnames"
import s from "./navigation-extended.module.scss"
export const NavigationExtended: FC<INavigationExtendedProps> = ({
	linkListProps,
	navigationClickHandler,
	hasDmu,
	extraLinkProps
}) => {
	const isMobile = !useBreakPoints().lg
	const [currentKey, setCurrentKey] = useState<string>("")
	return (
		<Overlay>
			<div
				className={ cn(s.wrapper, {
					[s.lightBackground]: currentKey
				}) }
			>
				<div
					className={ s.navigation }
				>
					<Navigation
						hasDmu={ hasDmu }
						isActive
						navigationClickHandler={ navigationClickHandler }
						shouldStickyBeHighlighted={ false }
					/>
				</div>
				<div
					className={ s.linkList }
				>
					{ linkListProps.map((linkList) => (
						<div
							className={ cn(s.list, {
								[s.extended]: currentKey === linkList.title
							}) }
							key={ linkList.title }
						>
							<LinkList
								clickHandler={ (): void => {
									if (isMobile) {
										if (currentKey === linkList.title) {
											setCurrentKey("")
										}
										else {
											setCurrentKey(linkList.title)
										}
									}
								} }
								isExtended={ currentKey === linkList.title }
								isMobile={ isMobile }
								linkProps={ linkList.linkProps }
								title={ linkList.title }
							/>
						</div>
					)) }
					{ isMobile && (
						<div>
							{ extraLinkProps.map(({
								extendedNavigation, name
							}) => {
								return (
									<div
										className={ s.link }
										key={ name }
										onClick={
											navigationClickHandler[extendedNavigation]
										}
									>
										<p
											className={ s.title }
										>
											{ name }
										</p>
										<hr
											className={ s.line }
										/>
									</div>
								)
							})}
						</div>
					)}
				</div>
			</div>
		</Overlay>
	)
}