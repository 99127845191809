import { EFooterCategory } from "../enum"
import { IContactProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./contact.module.scss"
export const Contact: FC<IContactProps> = ({
	address,
	companyList,
	fax,
	fon,
	hasDmu,
	isExtended,
	accordionHandler
}) => {
	return (
		<div
			className={ s.contactWrapper }
		>
			<div
				className={ s.title }
				onClick={ ():void => {
					accordionHandler?.(EFooterCategory.contact)
				} }
			>
				{ EFooterCategory.contact }
			</div>
			<div
				className={ cn ({
					[s.mobileHide]: !isExtended
				}) }
			>
				<div
					className={ s.companyWrapper }
				>
					{ companyList.map((company) => {
						if (!hasDmu && company.title === "DMU") {
							return null
						}
						else {
							return (
								<p
									className={ s.p }
									key={ company.title }
								>
									<span className={ s.span }>{ company.title }
									</span>
									{ company.subtitle }
								</p>
							)
						}
					}) }
				</div>
				<p className={ s.address }>
					<span className={ s.span }>
						{ address.street }
					</span>
					<span className={ s.span }>
						{ address.city }
					</span>
				</p>
				<p>
					<span className={ s.span }>
						Fon: { fon }
					</span>
					<span className={ s.span }>
						Fax: { fax }
					</span>
					{ companyList.map((company) => {
						if (!hasDmu && company.title === "DMU") {
							return null
						}
						else {
							return (
								<span
									className={ s.span }
									key={ company.email }
								>
									Email: { company.email }
								</span>
							)
						}
					}) }
				</p>
			</div>
		</div>
	)
}