import { CommunicationMessage } from "../communication-message"
import { CommunicationPagination } from "../communication-pagination"
import { ICommunicationMessageListProps } from "./interface"
import React, { FC, useState } from "react"
import s from "./communication-message-list.module.scss"
export const CommunicationMessageList: FC<ICommunicationMessageListProps> = ({
	messageList
}) => {
	const [currentPage, setCurrentPage] = useState(1)
	return (
		<section
			className={ s.communicationMessageList }
		>
			{messageList.map(({
				attachmentUrl,
				messageId,
				messageDate,
				messageSubtitle,
				messageTheme,
				messageTitle,
				messageText,
				shouldRenderOpened
			}) => (
				<CommunicationMessage
					attachmentUrl={ attachmentUrl }
					key={ messageId }
					messageDate={ messageDate }
					messageSubtitle={ messageSubtitle }
					messageText={ messageText }
					messageTheme={ messageTheme }
					messageTitle={ messageTitle }
					shouldRenderOpened={ shouldRenderOpened }
				/>
			))}
			<CommunicationPagination
				activePageNumber={ currentPage }
				amountOfItems={ messageList.length }
				changePageCallback={ setCurrentPage }
				itemsPerPage={ 1 }
			/>
		</section>
	)
}