import { iframeCssUrl } from "../../components/mr-money-iframe"
import { render } from "mustache"
const getCurrentHost = (): string => window.location.host
const getCurrentProtocol = (): string => window.location.protocol
/* @TODO currently does not work because has wrong mime-type "text/html" */
const absoluteIframeCssPath = "/static/iframe-style.css"
const getFqdnIframeCssPath = (): string => `${getCurrentProtocol()}//${getCurrentHost()}${absoluteIframeCssPath}`
export const mrMoneyUrlSanitizer = (mrMoneyUrl: string): string => {
	return render(mrMoneyUrl, {
		// "css-path": getFqdnIframeCssPath()
		"css-path": iframeCssUrl
	})
}