import { DetailsWrapperCard } from "../wrapper-card"
import { IDetailsProps } from "../details-list/interface"
import { TCredentialsProps } from "../type"
import React, { FC } from "react"
export const CredentialsBox: FC<TCredentialsProps> = ({
	cardTitle: title,
	btnTitle,
	href,
	accessNumber,
	email,
	password
}) => {
	const cardDetails: IDetailsProps[] = [
		{
			label: "Zugansnummer",
			value: accessNumber
		},
		{
			label: "Email",
			value: email
		},
		{
			label: "Passwort",
			value: password
		}
	]
	return (
		<DetailsWrapperCard
			btnTitle={ btnTitle }
			cardTitle={ title }
			href={ href }
		>
			{ cardDetails }
		</DetailsWrapperCard>
	)
}