import { DashboardTitle } from "../dashboard-title"
import { IDashboardSectionProps } from "./interface"
import React, { FC, ReactNode } from "react"
import cn from "classnames"
import s from "./dashboard-section.module.scss"
export const DashboardSection: FC<IDashboardSectionProps> = ({
	children,
	className,
	title,
	titleUrl,
	shouldRenderChildrenInline
}) => {
	const getChildrenContent = (): ReactNode => {
		const row = (
			<section
				className={ s.dashboardSectionRow }
			>
				{ children }
			</section>
		)
		return shouldRenderChildrenInline
			? row
			: children
	}
	return (
		<section
			className={ cn(s.dashboardSection, className) }
		>
			<DashboardTitle
				titleUrl={ titleUrl }
			>
				{ title }
			</DashboardTitle>
			{ getChildrenContent() }
		</section>
	)
}