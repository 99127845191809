import { CalculatorLink } from "./index"
import { ITextRenderShortCodesChild, TTextShortCodePlugin } from "@teamparallax/react-components"
import React, { ReactElement } from "react"
const render: TTextShortCodePlugin<"title"|"url"|"description"> = (
	attributes: Record<"title"|"url"|"description", string>,
	children: ITextRenderShortCodesChild[]
): ReactElement => {
	return (
		<CalculatorLink
			description={ attributes.description }
			title={ attributes.title }
			url={ attributes.url }
		/>
	)
}
export const calculatorLinkShortcode = {
	name: "calculator-link",
	render
}