import { DetailsWrapperCard } from "../wrapper-card"
import { IDetailsProps } from "../details-list/interface"
import { TContactProps } from "../type"
import React, { FC } from "react"
export const ContactBox: FC<TContactProps> = ({
	cardTitle,
	btnTitle,
	href,
	email,
	mobile
}) => {
	const cardDetails: IDetailsProps[] = [
		{
			label: "Email",
			value: email
		},
		{
			label: "Telefonnummer",
			value: mobile
		}
	]
	return (
		<DetailsWrapperCard
			btnTitle={ btnTitle }
			cardTitle={ cardTitle }
			href={ href }
		>
			{ cardDetails }
		</DetailsWrapperCard>
	)
}