import {
	CmsText, CmsWrapper, Fade, ICms, useCms
} from "@teamparallax/react-components"
import { DegeniaButton } from "../../components/degenia-button"
import { DegeniaMarkdown } from "../../components/markdown"
import { EButtonSize } from "../../utils/enum"
import { ERoleCapabilities } from "@teamparallax/common-libraries/lib/rbac/enum"
import { IContentPaneEntry } from "./interface"
import { TContentPaneProps } from "./type"
import { TitleWrapper } from "../title"
import React, { FC } from "react"
import cn from "classnames"
import s from "./content.module.scss"
export const CmsContentPane: FC<ICms<IContentPaneEntry>> = ({
	entry,
	onSaveCallback
}) => {
	const {
		id: entryId
	} = entry
	const {
		currentEntryId
	} = useCms()
	let button
	if (entry.buttonLink !== "" || currentEntryId === entryId) {
		button = {
			href: entry.buttonLink ?? "",
			title: (
				<CmsText
					entry={ entry }
					keyofEntryId="buttonText"
					shouldNotRenderMarkdown
				/>
			)
		}
	}
	return (
		<CmsWrapper
			editEntryId={ entryId }
			onSaveCallback={ onSaveCallback }
			rbac={ {
				requiredCapability: ERoleCapabilities.frontEndEditHomePage
			} }
		>
			<ContentPane
				button={ button }
				content={ (
					<CmsText
						entry={ entry }
						keyofEntryId="content"
						shouldUseMarkdownEditor
					/>
				) }
				hasTwoColumns={ currentEntryId !== entryId }
				subTitle={ (
					<CmsText
						entry={ entry }
						keyofEntryId="subTitle"
						shouldNotRenderMarkdown
					/>
				) }
				title={ (
					<CmsText
						entry={ entry }
						keyofEntryId="title"
						shouldNotRenderMarkdown
					/>
				) }
			/>
		</CmsWrapper>
	)
}
export const ContentPane: FC<TContentPaneProps> = ({
	button,
	hasTwoColumns,
	content = "",
	shouldFade = true,
	subTitle,
	isMarkdownContent,
	title
}) => {
	let renderedSubTitle = subTitle
	let renderedTitle = title
	let renderedContent = content
	if (isMarkdownContent) {
		renderedSubTitle = (
			<DegeniaMarkdown
				markdown={ renderedSubTitle as string }
			/>
		)
		renderedTitle = (
			<DegeniaMarkdown
				markdown={ renderedTitle as string }
			/>
		)
		renderedContent = (
			<DegeniaMarkdown
				markdown={ renderedContent as string }
			/>
		)
	}
	const innerContent = (
		<>
			<TitleWrapper
				subTitle={ renderedSubTitle }
				title={ renderedTitle }
			/>
			<div
				className={ cn(s.text, {
					[s.twoColumns]: hasTwoColumns
				}) }
			>
				{ renderedContent }
			</div>
			{ button && (
				<div
					className={ cn(s.button) }
				>
					<DegeniaButton
						href={ button.href }
						isInverted={ button.isInverted ?? true }
						size={ button.size ?? EButtonSize.big }
						title={ button.title }
					/>
				</div>
			)}
		</>
	)
	return (
		<div
			className={ cn(s.contentPane) }
		>
			{shouldFade
				? (
					<Fade
						bottom
					>
						{innerContent}
					</Fade>
				)
				: innerContent
			}
		</div>
	)
}