import { QaBox } from "./index"
import { TContainerShortCodePlugin } from "@teamparallax/react-components"
import React, { ReactElement, ReactNode } from "react"
const render: TContainerShortCodePlugin<"question"> = (
	attributes: Record<"question", string>,
	reactChildren?: ReactNode
): ReactElement => {
	return (
		<QaBox
			answer={ reactChildren }
			question={ attributes.question }
		/>
	)
}
export const accordionShortcode = {
	name: "accordion",
	render
}