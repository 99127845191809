import { EIconColor } from "../../.."
import { EIconSize } from "../../../components/icon/enum"
import { Fade } from "@teamparallax/react-components"
import { IInsuranceBoxesProps } from "./interface"
import { Icon } from "../../../components/icon"
import React, { FC } from "react"
import cn from "classnames"
import s from "./insurance-box.module.scss"
export const InsuranceBox: FC<IInsuranceBoxesProps> = ({
	icon,
	isDark,
	size,
	title,
	isWide
}) => {
	const iconBackgroundColor = isDark
		? EIconColor.darkgrey
		: undefined
	const iconForegroundColor = isDark
		? EIconColor.white
		: undefined
	return (
		<Fade bottom>
			<div
				className={ cn(s.insuranceBoxWrap, {
					[s.medium]: size === EIconSize.px74,
					[s.dark]: isDark,
					[s.wideBox]: isWide
				}) }
			>
				<div
					className={ s.icon }
				>
					<Icon
						alt={ title }
						backgroundColor={ iconBackgroundColor }
						color={ iconForegroundColor }
						icon={ icon }
						size={ size }
					/>
				</div>
				<span
					className={ cn(s.title) }
				>
					{ title }
				</span>
			</div>
		</Fade>
	)
}