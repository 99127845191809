import { IQaBoxProps } from "./interface"
import React, { FC, useState } from "react"
import TriangleSVG from "../../../../static/degenia/triangle-small.svg"
import cn from "classnames"
import s from "./qa-box.module.scss"
export const QaBox: FC<IQaBoxProps> = ({
	question,
	answer,
	isExtended: isExtendedSuper,
	clickHandler: clickHandlerSuper
}) => {
	const [isExtendedLocal, setIsExtendedLocal] = useState(false)
	const isExtended = isExtendedSuper ?? isExtendedLocal
	const clickHandler = ():void => {
		setIsExtendedLocal(!isExtendedLocal)
		clickHandlerSuper?.()
	}
	return (
		<div
			className={ cn(s.wrapper) }
		>
			<div
				className={ cn(s.question, {
					[s.colored]: isExtended
				}) }
				onClick={ clickHandler }
			>
				{ question }
				<TriangleSVG
					className={ cn(s.arrowLeft, {
						[s.arrowDown]: isExtended
					}) }
				/>
			</div>
			{isExtended && (
				<div
					className={ cn(s.answer) }
				>
					{ answer }
				</div>
			)}
		</div>
	)
}