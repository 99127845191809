import { DegeniaButton } from "../degenia-button"
import { EButtonSize } from "../../utils/enum"
import { EIconSize } from "../icon/enum"
import { Header1 } from "../text/h1"
import { IHeaderContentProps } from "./interface"
import { Icon } from "../icon"
import { useBreakPoints } from "@teamparallax/client-libraries/lib/hooks/use-break-points"
import React, { FC } from "react"
import cn from "classnames"
import s from "./header-content.module.scss"
export const HeaderContent: FC<IHeaderContentProps> = ({
	button,
	title,
	icon,
	subTitle,
	hasBackgroundImage
}) => {
	const isMobile = !useBreakPoints().lg
	const iconSize = isMobile
		? EIconSize.px89
		: EIconSize.px109
	return (
		<div
			className={ cn(s.headerContent, {
				[s.default]: hasBackgroundImage
			}) }
		>
			{
				icon && !hasBackgroundImage
				&& (
					<div
						className={ s.iconWrap }
					>
						<Icon
							alt={ title }
							icon={ icon }
							size={ iconSize }
						/>
					</div>
				)
			}
			<div
				className={ s.textWrap }
			>
				<Header1
					text={ title }
				/>
				<span
					className={ s.subtitle }
				>
					{subTitle}
				</span>
			</div>
			{
				button && !hasBackgroundImage && (
					<div
						className={ cn(s.button) }
					>
						<DegeniaButton
							hasBlueIcon={ isMobile }
							{ ...button }
							size={ button.size ?? EButtonSize.big }
						/>
					</div>
				)
			}
		</div>
	)
}