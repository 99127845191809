import { EIcon } from "../../../utils/enum"
import { EIconSize } from "../../icon/enum"
import { ILinkListProps } from "./interface"
import { Icon } from "../../icon"
import { Link, is } from "@teamparallax/react-components"
import { isNil } from "lodash-es"
import React, {
	FC, ReactElement, ReactNode, useEffect, useState
} from "react"
import StarSvg from "../../../../static/degenia/star-filled.svg"
import cn from "classnames"
import s from "./link-list.module.scss"
export const LinkList: FC<ILinkListProps> = ({
	title,
	linkProps,
	isMobile = false,
	isExtended: isExtendedSuper,
	clickHandler: clickHandlerSuper
}) => {
	const [isExtendedLocal, setIsExtendedLocal] = useState<boolean>(!isMobile)
	const shouldRenderExtended = ():boolean => {
		if (isMobile) {
			return isExtendedSuper ?? isExtendedLocal
		}
		else {
			return isExtendedLocal
		}
	}
	const isExtended = shouldRenderExtended()
	const clickHandler = ():void => {
		if (isMobile) {
			setIsExtendedLocal(!isExtendedLocal)
			clickHandlerSuper?.()
		}
	}
	useEffect(() => {
		const handleResize = (): void => {
			if (isMobile) {
				setIsExtendedLocal(false)
			}
			else {
				setIsExtendedLocal(true)
			}
		}
		window.addEventListener("resize", handleResize)
		return (): void => window.removeEventListener("resize", handleResize)
	}, [isMobile])
	return (
		<div
			className={ cn(s.wrapper, {
				[s.extended]: isMobile && isExtended
			}) }
		>
			<div
				className={ s.title }
				onClick={ clickHandler }
				role="button"
			>
				{ title }
			</div>
			<hr
				className={ cn(s.line, {
					[s.extended]: isExtended
				}) }
			/>
			{ isExtended && (
				<ul
					className={ s.linkList }
				>
					{linkProps.map((linkElement) => (
						<Link
							href={ linkElement.linkUrl }
							key={ linkElement.name }
						>
							<div
								className={ s.linkWrapper }
								role="button"
							>
								<div
									className={ s.image }
								>
									{ renderLinkElementImage(linkElement.image) ?? (
										<div
											style={ {
												height: "14px",
												width: "14px"
											} }
										>
											<StarSvg/>
										</div>
									) }
								</div>
								<li
									className={ s.link }
								>
									{linkElement.name}
								</li>
							</div>
						</Link>
					))}
				</ul>
			)}
			{ isMobile && isExtended && (
				<hr
					className={ cn(s.line) }
				/>
			)}
		</div>
	)
}
const renderLinkElementImage = (image?: EIcon|ReactElement): ReactNode => {
	if (isNil(image)) {
		return null
	}
	else if (is<ReactElement>(image, "props")) {
		return image
	}
	else {
		return (
			<Icon
				icon={ image as EIcon }
				size={ EIconSize.px55 }
			/>
		)
	}
}