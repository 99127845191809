import { TTextShortCodePlugin } from "@teamparallax/react-components"
import { YoutubeEmbed } from "../../youtube-embed"
import React, { ReactElement } from "react"
const render: TTextShortCodePlugin<"embedId"> = (
	attributes: Record<"embedId", string>
): ReactElement => {
	const embedId = attributes.embedId
	return (
		<YoutubeEmbed
			embedId={ embedId }
		/>
	)
}
export const youtubeEmbedShortcode = {
	name: "youtube-embed",
	render
}