import { CardItem } from "./card-item"
import { ECardItemColumns } from "./enum"
import { FC } from "react"
import { ICardItemsList } from "./interface"
import React from "react"
import cn from "classnames"
import s from "./card-items-list.module.scss"
export const CardItemsList: FC<ICardItemsList> = ({
	cardItemProps,
	columnCount = ECardItemColumns.three,
	hasBorder
}) => {
	return (
		<section
			className={ cn(s.cardItemsGrid, s[columnCount]) }
		>
			{
				cardItemProps.map((cardItem) => (
					<CardItem
						hasBorder={ hasBorder }
						key={ cardItem.itemId }
						{ ...cardItem }
					/>
				))
			}
		</section>
	)
}