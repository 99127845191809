import { EIcon, EIconColor } from "../../../../utils/enum"
import { EIconSize } from "../../../icon/enum"
import { IInfoCellProps } from "./interface"
import { Icon } from "../../../icon"
import { Tooltip } from "@teamparallax/react-components"
import React, { FC } from "react"
import cn from "classnames"
import s from "./index.module.scss"
export const InfoCell: FC<IInfoCellProps> = ({
	description,
	children
}) => {
	return (
		<div
			className={ cn(s.infoCell, s.tippy) }
		>
			<span
				className={ s.label }
			>
				{children}
			</span>
			<Tooltip
				content={ description }
				tippyProps={ {
					placement: "right",
					theme: "degenia"
				} }
			>
				<div
					className={ s.icon }
				>
					<Icon
						color={ EIconColor.green }
						hasTransparentBackground
						icon={ EIcon.info }
						size={ EIconSize.px30 }
					/>
				</div>
			</Tooltip>
		</div>
	)
}