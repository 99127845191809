import { ICommunicationButtonProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
export const CommunicationButton: FC<ICommunicationButtonProps> = ({
	className,
	children,
	onClickCallback,
	type
}) => {
	return (
		<button
			className={ cn(
				className
			) }
			onClick={ onClickCallback }
			// eslint-disable-next-line react/button-has-type
			type={ type }
		>
			{ children }
		</button>
	)
}