import { Attachment } from "../../../components/shortcode-links/attachment"
import { CommunicationMessageActions } from "../communication-message-actions"
import { ICommunicationMessageProps } from "./interface"
import { prettifyDate } from "../../../utils/date"
import React, { FC, useState } from "react"
import Star from "../../../../static/degenia/star-filled.svg"
import s from "./communication-message.module.scss"
export const CommunicationMessage: FC<ICommunicationMessageProps> = ({
	attachmentUrl,
	messageDate,
	messageSubtitle,
	messageText,
	messageTheme,
	messageTitle,
	shouldRenderOpened
}) => {
	const [isOpen, setOpen] = useState<boolean>(Boolean(shouldRenderOpened))
	const openHandler = (): void => {
		if (isOpen) {
			return
		}
		setOpen(true)
	}
	return (
		<div
			className={ s.communicationMessage }
			onClick={ openHandler }
		>
			<Star
				className={ s.starIcon }
			/>
			<div
				className={ s.communicationMessageInfo }
			>
				<div
					className={ s.communicationMessageHeader }
				>
					<span
						className={ s.communicationMessageTheme }
					>
						{ messageTheme }
					</span>
					<span
						className={ s.communicationMessageTitle }
					>
						{ messageTitle }
					</span>
					<span
						className={ s.communicationMessageSubTitle }
					>
						{ messageSubtitle }
					</span>
				</div>
				{isOpen && (
					<div
						className={ s.communicationMessageBody }
					>
						{messageText && (
							<div
								className={ s.communicationMessageBodyText }
							>
								{ messageText }
							</div>
						)}
						{attachmentUrl && (
							<Attachment
								className={ s.communicationMessageBodyAttachment }
								title="Rechnung - PDF download"
								url={ attachmentUrl }
							/>
						)}
					</div>
				)}
			</div>
			<CommunicationMessageActions
				hasAttachment={ attachmentUrl }
				isOpen={ isOpen }
				messageDate={ prettifyDate(new Date(messageDate)) }
				setOpenCallback={ (): void => setOpen(false) }
			/>
		</div>
	)
}