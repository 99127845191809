import { DegeniaButton } from "../../../components/degenia-button"
import { DegeniaMarkdown } from "../../../components/markdown"
import { EButtonSize, EIconColor } from "../../../utils/enum"
import { EIconSize } from "../../../components/icon/enum"
import { IJobBoxProps } from "./interface"
import { Icon } from "../../../components/icon"
import React, { FC } from "react"
import cn from "classnames"
import s from "./job-box.module.scss"
export const JobBox: FC<IJobBoxProps> = ({
	button,
	icon,
	description,
	title,
	isFlatTitleBox = false
}) => {
	return (
		<div
			className={ cn(s.jobBox) }
		>
			<div className={ cn(s.iconBox) }>
				<Icon
					alt={ `${icon }Icon` }
					borderColor={ EIconColor.darkgrey }
					icon={ icon }
					size={ EIconSize.px109 }
				/>
			</div>
			<div
				className={ cn(s.titleBox, {
					[s.heightSmall]: isFlatTitleBox
				}) }
			>
				{ title }
			</div>
			<div
				className={ cn(s.textBox, {
					[s.heightTall]: isFlatTitleBox
				}) }
			>
				<div
					className={ cn(s.jobDescription) }
				>
					{ typeof description === "string"
						? (
							<DegeniaMarkdown
								markdown={ description }
							/>
						)
						: description
					}
				</div>
				<div
					className={ cn(s.button) }
				>
					{
						button && (
							<DegeniaButton
								href={ button.href }
								size={ EButtonSize.medium }
								title={ button.title }
							/>
						)
					}
				</div>
			</div>
		</div>
	)
}