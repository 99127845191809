import { Container } from "../container"
import { useBreakPoints } from "@teamparallax/client-libraries/lib/hooks/use-break-points"
import React, { FC } from "react"
import ScrollMobileSvg from "../../../static/degenia/scroll-down.svg"
import ScrollSvg from "../../../static/degenia/mouse-scroll.svg"
import s from "./scroll-icon.module.scss"
export const ScrollIcon: FC = () => {
	const isMobile = !useBreakPoints().lg
	return (
		<Container>
			<div
				className={ s.scrollIcon }
			>
				{
					isMobile
						? <ScrollMobileSvg/>
						: <ScrollSvg/>
				}
			</div>
		</Container>
	)
}