export enum EMenuOpenState {
	closed = "closed",
	login = "login",
	navigation = "navigation",
	search = "search"
}
export enum EMenuClickHandler {
	onClickClose = "onClickClose",
	onClickForCustomers = "onClickForCustomers",
	onClickLogin = "onClickLogin",
	onClickNavigation = "onClickNavigation",
	onClickSearch = "onClickSearch"
}