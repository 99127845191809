import { ITitleProps } from "./interface"
import { TableButton } from "../table-button"
import ActiveStatus from "../../../static/degenia/icons/active-status.svg"
import InactiveStatus from "../../../static/degenia/icons/inactive-status.svg"
import React, { FC, ReactNode } from "react"
import s from "./title.module.scss"
import slugify from "slugify"
export const DetailsTitle: FC<ITitleProps> = ({
	isActive,
	tableButtonProps,
	title
}) => {
	const getIcon = (): ReactNode => {
		const shouldRenderIcon = isActive !== undefined
		const iconToRender = isActive
			? <ActiveStatus className={ s.icon }/>
			: <InactiveStatus className={ s.icon }/>
		return shouldRenderIcon && iconToRender
	}
	return (
		<div className={ s.titleContainer }>
			<h2
				className={ s.title }
				id={ slugify(s.title, {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					lower: true
				}) }
			>
				{title}
			</h2>
			{ getIcon() }
			{tableButtonProps && (
				<TableButton
					{ ...tableButtonProps }
				/>
			)
			}
		</div>
	)
}