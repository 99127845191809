import { DegeniaGenericForm } from "../degenia-generic-form"
import { EInputType } from "../degenia-input-field/enum"
import { IAddressChangeFormData, IAddressChangeProps } from "./interface"
import React, { FC } from "react"
export const AddressChange: FC<IAddressChangeProps> = ({
	onSubmit
}) => (
	<DegeniaGenericForm
		fields={ [
			{
				isRequired: true,
				name: "insuranceNumber",
				placeholder: "Versicherungsscheinnummer",
				title: "Vers. Schein Nr.",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "lastname",
				placeholder: "Nachname",
				title: "Nachname",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "firstname",
				placeholder: "Vorname",
				title: "Vorname",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "address",
				placeholder: "Strasse und Hausnummer",
				title: "Strasse und Hausnummer",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "zipcode",
				placeholder: "Postleitzahl",
				title: "Postleitzahl",
				type: EInputType.number
			},
			{
				isRequired: true,
				name: "location",
				placeholder: "Ort",
				title: "Ort",
				type: EInputType.text
			},
			{
				isRequired: true,
				name: "startingFrom",
				placeholder: "Neue Adresse gültig ab",
				title: "Neue Adresse gültig ab",
				type: EInputType.date
			},
			{
				name: "message",
				placeholder: "Ihre Mitteilung",
				title: "Ihre Mitteilung",
				type: EInputType.textarea
			}
		] }
		submitButtonTitle="Adresse ändern"
		submitCallback={ (args):void => {
			const formData = args as IAddressChangeFormData
			onSubmit(formData)
		} }
	/>
)