import { EUserRole } from "@teamparallax/common-libraries/lib/rbac/enum"
import {
	FC, createContext, useContext, useEffect, useState
} from "react"
import { PickIIdisCustomerExcludeKeyofIIdisCustomerOriginalData as IApiCustomer } from "@teamparallax/api-gateway-degenia-rest-api"
import { useApiGatewayDegeniaRest } from "../../../provider/api-gateway-degenia-rest"
import { useAuth } from "@teamparallax/react-components"
import React from "react"
interface ICustomerContext {
	customer?: IApiCustomer
}
const CustomerContext = createContext<ICustomerContext|undefined>(undefined)
export const CustomerProvider: FC = ({
	children
}) => {
	const [customer, setCustomer] = useState<IApiCustomer|undefined>(undefined)
	const {
		idisApi
	} = useApiGatewayDegeniaRest()
	const {
		profile
	} = useAuth()
	useEffect(() => {
		if (profile) {
			const customerRole = profile.roles
				.find((role) => role.roleName === EUserRole.degeniaCustomer)
			if (typeof customerRole?.value === "string") {
				void (async (): Promise<void> => {
					const {
						data: customerTmp
					} = await idisApi.getOwnCustomer()
					setCustomer(customerTmp)
				})()
			}
		}
	}, [idisApi, profile])
	return (
		<CustomerContext.Provider
			value={ {
				customer
			} }
		>
			{ children }
		</CustomerContext.Provider>
	)
}
export const useCustomer = ():ICustomerContext => {
	const context = useContext(CustomerContext)
	if (context === undefined) {
		throw new Error("useCustomer must be used within a CustomerContextProvider")
	}
	return context
}