import { Details } from "../details"
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { ICustomerDetailsProps } from "./interface"
import { prettifyDate } from "../../utils/date"
import { useTranslation } from "react-i18next"
import React, { FC } from "react"
export const CustomerDetails: FC<ICustomerDetailsProps> = ({
	customer
}) => {
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	const {
		customerPrivate,
		customerTelephone
	} = customer
	return (
		<Details
			details={ [
				{
					label: t("customerDetails|name"),
					value: `${customerPrivate.name}
						${customerPrivate.surname}
					`
				},
				{
					label: t("customerDetails|address"),
					value: `${customerPrivate.zipcode}
						${customerPrivate.street}
					`
				},
				{
					label: t("customerDetails|privatePhone"),
					value: `${customerTelephone.telephonePrivatePrefix}
						${customerTelephone.telephonePrivate}
					`
				},
				{
					label: t("customerDetails|businessPhone"),
					value: `${customerTelephone.telephoneBusinessPrefix}
						${customerTelephone.telephoneBusiness}`
				},
				{
					label: t("customerDetails|birthday"),
					value: customerPrivate.birthdate
						? prettifyDate(customerPrivate.birthdate)
						: ""
				}
			] }
		/>
	)
}