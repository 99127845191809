import { EIcon } from "../../../utils/enum"
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { IListHeaderProps } from "./interface"
import { Reoverlay } from "reoverlay"
import {
	Tooltip, UploadModal, failureToast, useApiGatewayRest, useAuth, useCms
} from "@teamparallax/react-components"
import { Trans } from "react-i18next"
import { UploadResult } from "@uppy/core"
import { determineImageOrIcon } from "../card-items-list/card-item"
import { getIcon } from "../../../utils"
import { isNil } from "lodash-es"
import { prettifyDate } from "../../../utils/date"
import React, {
	FC, ReactElement, useCallback, useState
} from "react"
import UpdateIcon from "../../../../static/degenia/icons/update-icon.svg"
import cn from "classnames"
import s from "./list-header.module.scss"
const CUploadPath = "microsite"
const allowedImageTypes = [
	"png",
	"jpg",
	"jpeg",
	"svg"
]
export const ListHeader: FC<IListHeaderProps> = ({
	elementKey,
	imageComponent: icon,
	title,
	entryId,
	keyofEntryId,
	lastEdited
}) => {
	const {
		currentEntryId,
		setContent: setContentInProvider
	} = useCms()
	const isWriteMode = entryId && keyofEntryId && currentEntryId === entryId
	const {
		fileApi
	} = useApiGatewayRest()
	const {
		profile
	} = useAuth()
	const [imageComponent, setImageComponent] = useState<ReactElement | string>(icon)
	const renderUploadError = useCallback((extensions: string[]) : ReactElement => {
		return (
			<Trans
				components={ {
					strong: <strong/>
				} }
				i18nKey={ `${ENameSpace.common}~upload|Only files with the extensions {{extensions}} are allowed` }
				values={ {
					extensions
				} }
			/>
		)
	}, [])
	const AddFileIcon = getIcon(EIcon.addFile)
	return (
		<section className={ s.listHeader }>
			<Tooltip
				content="Hier klicken um ein neues Logo hochzuladen"
				isDisabled={ !isWriteMode }
			>
				<div
					className={ cn(s.listHeaderLogoContainer, {
						[s.isWriteable]: isWriteMode
					}) }
					onClick={ (): void => {
						if (isWriteMode) {
							Reoverlay.showModal(UploadModal, {
								onUploaded: async (url: UploadResult): Promise<void> => {
									const uploadedFile = url.successful?.[0]
									const {
										name: fileName,
										extension
									} = uploadedFile
									let isSuccessful = true
									if (!allowedImageTypes.includes(extension)) {
										failureToast(renderUploadError(allowedImageTypes))
										isSuccessful = false
									}
									if (isSuccessful && !isNil(fileName) && !isNil(profile)) {
										const {
											data: {
												link,
												metaData: {
													ext
												}
											}
										} = await fileApi.getUserFile(
											profile?.id as string,
											`${CUploadPath}/${fileName}`,
											true
										)
										if (!allowedImageTypes.includes(ext)) {
											failureToast(renderUploadError(allowedImageTypes))
											isSuccessful = false
										}
										if (isSuccessful) {
											setImageComponent(imageComponent)
											if (entryId && keyofEntryId) {
												setContentInProvider(entryId, keyofEntryId, link)
											}
										}
									}
									Reoverlay.hideModal()
								},
								storeUploadFilesPath: CUploadPath
							})
						}
					}
					}
				>
					{isWriteMode && (
						<div className={ cn(s.listHeaderLogoContainerWritableOverlay) }>
							<AddFileIcon/>
						</div>
					) }
					{determineImageOrIcon(imageComponent)}
				</div>
			</Tooltip>
			<h1 className={ s.listHeaderTitle }>
				{ title }
			</h1>
			<div className={ s.listHeaderInfoContainer }>
				<UpdateIcon className={ s.icon }/>
				<div className={ s.listHeaderInfo }>
					<span className={ s.listHeaderInfoText }>
						Letztes Seiten-Update: { prettifyDate(lastEdited) }
					</span>
					<span className={ s.listHeaderInfoText }>
						Agenturnummer:
						{" "}
						{ elementKey }
					</span>
				</div>
			</div>
		</section>
	)
}