import { DynamicLoader, TLazyImport } from "@teamparallax/react-components"
import { ILayout } from "@teamparallax/react-components"
import React, { FC } from "react"
export const MyDegeniaLayout: FC<ILayout> = (props) => {
	return (
		<DynamicLoader<ILayout>
			importPromise={ (async () => import("./mydegenia-layout")) as TLazyImport }
			props={ props }
		/>
	)
}
export default MyDegeniaLayout