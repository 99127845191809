import { DegeniaButton } from "../../degenia-button"
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { EVariant, Text } from "@teamparallax/react-components"
import { FormProvider, useForm } from "react-hook-form"
import { ILoginFormData } from "../interface"
import { ILoginFormProps } from "./interface"
import { InputField } from "../input-field"
import { InputGroup } from "@teamparallax/react-components"
import { useTranslation } from "react-i18next"
import React, {
	FC, ReactElement, useState
} from "react"
import s from "../login.module.scss"
export const LoginForm: FC<ILoginFormProps> = ({
	onLogin
}) => {
	const {
		t
	} = useTranslation(ENameSpace.common)
	const methods = useForm<ILoginFormData>()
	const [errorMessage, setErrorMessage] = useState<string|ReactElement|undefined>()
	const [isLoading, setIsLoading] = useState(false)
	const onSubmit = async (credentials: ILoginFormData): Promise<void> => {
		setIsLoading(true)
		const isErrorless = await methods.trigger()
		if (isErrorless) {
			const loginError = await onLogin(credentials)
			setErrorMessage(loginError)
			setIsLoading(false)
		}
	}
	return (
		<FormProvider
			{ ...methods }
		>
			<form
				className={ s.form }
				onKeyPress={ (e): void => {
					if (e.key === "Enter" && !e.shiftKey) {
						const values = methods.getValues()
						void onSubmit(values)
					}
				} }
			>
				<InputGroup
					classnames={ s.input }
					key="username"
				>
					<InputField
						autocomplete="username"
						hint={ t(`hintUsername`) }
						isRequired
						name="user"
						placeholder={ t(`username`) }
						type="text"
					/>
				</InputGroup>
				<InputGroup
					classnames={ s.input }
					key="password"
				>
					<InputField
						autocomplete="current-password"
						hint={ t(`hintPassword`) }
						isRequired
						name="password"
						placeholder={ t(`password`) }
						type="password"
					/>
				</InputGroup>
				{ errorMessage && (
					<Text
						hasLinefeed
						isBold
						style={ {
							marginTop: "30px",
							textAlign: "center"
						} }
						text={ errorMessage }
						variant={ EVariant.danger }
					/>
				)}
				<div
					className={ s.button }
				>
					<DegeniaButton
						hasBlueIcon
						isLoading={ isLoading }
						onClick={ methods.handleSubmit(onSubmit) }
						title={ t(`login`) }
					/>
				</div>
			</form>
		</FormProvider>
	)
}