import { ICmsStrapiImage } from "@teamparallax/api-gateway-rest-api"
export const parseStrapiImageUrl = (
	baseStrapiUrl: string,
	imageUrlRaw?: string | ICmsStrapiImage
): string|undefined => {
	if (!imageUrlRaw) {
		return undefined
	}
	let imageUrl: string
	if (imageUrlRaw?.hasOwnProperty("url")) {
		const imageStrapiUrl = (imageUrlRaw as ICmsStrapiImage).url
		imageUrl = `${baseStrapiUrl}${imageStrapiUrl}`
	}
	else if ((imageUrlRaw as string).startsWith("/static")) {
		imageUrl = imageUrlRaw as string
	}
	else if ((imageUrlRaw as string).startsWith("/")) {
		imageUrl = `${baseStrapiUrl}${imageUrlRaw}`
	}
	else {
		imageUrl = imageUrlRaw as string
	}
	return imageUrl
}
export const parseStrapiUrl = (
	baseStrapiUrl: string,
	strapiUrl: string
): string => {
	if (strapiUrl.startsWith("http")) {
		return strapiUrl
	}
	return `${baseStrapiUrl}${strapiUrl}`
}