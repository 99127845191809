import { DegeniaGenericForm } from "../../../components/degenia-generic-form"
import { EInputType } from "../../../components/degenia-input-field/enum"
import { IDegeniaGenericFormFields } from "../../../components/degenia-generic-form/interface"
import { IDetailsListProps, IDetailsProps } from "./interface"
import React, { FC, ReactElement } from "react"
import cn from "classnames"
import s from "./details-list.module.scss"
export const DetailsList: FC<IDetailsListProps> = ({
	details,
	isEditable = false,
	detailsType = EInputType.text
}) => {
	const getEditableForm = (details: IDetailsProps[]): ReactElement => {
		const detailsFormElements = details.map(
			(detail: IDetailsProps): IDegeniaGenericFormFields => {
				return (
					{
						customInput: detail.customInput,
						defaultValue: detail.value,
						isDetailEditable: detail.isEditable,
						name: detail.value,
						title: detail.label,
						type: detail.detailsType ?? detailsType
					}
				)
			}
		)
		return (
			<DegeniaGenericForm
				fields={ detailsFormElements }
				// eslint-disable-next-line no-console
				submitCallback={ (): void => console.log("submit clicked") }
			/>
		)
	}
	const getDetailsList = (details: IDetailsProps[]): ReactElement => {
		return (
			<div>
				<ul
					className={ cn(s.dataList) }
				>
					{ details.map((detail) => {
						return (
							<li
								className={ cn(s.dataItem) }
								key={ detail.label }
							>
								<span
									className={ cn(s.itemName) }
								>
									{detail.label}
								</span>
								<span className={ cn(s.itemValue) }>
									{detail.value}
								</span>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}
	const childComponent = isEditable
		? getEditableForm(details)
		: getDetailsList(details)
	return childComponent
}