import { Button } from "../../../components/button"
import {
	Controller, FormProvider, useForm
} from "react-hook-form"
import { DropZone, InputGroup } from "@teamparallax/react-components"
import React, { FC } from "react"
import ReactSelect from "react-select"
import cn from "classnames"
import s from "./communication-message-form.module.scss"
export const CommunicationMessageForm: FC = () => {
	const methods = useForm()
	const selectOptions = [
		{
			label: "Privathaftpflicht T20 - Herr Stefan Bauer",
			value: "degenia"
		},
		{
			label: "Privathaftpflicht T21 - Herr Stefan Bauer",
			value: "anotherReceiver"
		},
		{
			label: "Privathaftpflicht T22 - Herr Stefan Bauer",
			value: "anotherReceiverq"
		}
	]
	return (
		<section
			className={ s.communicationMessageFormSection }
		>
			<FormProvider
				{ ...methods }
			>
				<form
					className={ s.communicationMessageForm }
				>
					<InputGroup
						classnames={ s.communicationMessageFormFieldContainer }
					>
						<span
							className={ s.communicationMessageFormFieldTitle }
						>
							Empfänger
						</span>
						<div
							className={ s.communicationMessageFormFieldSelectContainer }
						>
							<Controller
								as={ ReactSelect }
								classNamePrefix={ s.communicationMessageFormFieldSelect }
								control={ methods.control }
								defaultValue={ selectOptions?.[0] }
								name="receiver"
								options={ selectOptions }
							/>
						</div>
					</InputGroup>
					<InputGroup
						classnames={ s.communicationMessageFormFieldContainer }
					>
						<span
							className={ s.communicationMessageFormFieldTitle }
						>
							Betreff
						</span>
						<input
							className={ s.communicationMessageFormField }
							name="subject"
							ref={ methods.register() }
							type="text"
						/>
					</InputGroup>
					<InputGroup
						classnames={ cn(
							s.communicationMessageFormFieldContainer,
							s.communicationMessageFormFieldContainerStart
						) }
					>
						<span
							className={ s.communicationMessageFormFieldTitle }
						>
							Nachricht
						</span>
						<textarea
							className={ s.communicationMessageFormFieldArea }
							name="message"
							ref={ methods.register() }
						/>
					</InputGroup>
					<InputGroup
						classnames={ cn(
							s.communicationMessageFormFieldContainer,
							s.communicationMessageFormFieldContainerStart
						) }
					>
						<span
							className={ s.communicationMessageFormFieldTitle }
						>
							Anhang
						</span>
						<DropZone
							className={ s.communicationMessageFormFieldDropzone }
						/>
					</InputGroup>
					<div
						className={ s.communicationMessageFormButtonContainer }
					>
						<Button
							className={ cn(
								s.communicationMessageFormButton,
								s.communicationMessageFormButtonSend
							) }
							type="submit"
						>
							Nachricht absenden
						</Button>
						<Button
							className={ cn(
								s.communicationMessageFormButton,
								s.communicationMessageFormButtonReset
							) }
							type="reset"
						>
							Löschen
						</Button>
					</div>
				</form>
			</FormProvider>
		</section>
	)
}