import { Award } from "../../components/award"
import { Container } from "../../components/container"
import { IHeaderProps } from "./interface"
import { Navigation } from "../../components/navigation"
import { ScrollIcon } from "../../components/scroll-icon"
import { parseStrapiImageUrl } from "../../utils/strapi"
import { useAppConfig } from "@teamparallax/react-components"
import { useBreakPoints } from "@teamparallax/client-libraries/lib/hooks/use-break-points"
import React, { FC, ReactElement } from "react"
import cn from "classnames"
import s from "./header.module.scss"
export const Header: FC<IHeaderProps> = ({
	children,
	imageUrl: imageUrlRaw,
	awards,
	navigationClickHandler,
	hasDmu
}) => {
	const {
		strapiUrl
	} = useAppConfig()
	const imageUrl = parseStrapiImageUrl(strapiUrl, imageUrlRaw)
	const isMobile = !useBreakPoints().lg
	const headerStyles = imageUrl
		? {
			backgroundImage: `linear-gradient(
			131.3deg,
			rgba(199, 213, 48, 0.85),
			rgba(99, 132, 155, 0.15)
			),
			url(${imageUrl})`
		}
		: {}
	const preparedAwards = isMobile
		? awards?.length
			? [awards[0]]
			: []
		: awards
	const getScrollWrapper = (isMobileBlock?: boolean): ReactElement => {
		const scrollWrapper = isMobileBlock
			? (
				<div
					className={ s.scrollWrapper }
				>
					<ScrollIcon/>
				</div>
			)
			: (
				<Container>
					{
						awards?.length
							? (
								<div
									className={ cn(s.awardWrapper) }
								>
									{
										preparedAwards?.map((award) => (
											<Award
												awardImage={ (
													<img
														alt={ award.title }
														key={ award.title ?? Math.random() }
														src={ parseStrapiImageUrl(
															strapiUrl,
															award.image
														) }
													/>
												) }
												key={ award.title ?? Math.random() }
											/>
										))
									}
								</div>
							)
							: null
					}
					<div
						className={ s.scrollWrapper }
					>
						{ !isMobile && <ScrollIcon/> }
					</div>
				</Container>
			)
		return scrollWrapper
	}
	return (
		<div>
			<div
				className={ cn(s.header, {
					[s.default]: !imageUrl
				}) }
				style={ headerStyles }
			>
				<Navigation
					hasDmu={ hasDmu }
					navigationClickHandler={ navigationClickHandler }
				/>
				{
					(!isMobile || !imageUrl)
					&& (
						<Container>
							{children}
						</Container>
					)
				}
				{
					imageUrl && getScrollWrapper()
				}
			</div>
			{
				imageUrl && isMobile
				&& (
					<div
						className={ s.mobileWrapper }
					>
						{children}
						{getScrollWrapper(isMobile)}
					</div>
				)
			}
		</div>
	)
}