import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { FC, useState } from "react"
import { ISearchInput } from "./interface"
import { useTranslation } from "react-i18next"
import React from "react"
import cn from "classnames"
import s from "./search-input-field.module.scss"
export const SearchInput: FC<ISearchInput> = ({
	isActive,
	inputValue
}) => {
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>):void => {
		if (event.key === "Enter") {
			// eslint-disable-next-line no-console
			console.log("enter button has been pressed")
		}
	}
	const [value, setValue] = useState(inputValue)
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	return (
		<div
			className={ cn(s.wrapper) }
		>
			<input
				className={ cn(s.input, {
					[s.active]: isActive
				}) }
				onChange={ (e): void => setValue(e.target.value) }
				onKeyDown={ handleKeyDown }
				placeholder={ t(`search|hint`) }
				value={ value }
			/>
		</div>
	)
}