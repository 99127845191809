import { EIcon } from "../../utils/enum"
import { ERoleCapabilities } from "@teamparallax/common-libraries/lib/rbac/enum"
import { ILayout, Icon } from "@teamparallax/react-components"
import { getIcon } from "../../utils"
import NavbarHeader from "./mydegenia/navbar/navbar-header"
import React, { FC } from "react"
const DefaultIconSize = 20
const LayoutIcon:FC<{icon: EIcon, size?: number}> = ({
	icon,
	size
}) => {
	return (
		<Icon
			icon={ getIcon(icon) }
			size={ size ?? DefaultIconSize }
		/>
	)
}
export const layoutStructure: ILayout = {
	navbar: {
		header: <NavbarHeader/>,
		sections: [
			{
				label: "",
				subSections: [
					{
						href: "/portal",
						icon: <LayoutIcon icon={ EIcon.speedometer }/>,
						label: "Dashboard",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						href: "/portal/service-team",
						icon: <LayoutIcon icon={ EIcon.contact }/>,
						label: "Serviceteam",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						entries: [{
							href: "/portal/kunden-und-vertraege/suche",
							icon: <LayoutIcon icon={ EIcon.documentSearch } size={ 22 }/>,
							label: "Kunden & Vertragssuche"
						},
						{
							href: "/portal/kunden-und-vertraege/nicht-policierte-antraege",
							icon: <LayoutIcon icon={ EIcon.documentLock } size={ 22 }/>,
							label: "Nichtpolicierte Anträge"
						},
						{
							href: "/portal/kunden-und-vertraege/daten-export",
							icon: <LayoutIcon icon={ EIcon.documentExport } size={ 22 }/>,
							label: "Datenexport"
						},
						{
							href: "/portal/kunden-und-vertraege/statistiken",
							icon: <LayoutIcon icon={ EIcon.documentStats } size={ 22 }/>,
							label: "Statistiken"
						},
						{
							href: "/portal/kunden-und-vertraege/download",
							icon: <LayoutIcon icon={ EIcon.documentDownload } size={ 22 }/>,
							label: "Download Dokumente"
						},
						{
							href: "/portal/kunden-und-vertraege/nachrichten",
							icon: <LayoutIcon icon={ EIcon.newsletter } size={ 22 }/>,
							label: "Nachrichten & Kundenanfragen"
						}
						],
						icon: <LayoutIcon icon={ EIcon.folder }/>,
						label: "Kunden & Verträge",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						entries: [
							{
								href: "/portal/rechner/vergleichsrechner",
								icon: <LayoutIcon icon={ EIcon.calculator } size={ 16 }/>,
								label: "Vergleichsrechner"
							},
							{
								href: "/portal/rechner/vergleichsrechner-gewerbe",
								icon: <LayoutIcon icon={ EIcon.calculatorBriefcase } size={ 26 }/>,
								label: "Vergleichsrechner Gewerbe (Greta)"
							},
							{
								href: "/portal/rechner/gespeichert",
								icon: <LayoutIcon icon={ EIcon.calculatorOfficeClip } size={ 30 }/>,
								label: "Gespeicherte Berechnungen"
							},
							{
								href: "/portal/rechner/risikoleben",
								icon: <LayoutIcon icon={ EIcon.calculatorAlert } size={ 28 }/>,
								label: "Angebotsrechner Risikoleben"
							}
						],
						icon: <LayoutIcon icon={ EIcon.calculator } size={ 16 }/>,
						label: "Rechner",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						entries: [{
							href: "/portal/gesellschaften-und-produkte/gesellschaften",
							icon: <LayoutIcon icon={ EIcon.starFilled }/>,
							label: "Gesellschaften"
						},
						{
							href: "/portal/gesellschaften-und-produkte/erklaerung",
							icon: <LayoutIcon icon={ EIcon.starFilled }/>,
							label: "Erklärung",
							requiredCapability: ERoleCapabilities.frontEndEditMicrosite
						},
						{
							href: "/portal/gesellschaften-und-produkte/products",
							icon: <LayoutIcon icon={ EIcon.starFilled }/>,
							label: "Produkte",
							requiredCapability: ERoleCapabilities.frontEndViewBroker
						}],
						icon: <LayoutIcon icon={ EIcon.shield } size={ 22 }/>,
						label: "Gesellschaften & Produkte",
						requiredCapability: ERoleCapabilities.frontEndViewMicrosite
					},
					{
						href: "/portal/courtage",
						icon: <LayoutIcon icon={ EIcon.courtage } size={ 21 }/>,
						label: "Courtage",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						href: "/portal/bestandsuebertragung",
						icon: <LayoutIcon icon={ EIcon.doubleArrow }/>,
						label: "Bestandsübertragung",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						href: "/portal/news",
						icon: <LayoutIcon icon={ EIcon.flag }/>,
						label: "News & Newsletter",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						href: "/portal/kalender",
						icon: <LayoutIcon icon={ EIcon.calendar }/>,
						label: "Kalender",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						href: "/portal/weiterbildung",
						icon: <LayoutIcon icon={ EIcon.doctoralHat }/>,
						label: "Weiterbildung",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						href: "/portal/veranstaltungen",
						icon: <LayoutIcon icon={ EIcon.cocktail }/>,
						label: "Veranstaltungen",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						href: "/portal/ihre-website",
						icon: <LayoutIcon icon={ EIcon.codeCube }/>,
						label: "Ihre Website",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						href: "/portal/vorteilsnetzwerk",
						icon: <LayoutIcon icon={ EIcon.network }/>,
						label: "Vorteilsnetzwerk",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					},
					{
						href: "/portal/profil",
						icon: <LayoutIcon icon={ EIcon.profile }/>,
						label: "Ihr Profil",
						requiredCapability: ERoleCapabilities.frontEndViewBroker
					}
				]
			},
			{
				label: "Verwaltung",
				requiredCapability: ERoleCapabilities.frontEndViewAdmin,
				subSections: [
					{
						href: "/portal/administration/user",
						icon: <LayoutIcon icon={ EIcon.gear }/>,
						label: "Benutzer"
					}]
			}
		]
	}
}