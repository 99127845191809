/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { IDegeniaCheckboxProps } from "./interface"
import React, {
	Ref, forwardRef, useEffect, useRef
} from "react"
import s from "./checkbox.module.scss"
export const DegeniaCheckbox = forwardRef<HTMLElement, IDegeniaCheckboxProps>(
	({
		indeterminate, name, ...rest
	}, ref: Ref<HTMLElement>) => {
		const defaultRef = useRef()
		const resolvedRef: any = ref ?? defaultRef
		useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate
		}, [resolvedRef, indeterminate])
		return (
			<label
				className={ s.checkboxLabel }
			>
				<input
					ref={ resolvedRef }
					type="checkbox"
					{ ...rest }
				/>
				<span className={ s.checkbox }/>
				{name}
			</label>
		)
	}
)