import React, { FC } from "react"
import s from "./index.module.scss"
export const Small:FC = ({
	children
}) => {
	return (
		<span
			className={ s.small }
		>
			{children}
		</span>
	)
}