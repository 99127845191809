import { IOverviewPaneProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./overview.module.scss"
export const OverviewPane:FC<IOverviewPaneProps> = ({
	children
}) => {
	return (
		<div
			className={ cn(s.overviewWrapper) }
		>
			{ children }
		</div>
	)
}