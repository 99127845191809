import { IOtherCustomersProps } from "./interface"
import { OtherCustomersBox } from "./othercustomers-box"
import { ResponsiveCarousel } from "../../components/responsive-carousel"
import React, { FC } from "react"
import s from "./othercustomers.module.scss"
export const OtherCustomersPane: FC<IOtherCustomersProps> = ({
	title,
	otherCustomersBoxProps
}) => {
	const breakPointMap = {
		1: 0,
		2: 910
	}
	return (
		<div
			className={ s.wrapper }
		>
			<span
				className={ s.title }
			>
				{ title }
			</span>
			<ResponsiveCarousel
				breakPointMap={ breakPointMap }
				doesNotUseDefaultBreakPoints
				hasGreenArrows
			>
				{otherCustomersBoxProps.map((otherCustomersBox) => (
					<OtherCustomersBox
						button={ otherCustomersBox.button }
						icon={ otherCustomersBox.icon }
						imageUrl={ otherCustomersBox.imageUrl }
						key={ otherCustomersBox.title }
						subtitle={ otherCustomersBox.subtitle }
						title={ otherCustomersBox.title }
					/>
				))}
			</ResponsiveCarousel>
		</div>
	)
}