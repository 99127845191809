import {
	EIcon, EIconColor, EIconSize
} from "../.."
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { FC } from "react"
import { ISearchProps } from "./interface"
import { Icon } from "../icon/index"
import { Navigation } from "../navigation"
import { Overlay } from "../overlay"
import { ResultList } from "./result-list"
import { SearchInput } from "./search-input/index"
import { useTranslation } from "react-i18next"
import React from "react"
import cn from "classnames"
import s from "./search.module.scss"
export const Search: FC<ISearchProps> = ({
	navigationClickHandler,
	hasDmu,
	resultProps,
	inputValue
}) => {
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	return (
		<Overlay>
			<div
				className={ cn(s.wrapper) }
			>
				<Navigation
					hasDmu={ hasDmu }
					isActive
					navigationClickHandler={ navigationClickHandler }
				/>
				<div
					className={ cn(s.searchWrapper) }
				>
					<div
						className={ cn(s.icon) }
					>
						<Icon
							color={ EIconColor.white }
							hasTransparentBackground
							icon={ EIcon.search }
							size={ EIconSize.px109 }
						/>
					</div>
					<SearchInput
						inputValue={ inputValue }
						isActive={ Boolean(resultProps) }
					/>
					{
						!resultProps && (
							<div
								className={ cn(s.description) }
							>
								{t(`search|description`)}
							</div>
						)
					}
					{
						resultProps
							? (
								<div
									className={ cn(s.resultList) }
								>
									<ResultList
										resultProps={ resultProps }
									/>
								</div>
							)
							: null
					}
				</div>
			</div>
		</Overlay>
	)
}