import { EIconSize } from "../../../../components/icon/enum"
import { IContractBoxProps } from "./interface"
import { Icon } from "../../../../components/icon"
import React, { FC } from "react"
import cn from "classnames"
import s from "./contract-box.module.scss"
export const ContractBox: FC<IContractBoxProps> = ({
	icon,
	title,
	description,
	price,
	link
}) => {
	return (
		<a
			className={ cn(s.wrapper) }
			href={ link }
		>
			<div
				className={ cn(s.icon) }
			>
				<Icon
					icon={ icon }
					size={ EIconSize.px65 }
				/>
			</div>
			<div
				className={ cn(s.textWrapper) }
			>
				<div
					className={ cn(s.leftWrapper) }
				>
					<div
						className={ cn(s.bigFont, {
							[s.darkFont]: !price
						}) }
					>
						{ title }
					</div>
					<div
						className={ cn(s.smallFont, {
							[s.darkFont]: !price
						}) }
					>
						{ description.map((item) => (
							<div
								key={ item }
							>
								{item}
							</div>
						)) }
					</div>
				</div>
				<div>
					{ price && (
						<div
							className={ cn(s.rightWrapper) }
						>
							<div
								className={ cn(s.bigFont, {
									[s.darkFont]: !price
								}) }
							>
								{ price.amount } €
							</div>
							<div
								className={ cn(s.smallFont, {
									[s.darkFont]: !price
								}) }
							>
								{price.billingCycle}
							</div>
						</div>
					)}
				</div>
			</div>
		</a>
	)
}