import { IAwardProps } from "./interface"
import React, { FC } from "react"
import s from "./award.module.scss"
export const Award:FC<IAwardProps> = ({
	awardImage
}) => {
	return (
		<div
			className={ s.award }
		>
			{ awardImage }
		</div>
	)
}