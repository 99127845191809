import { IHelpPaneProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./index.module.scss"
export const DividedPane: FC<IHelpPaneProps> = ({
	children,
	firstChildTitle,
	secondChildTitle
}) => {
	return (
		<div
			className={ cn(s.dividedPane) }
		>
			<div
				className={ cn(s.wrapper) }
			>
				<div
					className={ cn(s.firstChildWrapper, s.childWrapper) }
				>
					<div
						className={ cn(s.childrenTitle) }
					>
						{firstChildTitle}
					</div>
					{children[0]}
				</div>
				<div
					className={ cn(s.separator) }
				/>
				<div
					className={ cn(s.secondChildWrapper, s.childWrapper) }
				>
					<div
						className={ cn(s.childrenTitle) }
					>
						{secondChildTitle}
					</div>
					{children[1]}
				</div>
			</div>
		</div>
	)
}