import { EIcon, EPaneColor } from "./enum"
import ActiveSvg from "../../static/degenia/icons/active.svg"
import AddFileSvg from "../../static/degenia/icons/addFile.svg"
import AlertSvg from "../../static/degenia/icons/alert.svg"
import AmbulanceSvg from "../../static/degenia/icons/ambulance.svg"
import AnimalSvg from "../../static/degenia/icons/animal.svg"
import ArrowSvg from "../../static/degenia/icons/arrow.svg"
import BikeSvg from "../../static/degenia/icons/bike.svg"
import BusinessLiabilitySvg from "../../static/degenia/icons/business-liability.svg"
import CalculatorAlertSvg from "../../static/degenia/icons/calculator-alert.svg"
import CalculatorBriefcaseSvg from "../../static/degenia/icons/calculator-briefcase.svg"
import CalculatorOfficeClipSvg from "../../static/degenia/icons/calculator-office-clip.svg"
import CalculatorSvg from "../../static/degenia/icons/calculator.svg"
import CalendarSimpleSvg from "../../static/degenia/icons/calendarSimple.svg"
import CalendarSvg from "../../static/degenia/icons/calendar.svg"
import CarSvg from "../../static/degenia/icons/car.svg"
import CatSvg from "../../static/degenia/icons/cat.svg"
import ChatSvg from "../../static/degenia/icons/chat.svg"
import CheckSvg from "../../static/degenia/icons/check.svg"
import CocktailSvg from "../../static/degenia/icons/cocktail.svg"
import CodeCubeSvg from "../../static/degenia/icons/code-cube.svg"
import CodeSvg from "../../static/degenia/icons/code.svg"
import CompassSvg from "../../static/degenia/icons/compass.svg"
import ContactSvg from "../../static/degenia/icons/contact.svg"
import CouchSvg from "../../static/degenia/icons/couch.svg"
import CourtageSvg from "../../static/degenia/icons/courtage.svg"
import CyberSvg from "../../static/degenia/icons/cyber.svg"
import DoctoralHatSvg from "../../static/degenia/icons/doctoral-hat.svg"
import DocumentDownload from "../../static/degenia/icons/documentDownload.svg"
import DocumentExport from "../../static/degenia/icons/documentExport.svg"
import DocumentLock from "../../static/degenia/icons/documentLock.svg"
import DocumentSearch from "../../static/degenia/icons/documentSearch.svg"
import DocumentStats from "../../static/degenia/icons/documentStats.svg"
import DoubleArrowSvg from "../../static/degenia/icons/double-arrow.svg"
import DownUpSvg from "../../static/degenia/icons/down-and-up.svg"
import DownloadSvg from "../../static/degenia/icons/download.svg"
import DroneSvg from "../../static/degenia/icons/drone.svg"
import EcCardSvg from "../../static/degenia/icons/ecCard.svg"
import ExcavatorSvg from "../../static/degenia/icons/excavator.svg"
import ExportSvg from "../../static/degenia/icons/export.svg"
import FilesArchiveSvg from "../../static/degenia/icons/filesArchive.svg"
import FlagSvg from "../../static/degenia/icons/flag.svg"
import FolderSvg from "../../static/degenia/icons/folder.svg"
import FourHandsSvg from "../../static/degenia/icons/four-hands.svg"
import GearSvg from "../../static/degenia/icons/gear.svg"
import HandsSvg from "../../static/degenia/icons/hands.svg"
import HouseSvg from "../../static/degenia/icons/house.svg"
import HouseWithGardenSvg from "../../static/degenia/icons/house-with-garden.svg"
import IndustrySvg from "../../static/degenia/icons/industry.svg"
import InfoSvg from "../../static/degenia/icons/info.svg"
import InfoWithoutHaloSvg from "../../static/degenia/icons/info-without-halo.svg"
import JobSvg from "../../static/degenia/icons/job.svg"
import LaptopSvg from "../../static/degenia/icons/laptop.svg"
import Legal from "../../static/degenia/icons/legal.svg"
import LegalProtectionSvg from "../../static/degenia/icons/legal-protection.svg"
import MailSvg from "../../static/degenia/icons/mail.svg"
import ManWithAccidentSvg from "../../static/degenia/icons/man-with-accident.svg"
import MountainSvg from "../../static/degenia/icons/mountain.svg"
import MovingTruckSvg from "../../static/degenia/icons/moving-truck.svg"
import NetworkSvg from "../../static/degenia/icons/network.svg"
import NewsletterSvg from "../../static/degenia/icons/newsletter.svg"
import OfficeClipSvg from "../../static/degenia/icons/office-clip.svg"
import OfficeSvg from "../../static/degenia/icons/office.svg"
import PeopleSvg from "../../static/degenia/icons/people.svg"
import PhoneSvg from "../../static/degenia/icons/phone.svg"
import PiggyBankSvg from "../../static/degenia/icons/piggy-bank.svg"
import PlusSvg from "../../static/degenia/icons/plus.svg"
import ProfileSvg from "../../static/degenia/icons/profile.svg"
import RocketSvg from "../../static/degenia/icons/rocket.svg"
import SearchSvg from "../../static/degenia/icons/search.svg"
import SepaSvg from "../../static/degenia/icons/sepa.svg"
import ServiceSvg from "../../static/degenia/icons/service.svg"
import ShieldSvg from "../../static/degenia/icons/shield.svg"
import SickAnimalSvg from "../../static/degenia/icons/sick-animal.svg"
import SignSvg from "../../static/degenia/icons/sign.svg"
import SolarPanelSvg from "../../static/degenia/icons/solar-panel.svg"
import SpeedometerSvg from "../../static/degenia/icons/speedometer.svg"
import StarFilledSvg from "../../static/degenia/icons/star-filled.svg"
import StarSvg from "../../static/degenia/icons/star.svg"
import StatistiscSvg from "../../static/degenia/icons/statistic.svg"
import StethoscopeSvg from "../../static/degenia/icons/stethoscope.svg"
import TestOnlineSvg from "../../static/degenia/icons/test-online.svg"
import TrashCanSvg from "../../static/degenia/icons/trash-can.svg"
import UmbrellaSvg from "../../static/degenia/icons/umbrella.svg"
import WaterDropSvg from "../../static/degenia/icons/waterdrop.svg"
import WineglassSvg from "../../static/degenia/icons/wineglass.svg"
import WorkerSvg from "../../static/degenia/icons/worker.svg"
/* eslint-disable-next-line complexity */
export const getIcon = (icon: EIcon): React.FC<React.SVGAttributes<SVGElement>> => {
	switch (icon) {
		case EIcon.rocket:
		default:
			return RocketSvg
		case EIcon.sepa:
			return SepaSvg
		case EIcon.animal:
			return AnimalSvg
		case EIcon.sickAnimal:
			return SickAnimalSvg
		case EIcon.calendarSimple:
			return CalendarSimpleSvg
		case EIcon.active:
			return ActiveSvg
		case EIcon.solarPanel:
			return SolarPanelSvg
		case EIcon.star:
			return StarSvg
		case EIcon.trashCan:
			return TrashCanSvg
		case EIcon.worker:
			return WorkerSvg
		case EIcon.bike:
			return BikeSvg
		case EIcon.businessLiability:
			return BusinessLiabilitySvg
		case EIcon.calculatorAlert:
			return CalculatorAlertSvg
		case EIcon.calculatorBriefcase:
			return CalculatorBriefcaseSvg
		case EIcon.calculatorOfficeClip:
			return CalculatorOfficeClipSvg
		case EIcon.code:
			return CodeSvg
		case EIcon.couch:
			return CouchSvg
		case EIcon.cyber:
			return CyberSvg
		case EIcon.export:
			return ExportSvg
		case EIcon.houseWithGarden:
			return HouseWithGardenSvg
		case EIcon.manWithAccident:
			return ManWithAccidentSvg
		case EIcon.office:
			return OfficeSvg
		case EIcon.officeClip:
			return OfficeClipSvg
		case EIcon.phone:
			return PhoneSvg
		case EIcon.piggyBank:
			return PiggyBankSvg
		case EIcon.legal:
			return Legal
		case EIcon.chat:
			return ChatSvg
		case EIcon.movingTruck:
			return MovingTruckSvg
		case EIcon.ecCard:
			return EcCardSvg
		case EIcon.compass:
			return CompassSvg
		case EIcon.alert:
			return AlertSvg
		case EIcon.people:
			return PeopleSvg
		case EIcon.statistic:
			return StatistiscSvg
		case EIcon.sign:
			return SignSvg
		case EIcon.umbrella:
			return UmbrellaSvg
		case EIcon.wineglass:
			return WineglassSvg
		case EIcon.car:
			return CarSvg
		case EIcon.drone:
			return DroneSvg
		case EIcon.mountain:
			return MountainSvg
		case EIcon.laptop:
			return LaptopSvg
		case EIcon.legalProtection:
			return LegalProtectionSvg
		case EIcon.house:
			return HouseSvg
		case EIcon.stethoscope:
			return StethoscopeSvg
		case EIcon.mail:
			return MailSvg
		case EIcon.service:
			return ServiceSvg
		case EIcon.addFile:
			return AddFileSvg
		case EIcon.cat:
			return CatSvg
		case EIcon.industry:
			return IndustrySvg
		case EIcon.downUp:
			return DownUpSvg
		case EIcon.check:
			return CheckSvg
		case EIcon.newsletter:
			return NewsletterSvg
		case EIcon.testOnline:
			return TestOnlineSvg
		case EIcon.ambulance:
			return AmbulanceSvg
		case EIcon.waterdrop:
			return WaterDropSvg
		case EIcon.excavator:
			return ExcavatorSvg
		case EIcon.search:
			return SearchSvg
		case EIcon.fourHands:
			return FourHandsSvg
		case EIcon.job:
			return JobSvg
		case EIcon.info:
			return InfoSvg
		case EIcon.infoWithoutHalo:
			return InfoWithoutHaloSvg
		case EIcon.arrow:
			return ArrowSvg
		case EIcon.calculator:
			return CalculatorSvg
		case EIcon.calendar:
			return CalendarSvg
		case EIcon.cocktail:
			return CocktailSvg
		case EIcon.codeCube:
			return CodeCubeSvg
		case EIcon.contact:
			return ContactSvg
		case EIcon.courtage:
			return CourtageSvg
		case EIcon.doctoralHat:
			return DoctoralHatSvg
		case EIcon.documentDownload:
			return DocumentDownload
		case EIcon.documentExport:
			return DocumentExport
		case EIcon.documentLock:
			return DocumentLock
		case EIcon.documentSearch:
			return DocumentSearch
		case EIcon.documentStats:
			return DocumentStats
		case EIcon.doubleArrow:
			return DoubleArrowSvg
		case EIcon.download:
			return DownloadSvg
		case EIcon.flag:
			return FlagSvg
		case EIcon.folder:
			return FolderSvg
		case EIcon.gear:
			return GearSvg
		case EIcon.hands:
			return HandsSvg
		case EIcon.network:
			return NetworkSvg
		case EIcon.plus:
			return PlusSvg
		case EIcon.profile:
			return ProfileSvg
		case EIcon.shield:
			return ShieldSvg
		case EIcon.speedometer:
			return SpeedometerSvg
		case EIcon.starFilled:
			return StarFilledSvg
		case EIcon.filesArchive:
			return FilesArchiveSvg
	}
}
export const CPaneColor = {
	[EPaneColor.blue]: "#99B1C2",
	[EPaneColor.darkgrey]: "#4A4A4A",
	[EPaneColor.greyblue]: "#c0cdd7",
	[EPaneColor.lightgrey]: "#E5E5E5",
	[EPaneColor.lightteal]: "#CDDBE6",
	[EPaneColor.transparent]: "rgba(0,0,0,0)",
	[EPaneColor.saturatedteal]: "#A5C1D6",
	[EPaneColor.white]: "#fff",
	[EPaneColor.lightblue]: "#CCD8E1"
}