import { EButtonSize } from "../../utils/enum"
import { Link, Spinner } from "@teamparallax/react-components"
import { ModalButtonWrapper } from "../modal-button-wrapper"
import { TDegeniaButtonProps } from "./type"
import ArrowSvg from "../../../static/degenia/arrow.svg"
import React, { FC } from "react"
import cn from "classnames"
import s from "./degenia-button.module.scss"
export const DegeniaButton: FC<TDegeniaButtonProps> = (props) => {
	const {
		isInverted,
		href,
		isLoading,
		onClick,
		shouldOpenInNewTab,
		modalButtonText,
		style,
		modalContent,
		size = EButtonSize.big,
		title,
		hasBlueIcon
	} = props
	/* If the Title doesnt contain a title or a link/function, hide */
	if (!title || !href && !onClick) {
		return null
	}
	if (modalContent) {
		const {
			modalContent: ignoreMe,
			...buttonProps
		} = props
		return (
			<ModalButtonWrapper
				button={ buttonProps }
				markdownContent={ modalContent }
				modalButtonText={ modalButtonText }
			/>
		)
	}
	let target = ""
	if (shouldOpenInNewTab) {
		target = "_blank"
	}
	const Button = (
		<a
			className={ cn(s.button, s.linkWrap, {
				[s.inverted]: isInverted,
				[s.buttonBig]: size === EButtonSize.big,
				[s.buttonMedium]: size === EButtonSize.medium,
				[s.buttonSmall]: size === EButtonSize.small,
				[s.buttonExtraSmall]: size === EButtonSize.extraSmall
			}) }
			role="button"
			style={ style }
			target={ target }
		>
			<div
				className={ cn(s.link) }
			>
				{title}
			</div>
			<div
				className={ cn(s.iconWrap, {
					[s.blue]: hasBlueIcon
				}) }
			>
				{ isLoading
					? (
						<span
							className={ s.loading }
						>
							<Spinner/>
						</span>
					)
					: (
						<ArrowSvg
							className={ s.icon }
						>
							{ title }
						</ArrowSvg>
					)
				}
			</div>
		</a>
	)
	return (
		<>
			{
				href
					? (
						<Link href={ href }>
							{Button}
						</Link>
					)
					: (
						<div onClick={ onClick }>
							{Button}
						</div>
					)}
		</>
	)
}