import { EIcon } from "../../../utils/enum"
import { IMailSendBecomeOurPartner } from "@teamparallax/api-gateway-rest-api"
export interface IPartnerBoxProps {
	buttonText: string
	icon: EIcon
	// onClick: (formData: IMailSendBecomeOurPartner) => void
	onSubmit: (formData: IMailSendBecomeOurPartner) => void
}
export const emptyMailSendBecomeOurPartner = (): IMailSendBecomeOurPartner => ({
	city: "",
	company: "",
	email: "",
	firstname: "",
	lastname: "",
	phone: "",
	salutation: "",
	street: "",
	zipcode: ""
})