import { IContentGenericPaneProps } from "./interface"
import { ListContainer } from "../../components/list-container"
import { TitleWrapper } from "../title"
import { isArray } from "lodash-es"
import React, { FC, ReactNode } from "react"
import cn from "classnames"
import s from "./contentGeneric.module.scss"
export const ContentGenericPane: FC<IContentGenericPaneProps> = ({
	children,
	title,
	subTitle
}) => {
	const sanitizedChildren = []
	if (isArray(children)) {
		sanitizedChildren.push(...children as ReactNode[])
	}
	else {
		sanitizedChildren.push(children)
	}
	return (
		<div
			className={ cn(s.contentPane) }
		>
			<TitleWrapper
				subTitle={ subTitle }
				title={ title }
			/>
			<div
				className={ cn(s.children) }
			>
				<div
					className={ cn(s.firstChildWrapper) }
				>
					<ListContainer>
						{ sanitizedChildren[0] }
					</ListContainer>
				</div>
				<div
					className={ cn(s.secondChildWrapper) }
				>
					{ sanitizedChildren[1] }
				</div>
			</div>
		</div>
	)
}