import { IButtonProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./button.module.scss"
export const Button: FC<IButtonProps> = ({
	className,
	children,
	type,
	onClick
}) => {
	return (
		<button
			className={ cn(s.button, className) }
			onClick={ onClick }
			// eslint-disable-next-line react/button-has-type
			type={ type }
		>
			{children}
		</button>
	)
}