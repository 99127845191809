import {
	AnchorButton, Link, useAuth
} from "@teamparallax/react-components"
import { CPaneColor } from "../../utils"
import { EIcon, EPaneColor } from "../../utils/enum"
import { EIconSize } from "../../components/icon/enum"
import { ERoleCapabilities } from "@teamparallax/common-libraries/lib/rbac/enum"
import {
	IInsuranceBoxComponent,
	IInsuranceBoxesWithComponentProps,
	IInsuranceProps,
	IInsurancePropsSsr
} from "./interface"
import { InsuranceBox } from "./insurance-box"
import { JobPane } from "../jobs"
import { Pane } from "../../layout/pane"
import { Separator } from "../separator"
import { Spacer } from "../space"
import { TitleWrapper } from "../title"
import React, {
	FC, ReactElement, useEffect, useRef, useState
} from "react"
import TriangleSvg from "../../../static/degenia/triangle.svg"
import cn from "classnames"
import s from "./insurance.module.scss"
export const InsurancePane: FC<IInsuranceProps> = ({
	title,
	subtitle,
	insuranceBoxesProps,
	newHref,
	paneColor = EPaneColor.lightteal,
	openInsuranceId,
	isBoxExpanding = true
}) => {
	const childRef = useRef<HTMLDivElement>(null)
	const scrollToView = (): void => {
		setTimeout(() => {
			childRef?.current?.scrollIntoView({
				behavior: "smooth"
			})
		})
	}
	useEffect(() => {
		if (openInsuranceId) {
			let secondLevelComponent
			let secondLevelTitle
			for (const props of insuranceBoxesProps) {
				if (props.title.toLowerCase() === openInsuranceId.toLowerCase()) {
					secondLevelComponent = props.component
					secondLevelTitle = props.title
				}
			}
			setNextLevel(secondLevelComponent, secondLevelTitle)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [insuranceBoxesProps, openInsuranceId])
	const setNextLevel = (
		newComponent?: ReactElement,
		newTitle?: string
	): void => {
		setCurrentComponent(newComponent)
		setCurrentTitle(newTitle)
		if (newComponent) {
			scrollToView()
		}
	}
	const [currentTitle, setCurrentTitle] = useState<string | undefined>(undefined)
	const insuranceBoxIconSize = currentTitle
		? EIconSize.px74
		: EIconSize.px109
	const [
		currentComponent,
		setCurrentComponent
	] = useState<ReactElement | undefined>(undefined)
	const {
		checkAllowed
	} = useAuth()
	const [isEditMode, setEditMode] = useState<boolean>(false)
	useEffect(() => {
		void (async (): Promise<void> => {
			const isAllowed = await checkAllowed(ERoleCapabilities.frontEndEditHomePage)
			setEditMode(isAllowed)
		})()
	}, [checkAllowed])
	return (
		<div>
			<Pane
				paneColor={ paneColor }
			>
				<div
					className={ cn(s.insurancePane, {
						[s.active]: currentTitle
					}) }
				>
					{ !currentTitle && (
						<TitleWrapper
							isCentered
							isInverted
							subTitle={ subtitle }
							title={ title }
						/>
					)}
					<div
						className={ cn(s.insuranceBoxesWrap) }
					>
						{ insuranceBoxesProps.map((insuranceBox) => (
							<div
								key={ insuranceBox.title }
								onClick={ ():void => {
									if (isBoxExpanding) {
										if (currentTitle === insuranceBox.title) {
											setNextLevel(undefined, undefined)
										}
										else {
											setNextLevel(insuranceBox.component, insuranceBox.title)
										}
									}
									else {
										// open link to new page
									}
								} }
								role="button"
							>
								<InsuranceBox
									icon={ insuranceBox.icon }
									isDark={ currentTitle === insuranceBox.title }
									isWide={ insuranceBox.isWide }
									key={ insuranceBox.title }
									size={ insuranceBoxIconSize }
									title={ insuranceBox.title }
								/>
							</div>
						)) }
						{/* @TODO change icon to "Add" */}
						{newHref && isEditMode && (
							<AnchorButton>
								<Link href={ newHref }>
									<InsuranceBox
										icon={ EIcon.sign }
										size={ insuranceBoxIconSize }
										title="Neu"
									/>
								</Link>
							</AnchorButton>
						)}
					</div>
					<div
						className={ s.triangle }
						style={ {
							color: `${CPaneColor[paneColor]}`
						} }
					>
						<TriangleSvg/>
					</div>
				</div>
			</Pane>
			<div
				ref={ childRef }
			>
				{ currentComponent }
			</div>
		</div>
	)
}
export const InsurancePaneSsr:FC<IInsurancePropsSsr> = (props) => {
	const insuranceBoxesProps: IInsuranceBoxesWithComponentProps[] = []
	for (const prop of props.insuranceBoxesProps) {
		insuranceBoxesProps.push({
			...prop,
			component: !prop.components.hasOwnProperty("length")
				? (
					<InsurancePaneSsr
						{ ...prop.components as IInsurancePropsSsr }
					/>
				)
				: (
					<>
						<Pane
							hasNoPadding
						>
							<Spacer heightInRem={ 8.875 }/>
							<JobPane
								jobBoxProps={ prop.components as IInsuranceBoxComponent[] }
								subTitle="Bitte wählen Sie eine Versicherung"
								title="Unsere Angebote für sie"
							/>
							<Spacer heightInRem={ 8.875 }/>
							<Separator/>
						</Pane>
					</>
				)
		})
	}
	return (
		<InsurancePane
			{ ...props }
			insuranceBoxesProps={ insuranceBoxesProps }
		/>
	)
}