import { DegeniaGenericForm } from "../../../components/degenia-generic-form"
import {
	EDegeniaLegacyDocumentCategory,
	EDegeniaLegacySalutation,
	IDegeniaLegacySoapDocument
} from "@teamparallax/api-gateway-degenia-rest-api"
import { EInputType } from "../../../components/degenia-input-field/enum"
import { IPortfolioCessionForm, IPortfolioCessionFormProps } from "./interface"
import { fileToBuffer } from "@teamparallax/common-libraries/lib/file"
import React, { FC } from "react"
export const PortfolioCessionForm: FC<IPortfolioCessionFormProps> = ({
	submitCallback
}) => {
	return (
		<DegeniaGenericForm
			fields={ [
				{
					isRequired: true,
					name: "salutation",
					selectOptions: [
						{
							label: "Weiblich",
							value: EDegeniaLegacySalutation._0
						},
						{
							label: "Männlich",
							value: EDegeniaLegacySalutation._1
						}
					],
					title: "Anrede",
					type: EInputType.select
				},
				{
					name: "title",
					title: "Titel",
					type: EInputType.text
				},
				{
					isRequired: true,
					name: "firstName",
					title: "Vorname",
					type: EInputType.text
				},
				{
					isRequired: true,
					name: "surname",
					title: "Nachname",
					type: EInputType.text
				},
				{
					isRequired: true,
					name: "birthdate",
					title: "Geburtsdatum",
					type: EInputType.date
				},
				{
					isRequired: true,
					name: "street",
					title: "Straße",
					type: EInputType.text
				},
				{
					isRequired: true,
					name: "zipCode",
					title: "PLZ",
					type: EInputType.text
				},
				{
					isRequired: true,
					name: "location",
					title: "Ort",
					type: EInputType.text
				},
				{
					isRequired: true,
					name: "companyCode",
					/* @TODO load all possible companies */
					selectOptions: [
						{
							label: "DMU",
							value: "dmu"
						},
						{
							label: "degenia",
							value: "degenia"
						}
					],
					title: "Gesellschaft",
					type: EInputType.select
				},
				{
					name: "paymentMethod",
					radioProps: [{
						radioButtonContent: "jährlich",
						shouldDefaultChecked: true,
						value: "J"
					}, {
						radioButtonContent: "halbjährlich",
						value: "HJ"
					}, {
						radioButtonContent: "vierteljährlich",
						value: "VJ"
					}, {
						radioButtonContent: "monatlich",
						value: "M"
					}],
					title: "Zahlungsweise",
					type: EInputType.radiobutton
				},
				{
					isRequired: true,
					name: "netContribution",
					title: "Nettobetrag gem. Zahlweise",
					type: EInputType.currency
				},
				{
					isRequired: true,
					name: "startDate",
					title: "Beginn des Vertrages",
					type: EInputType.date
				},
				{
					name: "cycleDate",
					title: "Hauptfälligkeit",
					type: EInputType.date
				},
				{
					isRequired: true,
					name: "document0",
					title: "Dokumente hochladen (max. 2 MB)",
					type: EInputType.file
				},
				{
					name: "document1",
					title: "Weiteres Dokument",
					type: EInputType.file
				},
				{
					name: "document2",
					title: "Weiteres Dokument",
					type: EInputType.file
				},
				{
					name: "note",
					title: "Bemerkung",
					type: EInputType.textarea
				}
			] }
			submitButtonTitle="DMU Bestandsübertragung"
			submitCallback={ async (values: IPortfolioCessionForm): Promise<void> => {
				const documents: IDegeniaLegacySoapDocument[] = []
				const {
					document0,
					document1,
					document2,
					...cessionForm
				} = values
				const rawDocuments = [document0, document1, document2]
				for (const [rawDocument] of rawDocuments) {
					if (rawDocument) {
						// eslint-disable-next-line no-await-in-loop
						const buffer = await fileToBuffer(rawDocument)
						documents.push({
							buffer: buffer.toString(),
							category: EDegeniaLegacyDocumentCategory.Maklervollmacht,
							filePath: rawDocument.name
						})
					}
				}
				submitCallback({
					...cessionForm,
					documents
				})
			} }
		/>
	)
}