import { DashboardLink } from "../dashboard-link"
import { IDashboardTitleProps } from "./interface"
import React, { FC } from "react"
import cn from "classnames"
import s from "./dashboard-title.module.scss"
export const DashboardTitle: FC<IDashboardTitleProps> = ({
	className,
	children,
	titleUrl
}) => {
	const isLink = titleUrl?.length
	const title = (
		<h3
			className={ cn(
				s.dashboardTitle,
				{
					[s.dashboardTitleLink]: isLink
				},
				className
			) }
		>
			{ children }
		</h3>
	)
	const linkWrapper = (
		<DashboardLink
			href={ titleUrl as string }
		>
			{ title }
		</DashboardLink>
	)
	return isLink
		? linkWrapper
		: title
}