import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { IBreadCrumbsProps } from "./interface"
import { Link } from "@teamparallax/react-components"
import { useTranslation } from "react-i18next"
import React, { FC } from "react"
import s from "./index.module.scss"
export const Breadcrumbs:FC<IBreadCrumbsProps> = ({
	breadcrumbs,
	children
}) => {
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	return (
		<nav
			className={ s.breadcrumb }
		>
			<strong>Sie sind hier:</strong>
			<ol>
				<li>
					<Link href="/">Home</Link>
				</li>
				{breadcrumbs
					.filter((breadcrumb) => breadcrumb.breadcrumb)
					.map((breadcrumb) => {
						let translation = t(`breadcrumbs|${breadcrumb.breadcrumb.toLowerCase()}`)
						if (
							translation.startsWith("[NO_TRANSLATION")
							|| translation.startsWith("breadcrumbs|")
						) {
							translation = breadcrumb.breadcrumb
						}
						return (
							<li key={ breadcrumb.href }>
								<Link href={ breadcrumb.href }>
									{ translation }
								</Link>
							</li>
						)
					})}
			</ol>
			<div
				className={ s.child }
			>
				{children}
			</div>
		</nav>
	)
}