import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { IAttentionBox } from "./interface"
import { useTranslation } from "react-i18next"
import React, { FC } from "react"
import cn from "classnames"
import s from "./attention-box.module.scss"
export const AttentionBox: FC<IAttentionBox> = ({
	title,
	style,
	children
}) => {
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	return (
		<div
			className={ cn(s.wrapper) }
			style={ {
				...style
			} }
		>
			<div
				className={ cn(s.banner) }
			>
				{t(`attention`)}
			</div>
			<div
				className={ cn(s.content) }
			>
				<div
					className={ cn(s.title) }
				>
					{title}
				</div>
				{children}
			</div>
		</div>
	)
}