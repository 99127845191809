import { EIconColor } from "../.."
import { EIconSize } from "./enum"
import { IIconProps } from "./interface"
import { getIcon } from "../../utils"
import React, { FC } from "react"
import cn from "classnames"
import s from "./icon.module.scss"
export const Icon: FC<IIconProps> = ({
	icon,
	alt,
	size = EIconSize.px139,
	hasTransparentBackground,
	backgroundColor = EIconColor.white,
	color,
	borderColor
}) => {
	const SvgIcon = getIcon(icon)
	const styleSize = s[`size${ size }px`]
	return (
		<div
			className={ cn(s.iconWrap, styleSize, {
				[s[`background-${backgroundColor}`]]: Boolean(backgroundColor),
				[s[`border-${borderColor}`]]: Boolean(borderColor),
				[s[`${color}`]]: Boolean(color),
				[s.transparent]: hasTransparentBackground
			}) }
		>
			<SvgIcon
				className={ s.icon }
			>
				{ alt }
			</SvgIcon>
		</div>
	)
}