import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { EVariant, Text } from "@teamparallax/react-components"
import { FieldErrors, useFormContext } from "react-hook-form"
import { IInputFieldProps } from "./interface"
import { useTranslation } from "react-i18next"
import React, { FC } from "react"
import cn from "classnames"
import s from "./input.module.scss"
export const InputField: FC<IInputFieldProps> = (props) => {
	const {
		register,
		getValues,
		errors
	} = useFormContext()
	const {
		isRequired,
		name,
		autocomplete,
		type,
		hint,
		...rest
	} = props
	const {
		t
	} = useTranslation(ENameSpace.common)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getErrorMessage = (errors: FieldErrors<Record<string, any>>): string => {
		const error = errors?.[name]
		if (error?.type === "required") {
			return t(`required`)
		}
		else {
			return error?.message
		}
	}
	const errorMessage = getErrorMessage(errors)
	return (
		<div
			className={ s.wrapper }
		>
			<input
				autoComplete={ autocomplete }
				className={ cn(s.input, {
					[s.error]: errorMessage
				}) }
				defaultValue={ getValues(name) }
				name={ name }
				ref={ register({
					// eslint-disable-next-line @typescript-eslint/naming-convention
					required: isRequired
				}) }
				type={ type }
				{ ...rest }
			/>
			{ errorMessage && (
				<Text
					size="small"
					style={ {
						marginTop: "-5px",
						textAlign: "center"
					} }
					text={ errorMessage }
					variant={ EVariant.danger }
				/>
			)}
			<p
				className={ s.hint }
			>
				{ hint }
			</p>
		</div>
	)
}