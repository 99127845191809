import { getIdFromString, getMarkdownContentFromProps } from "../../markdown"
import React, { FC, ReactElement } from "react"
import cn from "classnames"
import s from "./h3.module.scss"
interface IProps {
	id?: string
	shouldInheritColor?: boolean
	shouldTransformUppercase?: boolean
	text: string|ReactElement
}
export const Header3: FC<IProps> = ({
	id,
	shouldInheritColor,
	shouldTransformUppercase,
	text
}) => {
	const parsedId = id ?? getIdFromString(getMarkdownContentFromProps(text))
	return (
		<h3
			className={ cn(
				s.h3,
				{
					[s.inheritColor]: shouldInheritColor,
					[s.transformUppercase]: shouldTransformUppercase
				}
			) }
			id={ parsedId }
		>
			{text}
		</h3>
	)
}