import { CommunicationViewContainer } from "./communication-view/communication-view-container"
import { CommunicationViewHeader } from "./communication-view/communication-view-header"
import { ECommunicationViewType } from "./communication-view/enum"
import { ICommunicationProps } from "./interface"
import React, { FC, useState } from "react"
import cn from "classnames"
import s from "./communication.module.scss"
export const CommunicationPane: FC<ICommunicationProps> = ({
	inboxMessageList,
	hasWrapperBox = true,
	outboxMessageList
}) => {
	const [
		currentView,
		setCurrentView
	] = useState<ECommunicationViewType>(ECommunicationViewType.inboxView)
	const communicationView = {
		currentView,
		inboxMessageList,
		outboxMessageList
	}
	return (
		<section
			className={ cn({
				[s.communicationPane]: hasWrapperBox
			}) }
		>
			<section
				className={ s.communicationPaneView }
			>
				<CommunicationViewHeader
					currentView={ currentView }
					onClick={ setCurrentView }
				/>
				<CommunicationViewContainer { ...communicationView }/>
			</section>
		</section>
	)
}