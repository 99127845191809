import {
	IPageQuickAccessProps,
	ISubPage
} from "./interface"
import { usePageQuickAccess } from "@teamparallax/react-components"
import React, {
	FC,
	ReactElement
} from "react"
import cn from "classnames"
import styles from "./page-quick-access.module.scss"
export const PageQuickAccess: FC<IPageQuickAccessProps> = ({
	subpages
}) => {
	const {
		activePage,
		setActivePage
	} = usePageQuickAccess()
	const createQuickAccessEntryForSubpage = ({
		pageName
	}: ISubPage): ReactElement => {
		const isActivePage = activePage === pageName
		return (
			<div
				className={
					cn(styles.quickAccessEntry, {
						[styles.active]: isActivePage
					})
				}
				onClick={
					(): void => setActivePage(pageName)
				}
			>
				{ pageName }
			</div>
		)
	}
	const renderPageQuickAccess = subpages.map(
		(page: ISubPage) => createQuickAccessEntryForSubpage(page)
	)
	return (
		<div className={ styles.pageQuickAccessWrapper }>
			{ renderPageQuickAccess }
		</div>
	)
}