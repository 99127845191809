import { AwardsPane } from "../../panes/awards"
import { Breadcrumbs } from "../../panes/breadcrumb"
import { DegeniaLogo, DmuLogo } from "../../components/svg"
import { EMenuClickHandler, EMenuOpenState } from "./enum"
import { EPaneColor } from "../../utils/enum"
import { Footer } from "../footer"
import {
	GenericTextsProvider,
	Meta,
	useApiGatewayRest, useAppConfig, useAuth
} from "@teamparallax/react-components"
import { Header } from "../header"
import { ILayoutProps } from "./interface"
import { ILoginFormData } from "../../components/login/interface"
import { Login } from "../../components/login"
import { NavigationExtended } from "../../components/navigation-extended"
import { Pane } from "../pane"
import { Search } from "../../components/search"
import { Separator } from "../../panes/separator"
import { SideMenu } from "../../components/side-menu"
import { TeamMemberProvider } from "../../provider/team-member"
import { parseStrapiImageUrl } from "../../utils/strapi"
import { useBreadcrumbs } from "@teamparallax/client-libraries/lib/hooks/use-breadcrumbs"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import React, {
	FC, ReactElement, useCallback, useEffect, useState
} from "react"
import s from "./app.module.scss"
export const LayoutWrapper: FC = ({
	children
}) => (<div className={ s.layout }>{children}</div>)
export const Layout:FC<ILayoutProps> = ({
	hasNoSeparator,
	imageUrl,
	teamMembers,
	genericTexts,
	sideMenuProps,
	meta,
	headerAwards = [],
	awards,
	headerContent,
	hasDmu,
	contactProps,
	extraLinkProps,
	linkListProps,
	hasNoBreadcrumbs,
	socialMediaProps,
	footerListProps,
	children
}) => {
	const {
		t
	} = useTranslation()
	const breadcrumbs = useBreadcrumbs()
	const [menuState, setMenuState] = useState<EMenuOpenState>(EMenuOpenState.closed)
	const clickHandler = useCallback((menuState: EMenuOpenState) => {
		return (): void => {
			setMenuState(menuState)
		}
	}, [])
	const {
		strapiUrl
	} = useAppConfig()
	const router = useRouter()
	const {
		userApi
	} = useApiGatewayRest()
	const navigationClickHandler = {
		onClickClose: clickHandler(EMenuOpenState.closed),
		onClickForCustomers: async (): Promise<void> => {
			await router.push(`/produkte`)
		},
		onClickLogin: clickHandler(EMenuOpenState.login),
		onClickNavigation: clickHandler(EMenuOpenState.navigation),
		onClickSearch: clickHandler(EMenuOpenState.search)
	}
	/* Should close the menu if the path changes */
	useEffect(() => {
		if (router?.query?.open) {
			const open = router.query.open as string
			if (Object.keys(EMenuOpenState).includes(open)) {
				clickHandler(open as EMenuOpenState)()
			}
		}
		else {
			clickHandler(EMenuOpenState.closed)()
		}
	}, [clickHandler, router?.asPath, router?.query.open])
	const {
		login
	} = useAuth()
	const onLogin = async (credentials: ILoginFormData): Promise<string|ReactElement|undefined> => {
		const responseLogin = await login(credentials)
		if (!responseLogin.isSuccess) {
			return responseLogin.message
		}
		return undefined
	}
	return (
		<TeamMemberProvider
			teamMembers={ teamMembers }
		>
			<GenericTextsProvider genericTexts={ genericTexts }>
				<LayoutWrapper>
					{ menuState === EMenuOpenState.navigation && (
						<NavigationExtended
							extraLinkProps={ [
								{
									extendedNavigation: EMenuClickHandler.onClickSearch,
									name: "Suche"
								},
								{
									extendedNavigation: EMenuClickHandler.onClickLogin,
									name: "Login"
								},
								{
									extendedNavigation: EMenuClickHandler.onClickForCustomers,
									name: "Für Kunden"
								},
								...extraLinkProps ?? []
							] }
							hasDmu={ hasDmu }
							linkListProps={ linkListProps }
							navigationClickHandler={ navigationClickHandler }
						/>
					) }
					{ menuState === EMenuOpenState.login && (
						<Login
							hasDmu={ hasDmu }
							navigationClickHandler={ navigationClickHandler }
							onLogin={ onLogin }
						/>
					) }
					{ menuState === EMenuOpenState.search && (
						<Search
							hasDmu={ hasDmu }
							navigationClickHandler={ navigationClickHandler }
						/>
					) }
					<>
						<Meta
							{ ...meta }
							faviconUrl="/favicon.svg"
						/>
						<Header
							awards={ headerAwards }
							hasDmu={ hasDmu }
							imageUrl={ imageUrl }
							navigationClickHandler={ navigationClickHandler }
						>
							{headerContent}
						</Header>
						{ sideMenuProps && (
							<Pane
								hasNoPadding
							>
								<SideMenu
									scrollHeight={ sideMenuProps.scrollHeight }
									sideMenuBoxProps={ sideMenuProps.sideMenuBoxProps }
								/>
							</Pane>
						) }
						{ !hasNoBreadcrumbs && (
							<Pane
								hasNoPadding
							>
								<Breadcrumbs
									breadcrumbs={ breadcrumbs }
								/>
								{ !hasNoSeparator && <Separator/> }
							</Pane>
						) }
						{ children }
						<Pane
							hasNoPadding
							paneColor={ EPaneColor.lightgrey }
						>
							<AwardsPane
								awardsList={ [
									...awards.map((award) => ({
										image: (
											<img
												alt={ award.title }
												height={ 138 }
												key={ award.title ?? Math.random() }
												src={ parseStrapiImageUrl(strapiUrl, award.image) }
												width={ 161 }
											/>
										),
										title: award.title
									}))
								] }
								title="degenia-Konzepte wurden mehrfach ausgezeichnet"
							/>
						</Pane>
						<Pane
							hasNoPadding
							paneColor={ EPaneColor.darkgrey }
						>
							<Footer
								contactProps={ contactProps }
								footerListProps={ footerListProps }
								hasDmu={ hasDmu }
								logoMap={ {
									degenia: <DegeniaLogo/>,
									dmu: <DmuLogo/>
								} }
								socialMediaProps={ socialMediaProps }
							/>
						</Pane>
					</>
				</LayoutWrapper>
			</GenericTextsProvider>
		</TeamMemberProvider>
	)
}