import { DynamicLoader, TLazyImport } from "@teamparallax/react-components"
import { ICustomerBackendLayoutProps } from "./interface"
import React, { FC } from "react"
export const CustomerBackendLayout:FC<ICustomerBackendLayoutProps> = (props) => {
	return (
		<DynamicLoader<ICustomerBackendLayoutProps>
			importPromise={ (async () => import("./customer-backend-layout")) as TLazyImport }
			props={ props }
		/>
	)
}