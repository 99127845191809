import { DashboardLinkList } from "../dashboard-link-list"
import { DashboardSection } from "../dashboard-section"
import { IDashboardCommissionProps } from "./interface"
import { prettifyDate } from "../../../utils/date"
import React, { FC } from "react"
import s from "./dashboard-commission.module.scss"
export const DashboardCommission: FC<IDashboardCommissionProps> = ({
	links,
	nextUpdate
}) => {
	return (
		<DashboardSection
			className={ s.dashboardCommission }
			title="Courtage"
		>
			<DashboardLinkList
				links={ links }
			/>
			<span
				className={ s.dashboardCommissionUpdate }
			>
				Nächste Abrechnung am:
				<br/>
				<strong>
					{ prettifyDate(nextUpdate, {
						day: "numeric",
						month: "long",
						weekday: "long",
						year: "numeric"
					}) }
				</strong>
			</span>
		</DashboardSection>
	)
}