/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	CellProps, HeaderProps, Hooks, useRowSelect, useSortBy, useTable
} from "react-table"
import { DegeniaCheckbox } from "../checkbox"
import { ITableColumn, ITableProps } from "./interface"
import { TData } from "./type"
import ArrowTable from "../../../static/degenia/arrow-table.svg"
import React, { PropsWithChildren, ReactElement } from "react"
import cn from "classnames"
import s from "./table-component.module.scss"
export const TableComponent = <T extends TData = TData>({
	data,
	columns,
	onRowClick,
	isSelectable
}: PropsWithChildren<ITableProps<T>>): ReactElement => {
	const selectionHook = (hooks: Hooks<any>): void => {
		if (isSelectable) {
			hooks.allColumns.push((columns) => [
				{
					Cell: ({
						row
					}: CellProps<any>): ReactElement => {
						return <DegeniaCheckbox { ...row.getToggleRowSelectedProps() }/>
					},
					Header: ({
						getToggleAllRowsSelectedProps
					}: HeaderProps<any>): ReactElement => (
						<DegeniaCheckbox { ...getToggleAllRowsSelectedProps() }/>
					),
					id: "selection"
				},
				...columns
			])
			hooks.useInstanceBeforeDimensions.push(({
				headerGroups
			}) => {
				const selectionGroupHeader = headerGroups[0].headers[0]
				selectionGroupHeader.canResize = false
			})
		}
	}
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable(
		{
			columns,
			data,
			initialState: {
				sortBy: [{
					id: columns[0].accessor as string
				}]
			}
		},
		useSortBy,
		useRowSelect,
		selectionHook
	)
	return (
		<div
			className={ s.tableContainer }
		>
			<table
				className={ s.tableComponent }
				{ ...getTableProps() }
			>
				<thead>
					{headerGroups.map((headerGroup) => (
					// eslint-disable-next-line react/jsx-key
						<tr { ...headerGroup.getHeaderGroupProps() }>
							{headerGroup.headers.map((c) => {
								const column = (c as unknown) as ITableColumn<TData>
								return (
								// eslint-disable-next-line react/jsx-key
									<th
										{ ...column.getHeaderProps(column.getSortByToggleProps()) }
									>
										<div className={ s.tableComponentHeaderColumn }>
											{column.render("Header")}
											<span className={ s.icon }>
												<ArrowTable className={ cn({
													[s.reversed]: column.isSortedDesc,
													[s.hidden]: !column.isSorted
												}) }
												/>
											</span>
										</div>
									</th>
								)
							})}
						</tr>
					))}
				</thead>
				<tbody { ...getTableBodyProps() }>
					{rows.map((row, i) => {
						prepareRow(row)
						return (
						// eslint-disable-next-line react/jsx-key
							<tr
								{ ...row.getRowProps() }
								className={ cn({
									[s.onClick]: Boolean(onRowClick)
								}) }
								onClick={ (): void => {
									onRowClick?.(row.original)
								} }
							>
								{row.cells.map((cell) => (
								// eslint-disable-next-line react/jsx-key
									<td { ...cell.getCellProps() }>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}