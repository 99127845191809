export enum EDepartment {
	accounting = "accounting",
	advisor = "advisor",
	archiving = "archiving",
	assistance = "assistance",
	backofficeHrWg = "backofficeHrWg",
	// eslint-disable-next-line @typescript-eslint/naming-convention
	backofficeShuKRs = "backofficeShuKRs",
	business = "business",
	insurance = "insurance",
	it = "it",
	management = "management",
	marketing = "marketing",
	officeManagement = "officeManagement",
	productManagement = "productManagement",
	support = "support",
	trainee = "trainee"
}