import { DashboardLinkList } from "../dashboard-link-list"
import { DashboardSection } from "../dashboard-section"
import { IDashboardCompaniesProps } from "./interface"
import React, { FC } from "react"
import s from "./dashboard-companies.module.scss"
export const DashboardCompanies: FC<IDashboardCompaniesProps> = ({
	companies,
	links
}) => {
	return (
		<DashboardSection
			className={ s.dashboardCompanies }
			shouldRenderChildrenInline
			title="Gesellschaften & Produkte"
			titleUrl="/portal/gesellschaften-und-produkte/gesellschaften"
		>
			<DashboardSection
				title="Neue Gesellschaften:"
			>
				<DashboardLinkList
					hasNoIconLinks
					links={ companies }
				/>
			</DashboardSection>
			<DashboardSection
				title="Neue Produkte:"
			>
				<DashboardLinkList
					links={ links }
				/>
			</DashboardSection>
		</DashboardSection>
	)
}