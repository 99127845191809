import { EUserRole } from "@teamparallax/common-libraries/lib/rbac/enum"
import {
	FC, createContext, useContext, useEffect, useState
} from "react"
import { PickIIdisBrokerExcludeKeyofIIdisBrokerOriginalData as IApiBroker } from "@teamparallax/api-gateway-degenia-rest-api"
import { useApiGatewayDegeniaRest } from "../../../provider/api-gateway-degenia-rest"
import { useAuth } from "@teamparallax/react-components"
import React from "react"
interface IBrokerContext {
	broker?: IApiBroker
}
const BrokerContext = createContext<IBrokerContext|undefined>(undefined)
export const BrokerProvider: FC = ({
	children
}) => {
	const [broker, setBroker] = useState<IApiBroker|undefined>(undefined)
	const {
		idisApi
	} = useApiGatewayDegeniaRest()
	const {
		profile
	} = useAuth()
	useEffect(() => {
		if (profile) {
			const brokerRole = profile.roles
				.find((role) => role.roleName === EUserRole.degeniaBroker)
			if (typeof brokerRole?.value === "string") {
				const brokerId = brokerRole.value
				void (async (): Promise<void> => {
					const {
						data: brokerTmp
					} = await idisApi.getBrokerById(brokerId)
					setBroker(brokerTmp)
				})()
			}
		}
	}, [idisApi, profile])
	return (
		<BrokerContext.Provider
			value={ {
				broker
			} }
		>
			{ children }
		</BrokerContext.Provider>
	)
}
export const useBroker = ():IBrokerContext => {
	const context = useContext(BrokerContext)
	if (context === undefined) {
		throw new Error("useBroker must be used within a BrokerContextProvider")
	}
	return context
}