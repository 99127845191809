import { EDepartment } from "../../components/employee/enum"
import { ICmsDegeniaContentTypeTeamMember } from "@teamparallax/api-gateway-rest-api"
import { IEmployeeProps } from "../../components/employee/interface"
import { parseStrapiImageUrl } from "../../utils/strapi"
import { useAppConfig } from "@teamparallax/react-components"
import React, {
	FC, createContext, useEffect, useMemo, useState
} from "react"
type TTeamMemberMap = {
	[key in EDepartment]?: IEmployeeProps[]
}
interface ITeamMemberContext {
	teamMemberMap: TTeamMemberMap
	teamMembers: IEmployeeProps[]
}
const TeamMemberContext = createContext<ITeamMemberContext|undefined>(undefined)
interface ITeamMemberProviderProps {
	teamMembers: ICmsDegeniaContentTypeTeamMember[]
}
export const TeamMemberProvider: FC<ITeamMemberProviderProps> = ({
	children,
	teamMembers
}) => {
	const [teamMemberMap, setTeamMemberMap] = useState<TTeamMemberMap>({})
	const {
		strapiUrl
	} = useAppConfig()
	const sanitizedTeamMembers = useMemo(() => {
		return teamMembers.map((teamMember) => ({
			department: teamMember.department as EDepartment,
			email: teamMember.email,
			fax: teamMember.fax,
			imageUrl: parseStrapiImageUrl(strapiUrl, teamMember.imageUrl) ?? "",
			name: teamMember.name,
			phone: teamMember.phone,
			priority: teamMember.priority ?? Infinity,
			resort: teamMember.division ?? "",
			role: teamMember.role,
			vCardUrl: teamMember.vcardUrl
		}))
	}, [teamMembers, strapiUrl])
	useEffect(() => {
		const teamMemberMapTmp: Partial<TTeamMemberMap> = {}
		for (const teamMember of sanitizedTeamMembers) {
			const department: EDepartment = teamMember.department as EDepartment
			teamMemberMapTmp[department] = teamMemberMapTmp[department] ?? []
			teamMemberMapTmp[department]?.push(teamMember)
		}
		setTeamMemberMap(teamMemberMapTmp)
	}, [sanitizedTeamMembers])
	return (
		<TeamMemberContext.Provider
			value={ {
				teamMemberMap,
				teamMembers: sanitizedTeamMembers
			} }
		>
			{ children }
		</TeamMemberContext.Provider>
	)
}
export const useTeamMember = ():ITeamMemberContext => {
	const context = React.useContext(TeamMemberContext)
	if (context === undefined) {
		throw new Error("useTeamMember must be used within a TeamMemberProvider")
	}
	return context
}