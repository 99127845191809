import { DegeniaButton } from "../../../components/degenia-button"
import { DividedBoxGeneric } from "../generic"
import { EButtonSize } from "../../../utils/enum"
import { IMailSendBecomeOurPartner } from "@teamparallax/api-gateway-rest-api"
import {
	IPartnerBoxProps,
	emptyMailSendBecomeOurPartner
} from "./interface"
import React, {
	ChangeEvent,
	FC,
	useEffect,
	useState
} from "react"
import cn from "classnames"
import s from "./index.module.scss"
export const DividedBoxPartner: FC<IPartnerBoxProps> = ({
	buttonText,
	icon,
	onSubmit
}) => {
	const [formData, setFormData] = useState<IMailSendBecomeOurPartner>(
		emptyMailSendBecomeOurPartner()
	)
	const [isValid, setIsValid] = useState<boolean>(false)
	const updateForm = (event: ChangeEvent<HTMLInputElement>): void => {
		const key = event.target.name as keyof IMailSendBecomeOurPartner
		const val = event.target.value
		setFormData({
			...formData,
			[key]: val
		})
	}
	useEffect(() => {
		/* TODO : (proper) validation of inputs */
		const hasValidName = formData.salutation !== "" && formData.firstname !== "" && formData.lastname !== ""
		const hasValidAddress = formData.city !== "" && formData.street !== ""
			&& formData.zipcode !== "" && formData.company !== ""
		const hasValidContactDetails = formData.email !== "" && formData.phone !== ""
		setIsValid(hasValidName && hasValidContactDetails && hasValidAddress)
	}, [formData])
	return (
		<DividedBoxGeneric
			icon={ icon }
		>
			<div
				className={ cn(s.inputBox) }
			>
				<input
					className={ cn(s.input, s.a) }
					name="salutation"
					onChange={ updateForm }
					placeholder="Anrede*"
					type="text"
				/>
				<input
					className={ cn(s.input, s.b) }
					name="firstname"
					onChange={ updateForm }
					placeholder="Vorname*"
					type="text"
					value={ formData.firstname }
				/>
				<input
					className={ cn(s.input, s.c) }
					name="lastname"
					onChange={ updateForm }
					placeholder="Nachname*"
					type="text"
					value={ formData.lastname }
				/>
				<input
					className={ cn(s.input, s.d) }
					name="company"
					onChange={ updateForm }
					placeholder="Firma*"
					type="text"
					value={ formData.company }
				/>
				<input
					className={ cn(s.input, s.g) }
					name="zipcode"
					onChange={ updateForm }
					placeholder="PLZ*"
					type="text"
					value={ formData.zipcode }
				/>
				<input
					className={ cn(s.input, s.h) }
					name="city"
					onChange={ updateForm }
					placeholder="Ort*"
					type="text"
					value={ formData.city }
				/>
				<input
					className={ cn(s.input, s.i) }
					name="street"
					onChange={ updateForm }
					placeholder="Straße*"
					type="text"
					value={ formData.street }
				/>
				<input
					className={ cn(s.input, s.e) }
					name="phone"
					onChange={ updateForm }
					placeholder="Telefon*"
					type="text"
					value={ formData.phone }
				/>
				<input
					className={ cn(s.input, s.f) }
					name="email"
					onChange={ updateForm }
					placeholder="Email*"
					type="text"
					value={ formData.email }
				/>
			</div>
			<div
				className={ cn(s.button) }
			>
				<DegeniaButton
					hasBlueIcon={ !isValid }
					isInverted
					onClick={ (): void => {
						if (isValid) {
							onSubmit(formData)
						}
					} }
					size={ EButtonSize.extraSmall }
					title={ buttonText }
				/>
			</div>
		</DividedBoxGeneric>
	)
}