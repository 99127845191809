import { AnchorButton } from "@teamparallax/react-components"
import { DegeniaButton } from "../../../components/degenia-button"
import { DetailsList } from "../details-list"
import { EButtonSize } from "../../.."
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { IDetailsProps } from "../details-list/interface"
import { IDetailsWrapperCardProps } from "./interface"
import { TitleWrapper } from "../../title"
import { useTranslation } from "react-i18next"
import React, {
	FC, ReactElement, useState
} from "react"
import cn from "classnames"
import s from "./wrapper-card.module.scss"
export const DetailsWrapperCard: FC<IDetailsWrapperCardProps> = ({
	cardTitle,
	btnTitle,
	isDegeniaButton = false,
	children,
	hasList = true
}) => {
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	const [shouldRenderEditableForm, setShouldRenderEditableForm] = useState(false)
	const childComponent = hasList
		? (
			<DetailsList
				details={ children as IDetailsProps[] }
				isEditable={ shouldRenderEditableForm }
			/>
		)
		: children
	const editClickHandler = (): void => {
		setShouldRenderEditableForm(!shouldRenderEditableForm)
	}
	const saveClickHandler = (): void => {
		window.alert("save Button clicked")
		editClickHandler()
	}
	const cancelClickHandler = (): void => {
		window.alert("cacel Button clicked")
		editClickHandler()
	}
	const shouldRenderButton: boolean = btnTitle !== undefined
	const renderButton = (): ReactElement => {
		if (isDegeniaButton) {
			return (
				<div
					className={ cn(s.degeniaBtnWrapper) }
				>
					<DegeniaButton
						isInverted
						onClick={ (): void => { alert("TODO") } }
						size={ EButtonSize.small }
						title={ btnTitle as string }
					/>
				</div>
			)
		}
		const editButton = (
			<AnchorButton
				className={ cn(s.editButton, s.detailsButton) }
				onClick={ editClickHandler }
			>
				{t(`edit`)}
			</AnchorButton>
		)
		const cancelAndSaveButton = (
			<div
				className={ cn(s.cancelAndSaveButtonWrapper) }
			>
				<AnchorButton
					className={ cn(s.saveButton, s.detailsButton) }
					onClick={ saveClickHandler }
				>
					{t(`save`)}
				</AnchorButton>
				<AnchorButton
					className={ cn(s.cancelButton, s.detailsButton) }
					onClick={ editClickHandler }
				>
					{t(`cancel`)}
				</AnchorButton>
			</div>
		)
		return (
			shouldRenderEditableForm
				? cancelAndSaveButton
				: editButton
		)
	}
	return (
		<div className={ cn(s.boxWrapper) }>
			<TitleWrapper
				isTitleSmall
				title={ cardTitle }
			/>
			<div
				className={ cn(s.childComponentWrapper) }
			>{ childComponent }
			</div>
			{shouldRenderButton
				&& renderButton()
			}
		</div>
	)
}