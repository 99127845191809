import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import { ILoginProps } from "./interface"
import { LoginForm } from "./login-form"
import { Navigation } from "../navigation"
import { Overlay } from "../overlay"
import { RegisterForm } from "./register-form"
import { useTranslation } from "react-i18next"
import React, {
	FC, ReactElement, useState
} from "react"
import s from "./login.module.scss"
enum EForm {
	login = "login",
	register = "register"
}
export const Login: FC<ILoginProps> = ({
	onLogin,
	hasDmu,
	navigationClickHandler
}) => {
	const {
		t
	} = useTranslation(ENameSpace.common)
	const [activeForm, setActiveForm] = useState<EForm>(EForm.login)
	const getFormToRender = (form: EForm): ReactElement => {
		switch (form) {
			case EForm.register:
				return (
					<RegisterForm
						onLogin={ onLogin }
					/>
				)
			case EForm.login:
			default:
				return (
					<LoginForm
						onLogin={ onLogin }
					/>
				)
		}
	}
	const form = getFormToRender(activeForm)
	const getLinksToRender = (form: EForm): ReactElement => {
		switch (form) {
			case EForm.register:
				return (
					<div
						className={ s.link }
						onClick={
							(): void => setActiveForm(EForm.login)
						}
						role="button"
					>
						<p>
							{t(`login`)}
						</p>
					</div>
				)
			case EForm.login:
			default:
				return (
					<div>
						<div
							className={ s.link }
							onClick={
								(): void => setActiveForm(EForm.register)
							}
							role="button"
						>
							<p>
								{t(`register`)}
							</p>
						</div>
					</div>
				)
		}
	}
	const links = getLinksToRender(activeForm)
	return (
		<Overlay>
			<div
				className={ s.wrapper }
			>
				<Navigation
					hasDmu={ hasDmu }
					isActive
					navigationClickHandler={ navigationClickHandler }
				/>
				<p
					className={ s.title }
				>
					{t(`welcome`)}
				</p>
				{ form }
				{ links }
			</div>
		</Overlay>
	)
}