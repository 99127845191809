import { IPortalLink } from "./interface"
import { Link } from "@teamparallax/react-components"
import React, { FC } from "react"
import s from "./portal-link.module.scss"
export const PortalLink: FC<IPortalLink> = ({
	link,
	text
}) => {
	return (
		<Link
			className={ s.link }
			href={ link }
		>
			{ text }
		</Link>
	)
}