import { DashboardLink } from "../dashboard-link"
import { DashboardNewsLink } from "./dashboard-news-link"
import { DashboardSection } from "../dashboard-section"
import { EIcon } from "../../../utils/enum"
import { IDashboardNewsProps } from "./interface"
import { Icon } from "@teamparallax/react-components"
import { getIcon } from "../../../utils"
import { prettifyDate } from "../../../utils/date"
import React, { FC, ReactNode } from "react"
import s from "./dashboard-news.module.scss"
export const DashboardNews: FC<IDashboardNewsProps> = ({
	news
}) => {
	const getNews = (): ReactNode[] => news.map(({
		publishDate,
		url,
		title
	}) => {
		return (
			<li
				className={ s.dashboardNewsItem }
				key={ title }
			>
				<DashboardNewsLink
					href={ url }
				>
					{ title }
				</DashboardNewsLink>
				<span
					className={ s.dashboardNewsDate }
				>
					<Icon
						classnames={ s.icon }
						icon={ getIcon(EIcon.calendar) }
						size={ 14 }
					/>
					Veröffentlicht: { prettifyDate(new Date(publishDate)) }
				</span>
			</li>
		)
	})
	return (
		<DashboardSection
			className={ s.dashboardNews }
			title="News & Newsletter"
			titleUrl="/portal/news"
		>
			<ul
				className={ s.dashboardNewsList }
			>
				{ getNews() }
			</ul>
			<div
				className={ s.dashboardNewsAll }
			>
				<DashboardLink
					href="/portal/news"
				>
					Alle News anzeigen
				</DashboardLink>
			</div>
		</DashboardSection>
	)
}