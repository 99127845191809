import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import {
	IDetailsOption, IDetailsProps, TDetailValue
} from "./interface"
import { is } from "@teamparallax/common-libraries/lib/util/types"
import {
	isArray, isNil, isObject
} from "lodash-es"
import { prettifyDate } from "../../utils/date"
import { useTranslation } from "react-i18next"
import React, {
	FC, Fragment, ReactElement, ReactNode
} from "react"
import s from "./details.module.scss"
export const Details: FC<IDetailsProps> = ({
	details
}) => {
	const {
		t
	} = useTranslation(ENameSpace.degenia)
	const renderValues = (detail: IDetailsOption): ReactNode => {
		const {
			label,
			originalKey,
			value
		} = detail
		let renderedKeyValuePair = (
			<>
				<span> {label} </span>
				<span> {value} </span>
			</>
		)
		let renderedValue
		if (isNil(value)) {
			renderedValue = ""
		}
		else if (isArray(value)) {
			renderedValue = (
				<>{(value as string[]).map((value, index) =>
					value !== "" && (
						/* eslint-disable-next-line react/no-array-index-key */
						<p key={ index }>
							{index + 1}) {renderDate(value, originalKey)}
						</p>
					))}
				</>
			)
		}
		else if (is<ReactElement>(value, "props")) {
			renderedValue = value
		}
		else if (isDateKey(originalKey)) {
			renderedValue = prettifyDate (value as string)
		}
		else if (typeof value === "boolean") {
			renderedValue = value === true
				? "Ja"
				: "Nein"
		}
		else if (typeof value === "string") {
			renderedValue = value
		}
		else if (isObject(value)) {
			const nestedDetails: IDetailsOption[] = Object.entries(value as (string|number)[])
				.map(([nestedKey, nestedValue]) => ({
				/* @TODO i18n, see #490 */
					label: t(`risks|${nestedKey}`),
					originalKey: nestedKey,
					value: nestedValue as TDetailValue
				}))
			renderedValue = nestedDetails.map(renderValues)
		}
		else {
			renderedValue = JSON.stringify(value)
		}
		try {
			renderedKeyValuePair = (
				<Fragment
					key={ label }
				>
					<span>
						{ label }:
					</span>
					<span> {renderedValue}</span>
				</Fragment>
			)
		}
		catch (e) {
			console.warn(e)
		}
		return renderedKeyValuePair
	}
	const getOptions = (): ReactElement[] => details
		.filter((detail) => !isNil(detail.value))
		.map((detail) => {
			const {
				label,
				value
			} = detail
			return (
				<Fragment
					key={ label }
				>
					{ renderValues(detail) }
				</Fragment>
			)
		})
	return (
		<div
			className={ s.details }
		>
			{ getOptions() }
		</div>
	)
}
const renderDate = (date: string|Date, originalKey?: string): string|Date => {
	return isDateKey(originalKey)
		? prettifyDate(date)
		: date
}
const isDateKey = (key?: string): boolean => {
	switch (key) {
		case "yearOfConstruction":
		case "initialRegistration":
		case "registeredOnVn":
		case "birthdatePerson":
			return true
		default:
			return false
	}
}