import { DegeniaMarkdown } from "../markdown"
import { ITransformToMarkdown } from "./interface"
import React, { ReactElement } from "react"
export const TransformToMarkdown = ({
	component
}: ITransformToMarkdown): ReactElement => {
	const result: ReactElement = typeof component === "string"
		? <DegeniaMarkdown markdown={ component }/>
		: component
	return (
		<>
			{result}
		</>
	)
}