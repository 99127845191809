import { IChargesBoxProps } from "./interface"
import CheckSvg from "../../../../static/degenia/icons/check.svg"
import React, { FC } from "react"
import s from "./charges-box.module.scss"
export const ChargesBox: FC<IChargesBoxProps> = ({
	title,
	monthlyRate,
	yearlyRate,
	servicesList
}) => {
	return (
		<div
			className={ s.box }
		>
			<div
				className={ s.titleWrapper }
			>
				<span
					className={ s.title }
				>
					{ title }
				</span>
			</div>
			<div
				className={ s.rate }
			>
				<p
					className={ s.monthlyRate }
				>
					{ monthlyRate }
				</p>
				<p
					className={ s.yearlyRate }
				>
					{ yearlyRate }
				</p>
			</div>
			<div
				className={ s.servicesList }
			>
				{servicesList.map((service) => (
					<div
						className={ s.service }
						key={ service }
					>
						<div
							className={ s.icon }
						>
							<CheckSvg/>
						</div>
						<span
							className={ s.serviceText }
						>
							{ service }
						</span>
					</div>
				)) }
			</div>
		</div>
	)
}