import { DegeniaButton } from "../degenia-button"
import { EButtonSize } from "../../utils/enum"
import { IInfoBoxProps } from "./interface"
import { InfoBoxChildren } from "./info-box-children"
import { is } from "@teamparallax/react-components"
import React, { FC, ReactElement } from "react"
import cn from "classnames"
import s from "./infoBox.module.scss"
export const InfoBox: FC<IInfoBoxProps> = ({
	infoBoxChildrenProps,
	button,
	title
}) => {
	const icon = infoBoxChildrenProps[0].icon
	let iconAsEnum
	let iconAsReactElement
	if (is<ReactElement>(icon, "props")) {
		iconAsReactElement = icon
	}
	else {
		iconAsEnum = icon
	}
	return (
		<div
			className={ cn(s.infoBox) }
		>
			<div
				className={ cn(s.titleWrapper, {
					[s.titleWrapperReactElement]: iconAsReactElement,
					[s.titleWrapperEnum]: !iconAsReactElement
				}) }
			>
				{title}
			</div>
			{ infoBoxChildrenProps.map((infoBoxChildrenProps) => (
				<InfoBoxChildren
					icon={ infoBoxChildrenProps.icon }
					key={ Math.random() }
					label={ infoBoxChildrenProps.label }
					link={ infoBoxChildrenProps.link }
				/>
			)) }
			{button && (
				<div
					className={ cn(s.buttonWrapper) }
				>
					<DegeniaButton
						href={ button.href }
						isInverted
						size={ EButtonSize.small }
						title={ button.title }
					/>
				</div>
			)}
		</div>
	)
}