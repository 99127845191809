import { IDashboardLinkProps } from "./interface"
import DashboardArrow from "../../../../static/degenia/dashboard-arrow.svg"
import React, { FC } from "react"
import cn from "classnames"
import s from "./dashboard-link.module.scss"
export const DashboardLink: FC<IDashboardLinkProps> = ({
	className,
	href,
	hasNoIcon,
	children
}) => {
	return (
		<a
			className={ cn(s.dashboardLink, className) }
			href={ href }
		>
			{ children }
			{!hasNoIcon && (
				<DashboardArrow
					className={ s.icon }
				/>
			)}
		</a>
	)
}