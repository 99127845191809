import { DynamicLoader, TLazyImport } from "@teamparallax/react-components"
import { IDegeniaInputFieldProps } from "./interface"
import React, { FC } from "react"
export const DegeniaInputField: FC<IDegeniaInputFieldProps> = (props) => {
	return (
		<DynamicLoader<IDegeniaInputFieldProps>
			importPromise={ (async () => import("./degenia-input-field")) as TLazyImport }
			props={ props }
		/>
	)
}
export default DegeniaInputField