/* eslint-disable @typescript-eslint/no-magic-numbers */
import { AdvantagesBox } from "./advantages-box"
import { IAdvantagesPaneProps } from "./interface"
import { TitleWrapper } from "../title"
import React, { FC, useState } from "react"
import StarStrokeSvg from "../../../static/degenia/star-stroke.svg"
import cn from "classnames"
import s from "./advantages.module.scss"
interface IMoveMap {
	0: number[]
	1: number[]
	2: number[]
	3: number[]
}
export const AdvantagesPane: FC<IAdvantagesPaneProps> = ({
	title,
	advantagesBoxProps
}) => {
	const [extendedAdvantage, setExtendedAdvantage] = useState<number>(-1)
	const clickHandlerFactory = (advantageBoxNumber: number) => ():void => {
		if (extendedAdvantage === advantageBoxNumber) {
			setExtendedAdvantage(-1)
		}
		else {
			setExtendedAdvantage(advantageBoxNumber)
		}
	}
	const moveMap: IMoveMap = {
		0: [],
		1: [0],
		2: [0, 1],
		3: [0, 1, 2]
	}
	const shouldMove = (numberArray: number[]): boolean => {
		for (let i = 0; i < numberArray.length; i++) {
			if (extendedAdvantage === i) {
				return true
			}
		}
		return false
	}
	return (
		<div
			className={ s.wrapper }
		>
			<div
				className={ s.starLightGrey }
			>
				<StarStrokeSvg/>
			</div>
			<div
				className={ s.starGrey }
			>
				<StarStrokeSvg/>
			</div>
			<div
				className={ s.titleWrapper }
			>
				<TitleWrapper
					title={ title }
				/>
			</div>
			<div
				className={ s.advantagesWrapper }
			>
				<div
					className={ s.starGreen }
				>
					<StarStrokeSvg/>
				</div>
				<div
					className={ s.advantagesBoxWrapper }
				>
					{advantagesBoxProps.map((advantageBox, index) => (
						<div
							className={ cn(s[`advantage-${index}`], {
								[s.moved]: shouldMove(moveMap[index as keyof IMoveMap])
							}) }
							key={ advantageBox.title }
						>
							<AdvantagesBox
								advantagesList={ advantageBox.advantagesList }
								clickHandler={ clickHandlerFactory(index) }
								isExtended={ extendedAdvantage === index }
								title={ advantageBox.title }
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}