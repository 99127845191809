import { DetailsWrapperCard } from "../wrapper-card"
import { IDetailsWrapperCardProps } from "../wrapper-card/interface"
import React, { FC } from "react"
export const ReportDamageBox: FC<IDetailsWrapperCardProps> = ({
	cardTitle,
	children,
	btnTitle
}) => {
	return (
		<DetailsWrapperCard
			btnTitle={ btnTitle }
			cardTitle={ cardTitle }
			hasList={ false }
			isDegeniaButton
		>
			{ children }
		</DetailsWrapperCard>
	)
}