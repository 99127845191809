import { AnchorButton } from "@teamparallax/react-components/"
import { ChargesBox } from "./charges-box"
import { DegeniaButton } from "../../components/degenia-button"
import { IChargesProps } from "./interface"
import { ResponsiveCarousel } from "../../components/responsive-carousel"
import { TitleWrapper } from "../title"
import React, { FC } from "react"
import s from "./charges.module.scss"
export const ChargesPane: FC<IChargesProps> = ({
	chargesBoxesProps,
	degeniaButton,
	rateDetails,
	subtitle,
	title
}) => {
	const breakPointMap = {
		1: 0,
		2: 550,
		3: 750,
		4: 950
	}
	return (
		<div>
			<div
				className={ s.titleWrapper }
			>
				<TitleWrapper
					subTitle={ subtitle }
					title={ title }
				/>
			</div>
			<div
				className={ s.boxWrapper }
			>
				<ResponsiveCarousel
					breakPointMap={ breakPointMap }
					hasGreenArrows
				>
					{
						chargesBoxesProps.map((chargesBox) => (
							<div
								key={ chargesBox.title }
							>
								<ChargesBox
									monthlyRate={ chargesBox.monthlyRate }
									servicesList={ chargesBox.servicesList }
									title={ chargesBox.title }
									yearlyRate={ chargesBox.yearlyRate }
								/>
							</div>
						))
					}
				</ResponsiveCarousel>
			</div>
			<div
				className={ s.detailsWrapper }
			>
				{
					rateDetails && (
						<div
							className={ s.rateDetailsWrapper }
						>
							<AnchorButton
								className={ s.rateDetails }
								href={ rateDetails.href }
							>
								{rateDetails.title}
							</AnchorButton>
						</div>
					)
				}
				<DegeniaButton
					{ ...degeniaButton }
					isInverted
					shouldOpenInNewTab
				/>
			</div>
		</div>
	)
}