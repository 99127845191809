import { AccordionSection } from "./accordion-section"
import { IAccordionProps } from "./interface"
import React, { FC } from "react"
export const Accordion: FC<IAccordionProps> = ({
	options,
	initialOpenOptions = []
}) => (
	<div>
		{options.map((option, index) => {
			const {
				content,
				title,
				id
			} = option
			return (
				<AccordionSection
					id={ id }
					isInitialOpen={ initialOpenOptions?.includes(index) }
					key={ title }
					title={ title }
				>
					{content}
				</AccordionSection>
			)
		})}
	</div>
)